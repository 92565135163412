import React from 'react';
import Icon from '../../Global/Icon';
import {
  BookmarkIcon,
  HeartIcon,
  MinusIcon,
  PencilEditIcon,
  ReplyIcon,
  ShareIcon,
  TrashOutlineIcon,
  TripleDotIcon,
} from '../../Global/icons';
import { scrapType } from '../../../types/scrapType';
import { formatTimestampDate, showFullDate } from '../../../helpers';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import MyDropDown from '../../Global/MyDropDown';
import StateLabel from '../../Global/StateLabel';
import { scrapCardViewType } from '..';
import ScrapCardFooterLike from "./ScrapCardFooterLike";
type dropdownType = {
  valueField: 'delete' | 'edit' | 'remove' | 'share' | 'bookmark';
  textField: string;
  className?: string;
  icon: React.ReactElement;
  isIconStroked?: boolean;
};

interface Proptypes {
  onBookmarkClick?: () => void;
  onDeleteClick?: () => void;
  onEditClick?: (scrap: scrapType, val?: boolean) => void;
  state?: number;
  userName?: string;
  copied?: boolean;
  handleCopy?: () => void;
  scrap?: scrapType;
  onRemoveClick?: () => void;
  date?: string;
  onCommentClick?: () => void;
  onLikeToggled?: (isLiked: boolean) => void;
  onDropdownOpened?: () => void;
  onDropdownClosed?: () => void;
  isCommentActive?: boolean;
  commentsCount?: number;
  reactionsCount?: number;
  view?: scrapCardViewType;
  isBookmarked?: boolean;
}

const ScrapCardFooter = ({
  onBookmarkClick,
  onDeleteClick,
  onEditClick,
  state,
  userName,
  copied,
  handleCopy,
  scrap,
  onRemoveClick,
  date,
  onCommentClick,
  onLikeToggled,
  onDropdownClosed,
  onDropdownOpened,
  commentsCount,
  isCommentActive,
  view,
  isBookmarked
}: Proptypes) => {
  const user = useSelector((state: ReduxStateType) => state.user);

  function dropdownOptionClickHandler(option: Partial<dropdownType>) {
    switch (option.valueField) {
      case 'delete':
        onDeleteClick();
        break;
      case 'edit':
        onEditClick(scrap, false);
        break;
      case 'remove':
        onRemoveClick();
        break;
      case 'share':
        handleCopy();
        break;
      case 'bookmark':
        onBookmarkClick();
        break;

      default:
        break;
    }
  }
  const dropdownList: dropdownType[] = [];
  if (handleCopy)
    dropdownList.push({
      valueField: 'share',
      textField: 'Share',
      icon: <ShareIcon />,
    });
  if (onEditClick)
    dropdownList.push({
      valueField: 'edit',
      textField: 'Edit Scrap',
      icon: <PencilEditIcon />,
    });

  if (onBookmarkClick)
    dropdownList.push({
      valueField: 'bookmark',
      textField: isBookmarked ? 'Saved' : 'Save',
      icon: <BookmarkIcon isFilled={isBookmarked} />,
      className: isBookmarked ? 'scrap-card__footer-dropdown-item--active' : ''
    }); 
  
  if (onDeleteClick)
    dropdownList.push({
      valueField: 'delete',
      textField: 'Delete',
      icon: <TrashOutlineIcon />,
      isIconStroked: true,
    });

  if (onRemoveClick)
    dropdownList.push({
      valueField: 'remove',
      textField: `Remove from ${view === 'group-page' ? 'Share' : 'Collection'}`,
      icon: <MinusIcon />,
    });

  return (
    <div className="scrap-card__footer">
      <div className="scrap-card__footer-left">
        <StateLabel state={state} onClick={state === 2 ? dropdownOptionClickHandler.bind(null, {valueField: "share"}) : null} />
        {date && (
          <p className="scrap-card__footer-date" title={showFullDate(date, user.timezone)}>
            Edited {formatTimestampDate(date, user.timezone)}
          </p>
        )}
      </div>
      <div className="scrap-card__footer-right">
        <ScrapCardFooterLike scrapId={scrap.id} userId={user.user_id} onLikeToggled={onLikeToggled} />

        {(onCommentClick && user.user_id) && (
          <button
            onClick={onCommentClick}
            className={`scrap-card__footer-button scrap-card__comment-button${
              isCommentActive ? ' scrap-card__footer-button--active' : ''
            }`}
          >
            <ReplyIcon />
            {commentsCount ? commentsCount : ''}
          </button>
        )}
        <MyDropDown<dropdownType>
          dropDownList={dropdownList}
          // onOptionClick={dropdownOptionClickHandler}
          onDropDownClosed={onDropdownClosed}
          customButton={{
            customRender: (showDropDownListHandler) => {
              return (
                <button
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    e.stopPropagation();
                    showDropDownListHandler();
                    onDropdownOpened && onDropdownOpened();
                  }}
                  className="scrap-card__footer-button scrap-card__footer-triple-dot-button"
                >
                  <TripleDotIcon />
                </button>
              );
            },
          }}
        >
          {(item, onClicked) => {
            return (
              <div
                key={item.valueField}
                className={`scrap-card__footer-dropdown-item${item.className ? ' ' + item.className : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  // item.valueField === 'share' && handleCopy();
                  dropdownOptionClickHandler(item);
                  onClicked && onClicked();
                }}
              >
                <span
                  className={`scrap-card__footer-dropdown-item-icon${
                    item.isIconStroked ? ' scrap-card__footer-dropdown-item-icon--stroked' : ''
                  }`}
                >
                  {item?.icon}
                </span>
                <span className="scrap-card__footer-dropdown-item-label">{item?.textField}</span>
              </div>
            );
          }}
        </MyDropDown>
        {copied && (
          <div className="scrap-card__copied">
            <Icon iconClass="check-square" size="big" />
            Link copied to clipboard
          </div>
        )}
      </div>
    </div>
  );
};

export default ScrapCardFooter;
