import { gql } from '@apollo/client';

const CANCEL_SUBSCRIPTION = gql`query CancelSubscription {
    cancelSubscription {
      data {
        is_cancelled
      }
      messages {
        error
        success
        __typename
      }
      status
      http_code
      __typename
    }
  }`;

export default CANCEL_SUBSCRIPTION;