import React, { Fragment } from 'react';
import { filterItemType } from '../../../containers/SideNavContainer';

import './styles.css';

interface Proptypes {
  children: (filterItem: filterItemType) => React.ReactChild;
  list: filterItemType[];
  disabled?: boolean;
  showApplyFilters?: boolean;
  showCancelFilters?: boolean;
  onApplyFiltersClick: (type: 'cancel' | 'submit') => void;
}

const SideNavWrapper = ({ children, list, disabled, showApplyFilters, onApplyFiltersClick, showCancelFilters }: Proptypes) => {
  const currentDate = new Date();
  const showApplyContainer = showApplyFilters || showCancelFilters;
  return (
    <div
      className={`side-nav-wrapper custom-scroll__body${
        disabled ? ' side-nav-wrapper--disabled' : ''
      }${showApplyFilters ? ' side-nav-wrapper--apply' : ''}`}
    >
      <ul className="side-nav-list">
        {showApplyContainer && (
          <li className="side-nav-wrapper__apply-filters">
            <button
              onClick={(e) => {
                onApplyFiltersClick('cancel');
              }}
              className="side-nav-wrapper__apply-filters-cancel button button__outline btn-action"
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                onApplyFiltersClick('submit');
              }}
              className="side-nav-wrapper__apply-filters-submit button button__primary btn-action"
            >
              Apply
            </button>
          </li>
        )}
        {list.map((item, i) => (
          <Fragment key={i}>{children(item)}</Fragment>
        ))}
      </ul>
      <div className="side-nav-wrapper__policy">
        <img
          className="side-nav-wrapper__logo"
          src="/dashboard/img/Group.svg"
          alt="scrappi"
        />
        <div className="side-nav-wrapper__policy-content">
          <span className="side-nav-wrapper__policy-text">
            Copyright {currentDate.getFullYear()}
          </span>
          <a
            className="side-nav-wrapper__privacy-policy-link hoverState-text-color"
            href="/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </a>
          <a
            className="side-nav-wrapper__privacy-policy-link hoverState-text-color"
            href="/terms-and-conditions"
            target="_blank"
          >
            Terms of Service
          </a>
        </div>
      </div>
    </div>
  );
};

export default SideNavWrapper;
