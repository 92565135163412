import { combineReducers, createStore } from 'redux';
import oneScrapAction, { OneScrapActionType } from './oneScrap';
import supabase, { supabaseStoreType } from './supabase';
import feed from './feed';
import topics from './topics';
import activeTopic from './activeTopic';
import user from './user';
import filters, { ReduxFiltersType } from './filters';
import appliedFilter from './appliedFilter';
import appliedQuery from './appliedQuery';
import urlQuery from './urlQuery';
import filtersArray from './filtersArray';
import page from './page';
import utils from './utils';
import stories from './stories';
import tags from './tags';
import collections from './collections';
import filterParams from './filterParams';
import filterCount from './filterCount';
import sortParams from './sortParams';
import viewSettings from './viewSettings';
import batchEdit from './batchEdit';
import visibilityStates from './visibilityStates';
import globalSearch from './globalSearch';
import scrapsLikesAndComments, { ReduxScrapsLikesAndCommentsType } from './scrapsLikesAndComments';
import groupsLikesAndComments, { ReduxGroupsLikesAndCommentsType} from './groupsLikesAndComments';
import collectionsLikesAndComments, { ReduxCollectionsLikesAndCommentsType } from './collectionsLikesAndComments';

// types
import popups, { ReduxPopupsType } from './popups';
import messengers, { ReduxMessengersType } from './messengers';
import drawer, { ReduxDrawerType } from './drawer';
import { topicTypes } from '../../types/topic';
import { entryTypes } from '../../types/feed';
import { ReduxUserType } from '../../types/user';
import { collectionType } from '../../types/collections';
import { tagType } from '../../types/tags';
import { ViewSettingsType } from '../../types/viewSettings';
import { shareSettingsOption } from './shareSettings'
import { ThemeReducerType } from '../../types/themeType';
import themeReducer from './theme';
import { ShareSettingsProptypes } from '../../components/Cards/CardComponents/CardSettings';
import { utilsTypes } from '../../types/utils';
import { BatchEditType } from '../../types/batchEdit';

const dev: boolean = process.env.NODE_ENV !== 'production';
const { composeWithDevTools } = dev
  ? require('redux-devtools-extension')
  : require('redux-devtools-extension/logOnlyInProduction');



export interface ReduxStateType {
  theme: ThemeReducerType;
  topics: Array<topicTypes>
  feed: entryTypes[];
  user: ReduxUserType;
  collections: collectionType[];
  tags: tagType[];
  utils: utilsTypes;
  viewSettings: ViewSettingsType;
  shareSettingsOption: ShareSettingsProptypes;
  batchEdit: BatchEditType;
  oneScrapAction: OneScrapActionType;
  supabase: supabaseStoreType;
  popups: ReduxPopupsType;
  messengers: ReduxMessengersType;
  filters: ReduxFiltersType;
  drawer: ReduxDrawerType;
  scrapsLikesAndComments: ReduxScrapsLikesAndCommentsType;
  collectionsLikesAndComments: ReduxCollectionsLikesAndCommentsType;
  groupsLikesAndComments: ReduxGroupsLikesAndCommentsType;
}  

const combined = combineReducers({
  topics,
  feed,
  theme: themeReducer,
  activeTopic,
  user,
  appliedFilter,
  appliedQuery,
  urlQuery,
  filtersArray,
  page,
  utils,
  stories,
  tags,
  collections,
  filterParams,
  filterCount,
  sortParams,
  viewSettings,
  batchEdit,
  visibilityStates,
  globalSearch,
  shareSettingsOption,
  oneScrapAction,
  supabase,
  popups,
  messengers,
  filters,
  drawer,
  scrapsLikesAndComments,
  groupsLikesAndComments,
  collectionsLikesAndComments,
});
const AppStore = createStore(combined, composeWithDevTools());

export default AppStore;
