import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { UPLOAD_FILE } from '../../containers/data/uploadFile';
import { parseNote } from '../../helpers';
import CollectionViewDropdown from '../CollectionCreationWrapper/components/EditCollection/components/CollectionViewDropdown';
import AddImage from '../Global/AddImage';
import TextInput from '../Global/TextInput';
import { returnImagesCDN } from '../../helpers/config';
import NewPopup from '../Global/NewPopup';
import './styles.css';
import { DownArrowIcon } from '../Global/icons';
import { ScrapImagesCropper } from '../ScrapImagesPopup';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../Global/InvisibleRecaptcha';
import ValidateHuman from '../../helpers/validators/isHuman';
import { parseTryCatchError } from '../../helpers/parseTryCatchError';
import ScrapDescription from '../ScrapDetails/components/ScrapDescription';
import ScrapShare from '../ScrapDetails/components/ScrapShare';
import TrashOutline from '../Global/icons/TrashOutline';
import { collectionType } from '../../types/collections';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { setBackUrl, setShowMember } from '../../redux/action/utils';
import { ReduxStateType } from '../../redux/store';
import NewCollectionCollaborators from '../NewCollectionCard/UiComponents/NewCollectionCollaborators';
 
  interface Proptypes {
  collectionSlug?: string;
  openOtherFieldsByDefault?: boolean;
  // eslint-disable-next-line no-undef
  triggerer?: JSX.Element;
  id?: string;
  title?: string;
  view_type?: number;
  currentImage?: string;
  desc?: string;
  show_author?: number;
  isEditing: boolean;
  onCollectionEdit: (
    data: {
      title: string;
      desc?: string;
      cover_image?: string;
      view_type?: number;
      state?: number;
      sort_by?:string;
      sort_param?: string;
      show_author?: number;
    },
    cb: () => void
  ) => Promise<void>;
  openDeletePopup?: () => void;
  controlled?: {
    show: boolean;
    setShow: (val: boolean) => void;
  };
  state?: number;
  visibilityStatus?: number;
  private_key?: string;
  handleEdit?: (
    val: { title?: string; state?: number },
    cb?: Function
  ) => Promise<void>;
  clickedLock?: boolean;
  setCLickedLock?: (val: boolean) => void;
  createCollection?: boolean;
  collectionHandleCopy?: () => void;
  copyMessage?: boolean;
  collaborators?: collectionType['members'];
  setEditCollectionStatus?:(val:boolean)=>void;
  sort_by?: string;
  sort_para?: string;
  sortHandler?:(val: string)=>void;
  selectedSort?: "Newest" | "Oldest" | "Custom";
}

const returnActiveSortParams = (selectedSort: "Newest" | "Oldest" | "Custom") => {
  switch (selectedSort) {
    case 'Oldest':
      return {
        sort_param: 'scrap_created_at',
        sort_by: 'asc'
      }
    case 'Newest':
      return {
        sort_param: 'created_at',
        sort_by: 'desc'
      }
  
    default:
      return  {
        sort_param: null,
        sort_by: null,
      }
  }
}

const CollectionSettingsPopup = (props: Proptypes) => {
  const [uploadImage, { loading: uploading }] = useMutation(UPLOAD_FILE);
  const {
    collectionSlug,
    openOtherFieldsByDefault = true,
    view_type,
    show_author,
    title,
    currentImage,
    desc,
    isEditing,
    onCollectionEdit,
    openDeletePopup,
    triggerer,
    controlled,
    state,
    visibilityStatus,
    handleEdit,
    clickedLock,
    setCLickedLock,
    createCollection,
    collectionHandleCopy,
    copyMessage,
    collaborators,
    id,
    setEditCollectionStatus,
    sortHandler,
    selectedSort,
  } = props;
  const faSpinnerIcon = faSpinner as IconProp;
  const isNewCollection = !collectionSlug;
  const dispatch = useDispatch();
  const [showImage, setShowImage] = useState(currentImage?.length > 0);
  const [showOtherFields, setShowOtherFields] = useState(
    openOtherFieldsByDefault
  );
  // Reason: Might get utilized to get back the functionality to detect if the data is edited at certain moment
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gotEdited, setGotEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  // Reason: Might get utilized to get back the functionality to detect if the editor is focused
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [focusedEditor, setFocusedEditor] = useState(false);

  const [desccharLength, setDescCharLength] = useState<number>(null);
  // Reason: Might get utilized to get back the functionality for undo operation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [undo, setUndo] = useState<{ url: string; file?: File }>(null);
  const [cropUrl, setCropUrl] = useState<string>(null);
  const [editTitleValue, setEditTitleValue] = useState<string>(title || '');
  const [editDescription, setEditDescription] = useState<string>(
    parseNote(desc || '')
  );
  const [editVisibility, setEditVisibility] = useState(state || 1);
  const [editViewModeValue, setEditViewModeValue] = useState<number>(
    view_type || 1
  );
  
  const[sortBy,setSortBy] = useState(selectedSort)
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [coverImage, setCoverImage] = useState(currentImage);
  
  const [file, setFile] = useState<File>(null);
  const [limitFlag, setLimitFlag] = useState(false);
  const [enableShareStatus, setEnableShareStatus] = useState(
    state === 2 || state === 3 || createCollection
  );
  const { backUrl, scrollToBottom, scrapPopup, copyLink } = useSelector(
    (state: ReduxStateType) => state.utils
  );
  const [showAuthor,setShowAuthor]=useState(show_author === 1);
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  if (typeof window === 'object') {
    let elem = document.getElementsByClassName(
      'scrap-share__actions-toggle-description'
    )[0];
    (clickedLock || scrollToBottom) &&
      elem?.scrollIntoView({ behavior: 'smooth' });
  }
 

  useEffect(() => {
    setEnableShareStatus(state === 2 || state === 3 || createCollection);
  }, [state, createCollection])

  useEffect(() => {
    setCoverImage(currentImage);
    setEditTitleValue(title || '');
    setEditDescription(parseNote(desc || ''));
    setShowImage(currentImage?.length > 0);
    setEditViewModeValue(view_type);
    setShowAuthor(show_author === 1);
  }, [controlled.show]);

  useEffect(() => {
    setEditVisibility(state);
    setSortBy(selectedSort);
  }, [state,selectedSort]);
  const onTitleChange = (val: string) => {
    setGotEdited(true);
    setEditTitleValue(val);
  };
  const onFileChange = async (imageFile: File) => {
    setCropUrl(URL.createObjectURL(imageFile));
  };
  const returnState = () => {
    if (editVisibility) {
      if ((editVisibility === 1 || editVisibility === 2) && enableShareStatus) {
        return 2;
      } else if (
        (editVisibility === 1 || editVisibility === 2) &&
        !enableShareStatus
      ) {
        return 1;
      } else {
        return 3;
      }
    } else {
      if (!editVisibility && enableShareStatus) {
        return 2;
      } else if (!editVisibility && !enableShareStatus) {
        return 1;
      } else {
        return 3;
      }
    }
  };
  const sortingParams = returnActiveSortParams(sortBy);
  const collectionEditHandler = async (close: () => void) => {
    setIsSaving(true);
    try {
      sortHandler&&sortHandler(sortBy)
      setEditCollectionStatus && setEditCollectionStatus(true);
      setErrorMessage(null);
      let data = {
        title: editTitleValue,
        desc: JSON.stringify(JSON.stringify(editDescription)),
      };

      const isHuman = await ValidateHuman(reCaptchaRef.current);
      if (!isHuman) return;

      if (editTitleValue !== title) {
        data['title'] = editTitleValue;
      }
      let cover_image = coverImage;
      if (file) {
        const res = await uploadImage({
          variables: {
            file,
          },
        });
        if (res?.data?.upload) cover_image = res.data.upload;
      }
      data['cover_image'] = cover_image;
      if (cover_image?.includes(returnImagesCDN())) {
        data['cover_image'] = cover_image?.split(returnImagesCDN())[1];
      }
      if (visibilityStatus !== editVisibility)
        data['state'] = editVisibility || 1;
      await onCollectionEdit(
        { ...data, view_type: editViewModeValue, sort_by:sortingParams?.sort_by, sort_param: sortingParams?.sort_param,state: returnState(), show_author: showAuthor ? 1 : 0 },
        () => {
          setShowImage(false);
          setEditCollectionStatus && setEditCollectionStatus(false);
          close();
        }
      );

      if (createCollection) {
        setEditTitleValue('');
        setCoverImage('');
        setEditDescription('');
        setEditViewModeValue(1);
        setEnableShareStatus(false);
        setEditVisibility(1);
      }
    } catch (error) {
      let message = parseTryCatchError(error);
      if (
        message === 'Same name is already used for other collection.' ||
        message === 'Collection is already exist.'
      )
        message =
          'Collection of this title already exists. Please enter a new one.';
      setErrorMessage(message);
    }
    setIsSaving(false);
  };

  const cropHandler = (blob: Blob) => {
    const tempFile = new File([blob], 'Cover-image', { type: blob.type });
    setErrorMessage(null);
    setFile(tempFile);
    setCoverImage(URL.createObjectURL(tempFile));
    setCropUrl(null);
  };

  const render = () => {
    if (cropUrl) {
      return (
        <ScrapImagesCropper
          onCancel={setCropUrl.bind(null, null)}
          imageUrl={cropUrl}
          onCrop={cropHandler}
          aspectRatio={16/9}
        />
      );
    }
    return (
      <div className="collection-settings__container">
        {showOtherFields ? (
          <div className="collection-settings__other-fields">
            <div className="collection-settings__other-fields-col-1">
              {/* {undo && (
                <UndoBox
                  onUndoClick={() => {
                    setGotEdited(true);
                    setCoverImage(undo.url);
                    setFile(undo.file);
                    setUndo(null);
                  }}
                >
                  Image removed successfully
                </UndoBox>
              )} */}
              <div className="collection-settings__show">
                <span className="collection-settings__show__avatar">
                  <NewCollectionCollaborators
                    collectionId={id}
                    collaborators={collaborators}
                    // isEditPopup
                    onCollaboratorIconClick={() => {
                      dispatch(
                        setShowMember({
                          heading: 'Collection Collaborators',
                          member: collaborators,
                          clickable: true,
                        })
                      );
                    }}
                  />
                </span>
                <span className={`collection-settings__show__author${!collaborators?.length ? ' collection-settings__show__author--disabled' : ''}`}>
                  <input disabled={!collaborators?.length} type="checkbox" checked={showAuthor || !collaborators?.length} onChange={(e)=>setShowAuthor(e.target.checked)
                  }/>
                  <span className='collection-settings__show__author-checkbox'>Show Owner as Author</span>
                </span>
              </div>
              {/* {!showImage && (
                <div
                  className="collection-settings__add-image__toggle"
                  onClick={() => setShowImage(true)}
                >
                  {' '}
                  <NewGalleryIcon /> <span>Add Image</span>{' '}
                  <Plus className="tags-view__header-icon" />
                </div>
              )} */}

              <div className="collection-settings__add-image">
                {/* {!coverImage && (
                  <span className="collection-settings__add-cover-image-heading">
                    Cover Image
                  </span>
                )} */}
                <div className={`collection-settings__add-image-content`}>
                  <AddImage
                    clickedLock={clickedLock}
                    removeButtonLabel="Replace"
                    key={`image-${controlled?.show}`}
                    collectionSlug={collectionSlug}
                    collectionId={id}
                    image={coverImage}
                    convertCdnToBase64
                    onChange={(image, type) => {
                      setGotEdited(true);
                      setUndo(
                        type === 'remove'
                          ? { url: coverImage, file: file }
                          : null
                      );
                      if (type === 'remove') {
                        setCoverImage(null);
                        setFile(null);
                      } else if (type === 'file') {
                        onFileChange(image as File);
                      } else if (type === 'cdn-url')
                        setCropUrl(image as string);
                      else if (type === 'b64Url') setCropUrl(image as string);
                    }}
                  />
                </div>
              </div>

              <div className="collection-settings__title-field">
                <InvisibleRecaptcha inputRef={reCaptchaRef} />
                <TextInput
                  type="textarea"
                  onChange={(val) => {
                    setErrorMessage(null);
                    onTitleChange(val);
                    if (val.trim().length > 100) {
                      setLimitFlag(true);
                    } else if (limitFlag) {
                      setLimitFlag(false);
                    }
                  }}
                  label="Title"
                  value={editTitleValue}
                  placeholder="Title"
                />
                <span className="collection-settings__char-limit">
                  {editTitleValue.length}/100
                </span>
                {editTitleValue.length > 100 && (
                  <div style={{ color: 'red' }}>Text Limit Exceeded</div>
                )}
              </div>
              <div className="collection-settings__other-fields-col-2">
                <div
                  className={`collection-settings__description${
                    desccharLength > 5000
                      ? ' collection-settings__description--error'
                      : ''
                  }`}
                  onBlur={(e) => {
                    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
                      setFocusedEditor(true);
                    }
                  }}
                  onFocus={() => {
                    setFocusedEditor(false);
                  }}
                >
                  {/* <Tiptap
                    placeholder="Add a description or notes to display on your Collection page."
                    text={editDescription}
                    edit
                    animated
                    setCharLength={setDescCharLength}
                    onChange={(content) => {
                      setGotEdited(true);
                      setEditDescription(content);
                    }}
                    toolsToHide={['heading']}
                    focusedEditor={focusedEditor}
                    onBlur={() => setFocus(false)}
                    setFocus={setFocus}
                  /> */}
                  <div className={'scrap-add-notes-group__description-input'}>
                    <ScrapDescription
                      label="Description"
                      description={editDescription || ''}
                      placeholder="Description"
                      onChange={(content) => {
                        setGotEdited(true);
                        setEditDescription(content);
                      }}
                      setCharLength={setDescCharLength}
                    />
                  </div>
                  <span className="collection-settings__char-limit">
                    {desccharLength || 0}/5000
                  </span>
                  {desccharLength > 5000 && (
                    <div className="collection-settings__char-limit-exceeded-message">
                      Text Limit Exceeded
                    </div>
                  )}
                </div>
              </div>
              <div className="collection-settings__view-mode">
                <CollectionViewDropdown
                  value={editViewModeValue}
                  onChange={(val) => {
                    setEditViewModeValue(val);
                    setGotEdited(true);
                  }}
                  onSortChange={(val) => {
                    setSortBy(val as Proptypes['selectedSort'])
                    setGotEdited(true);
                  }}
                  sortValue={selectedSort}
                  sortBy={sortBy}
                  dropUp
                />
              </div>
              <ScrapShare
                status={editVisibility}
                type="Collection"
                settings
                //@ts-ignore
                handleEdit={(data) => {
                  handleEdit ? handleEdit(data) : setGotEdited(true);
                  setEditVisibility(data.state || 1);
                }}
                setEditVisibility={setEditVisibility}
                setEnableShareStatus={setEnableShareStatus}
                enableShareStatus={enableShareStatus}
                collectionHandleCopy={collectionHandleCopy}
                copyMessage={copyMessage}
              />
            </div>
          </div>
        ) : (
          <>
            <TextInput
              type="textarea"
              onChange={(val) => {
                setErrorMessage(null);
                onTitleChange(val);
                if (val.trim().length > 100) {
                  setLimitFlag(true);
                } else if (limitFlag) {
                  setLimitFlag(false);
                }
              }}
              value={editTitleValue}
              placeholder="Name your collection"
            />
            <span className="collection-settings__char-limit">
              {editTitleValue.length}/100
            </span>
            {editTitleValue.length > 100 && (
              <div style={{ color: 'red' }}>Text Limit Exceeded</div>
            )}
            <button
              className="collection-settings__other-fields-toggler"
              onClick={setShowOtherFields.bind(null, true)}
            >
              <span className="collection-settings__other-fields-toggler-text">
                Image, Description and Layout
              </span>

              <DownArrowIcon className="collection-settings__other-fields-toggler-icon" />
            </button>
          </>
        )}
        {errorMessage && (
          <span className="collection-settings__error-message">
            {errorMessage}
          </span>
        )}
      </div>
    );
  };

  function popupClosedHandler() {
    if (createCollection) {
      setEditTitleValue('');
      setCoverImage('');
      setEditDescription('');
      setEditViewModeValue(1);
      setEnableShareStatus(true);
      setEditVisibility(1);
      setShowImage(false);
      setCropUrl("");
    } else {
      setCLickedLock && setCLickedLock(false);
      dispatch(setBackUrl(null, false));
      setShowOtherFields(openOtherFieldsByDefault);
      setLimitFlag(false);
      setFile(null);
      setCoverImage(currentImage);
      setErrorMessage(null);
      setEditViewModeValue(view_type || 1);
      setEditDescription(parseNote(desc || ''));
      setEditTitleValue(title || '');
      setCropUrl(null);
      setUndo(null);
      setGotEdited(false);
      setDescCharLength(null);
      setShowImage(false);
    }
  }
  const isProcessing = isEditing || uploading || isSaving;
  const disableSave =
    (desccharLength || 0) > 5000 ||
    editTitleValue.trim().length === 0 ||
    editTitleValue.length > 100 ||
    limitFlag ||
    isProcessing;
  let className = `collection-settings__popup ${
    isProcessing ? 'collection-settings__popup--disable' : ''
  }`;
  if (cropUrl) className += ' scrap-images-popup scrap-images-cropper-popup';
  else if (isNewCollection) className += ' collection-settings__popup--new';
  // let submitLabel = isNewCollection ? 'Create' : 'Save';
  let submitLabel = 'Save';
  if (isProcessing) submitLabel = isNewCollection ? 'Creating' : 'Saving';
  return (
    <NewPopup
      className={className}
      onClosed={popupClosedHandler}
      secondaryPopup={cropUrl==="" || cropUrl === null ? false : true}
      controlled={controlled}
      triggerer={triggerer}
      header={{
        heading: 'Collection Settings',
        className: `${
          isProcessing ? 'collection-settings__popup-body--disable' : ''
        }`,
      }}
      size={cropUrl ? 'Extra large' : 'Small'}
      body={{
        className: `collection-settings__popup-body ${
          cropUrl ? ' collection-settings__popup-body--crop' : ''
        } ${isProcessing ? 'collection-settings__popup-body--saving ' : ''}`,
      }}
      footer={{
        className: `${
          isProcessing ? 'collection-settings__popup-body--disable' : ''
        }`,
        submitLabel,
        disableSubmit: disableSave,
        onSubmit: collectionEditHandler,
        // className: 'collection-header__popup-footer',
        hide: !!cropUrl,
        leftRender:
          !openDeletePopup && createCollection
            ? null
            : () =>
              isProcessing ? (
                <div className="collection-settings__footer-processing">
                  <span className="collection-settings__footer-processing-message">
                    {typeof isProcessing === 'string'
                      ? isProcessing
                      : 'Saving'}
                  </span>
                  <span className="collection-settings__footer-processing-spinner">
                    <FontAwesomeIcon icon={faSpinnerIcon} spin size="2x" />
                  </span>
                </div>
              ) : (
                !createCollection && (
                  <div className="collection-settings__delete-button-container">
                    <button
                      onClick={openDeletePopup}
                      className="collection-settings__delete-button hoverState__with-white-text-stroke"
                      title="Delete Collection"
                    >
                      <TrashOutline />
                    </button>
                  </div>
                )
              ),
      }}
    >
      {(close) => render()}
    </NewPopup>
  );
};

export default CollectionSettingsPopup;
