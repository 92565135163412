import React  from 'react';
import Button from '../uiComponents/Button';
import {
  ChainIcon,
  FilePinIcon,
  ImagesIcon,
  NoteIcon,
} from '../Global/icons';
import { ScrapImagesUploader } from '../ScrapImagesPopup';
import { utilsTypes } from '../../types/utils';

interface Proptypes {
  onLinkClick?: () => void;
  onNotesClick?: () => void;
  onImagesChange?: (file: FileList) => Promise<void>;
  onFilesChange?: (file: FileList) => Promise<void>;
  defaultOpen?: utilsTypes['newScrapPopup']['type']
  onError?: (errorMessage: string) => void;
}

export default function NewScrapPopupInputsActions({
  onLinkClick,
  onNotesClick,
  onImagesChange,
  onFilesChange,
  defaultOpen,
  onError,
}: Proptypes) {
  return (
    <div className="new-scrap-popup__inputs-actions">
      {onLinkClick && (
        <Button
          buttonType="outlined-with-background"
          className="new-scrap-popup__inputs-actions-button"
          onClick={onLinkClick}
        >
          <ChainIcon style={{ transform: 'rotate(90deg)' }} />
          Link +
        </Button>
      )}
      {onNotesClick && (
        <Button
          buttonType="outlined-with-background"
          className="new-scrap-popup__inputs-actions-button"
          onClick={onNotesClick}
          // disabled={disableCancel || spinnerConfig?.show}
        >
          <NoteIcon />
          Notes +
        </Button>
      )}
      <ScrapImagesUploader onError={onError} openFileSytemByDefault={defaultOpen === 'Images'} onChange={onImagesChange} multiple notValidHuman>
        {(onClick) => (
          <Button
            buttonType="outlined-with-background"
            className={`new-scrap-popup__inputs-actions-button${!onImagesChange ? ' new-scrap-popup__inputs-actions-button--hidden' : ''}`}
            onClick={onClick}
            // disabled={disableCancel || spinnerConfig?.show}
          >
            <ImagesIcon />
            Images +
          </Button>
        )}
      </ScrapImagesUploader>
      
      <ScrapImagesUploader
        openFileSytemByDefault={defaultOpen === 'File'}
        onChange={onFilesChange}
        fileType=".pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.txt"
        text="Up to 20 MB"
        upLoadType="File"
        multiple
        notValidHuman
        onError={onError}
      >
        {(onClick) => (
          <Button
            buttonType="outlined-with-background"
            className={`new-scrap-popup__inputs-actions-button${!onFilesChange ? ' new-scrap-popup__inputs-actions-button--hidden' : ''}`}
            onClick={onClick}
            // disabled={disableCancel || spinnerConfig?.show}
          >
            <FilePinIcon />
            Files +
          </Button>
        )}
      </ScrapImagesUploader>
    </div>
  );
}
