import React, { useEffect, useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { tagType } from '../../../types/tags';
import { GET_TAGS, ADD_TAG } from './queries';
import { collectionType } from '../../../types/collections';
import { addCollectionToStore, loadCollections } from '../../../redux/action/collectionAction';
import { UPSERT_COLLECTION } from '../../../containers/data/collection';
import CheckList from './CheckList';
import { setReloadSideNavFilters } from '../../../redux/action/utils';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../InvisibleRecaptcha';
import ValidateHuman from '../../../helpers/validators/isHuman';
import { parseTagFromSlug } from '../../../helpers';
import { COLLECTIONS_FOR_SIDE_NAV } from '../../../containers/data/collections';
import { ReduxStateType } from '../../../redux/store';

/*
  * COMPONENT DESCRIPTION
  * This component is getting used as a wrapper for providing a list of data along with checkboxes for operational
    purposes including creation of new data type (which is either tag or collection at the moment).
  * Component dependency include - /src/components/SideNav/SideNavDropdown/SideNavDropdownlistItem.tsx
  
  * Follow these instructions to add a new data type for using the same component other than tags or collections:
    ** Add the interface for the new data type as an or option to PropHelpers['dataType']
    ** The data type structure must include id and title for the new data type
    ** The same name as of the new data type must be mentioned in the PropTypes['itemDataType']
    ** The necessary inputs as can be seen are itemDataType, selectedItems and setSelectedItems which should be passed from the parent component
    ** Add query and mutation to fetch and create new item for the new data type at the end of #S1
    ** Update the useEffect (#U1) to set the main data based on the new data type
    ** Update the useEffect (#U2) to set the loading state for the new data type
    ** Update the function #C1 for the ability to create new item
*/

// DEFINE THE HELPERS TYPE TO BE USED IN THE COMPONENT RATHER THAN INDIVIDUALLY HANDLING
export interface PropHelpers {
  mergedDataType: Partial<tagType> & Partial<collectionType>;
  dataType: Partial<tagType> | Partial<collectionType>; // MAIN DATA TYPE GETTING USED FOR THE ITEM MANAGEMENT
  itemDataType: 'tagType' | 'collectionType'; // MAIN DATA TYPE GETTING USED FOR THE ITEM MANAGEMENT - string
  message: string | null;
}
interface PropTypes {
  allowCreation?: boolean; // TO BE USED TO TELL IF A NEW ITEM CAN BE CREATED IF NOT ALREADY PRESENT WHILE SEARCHING
  selectedItems: PropHelpers['dataType'][]; // SELECTED ITEMS OF THE LIST
  preSelectedData?: PropHelpers['dataType'][]; // SELECTED ITEMS OF THE LIST - TO BE PRIORITIZED AND SHOWN FIRST
  setSelectedItems: (newTagList: PropHelpers['dataType'][]) => void; // SET THE SELECTED ITEMS OF THE LIST
  placeholder?: string; // TO OVERRIDE THE DEFAULT PLACEHOLDER PRESENT FOR SEARCH
  itemDataType: PropHelpers['itemDataType']; // THIS REPRESENTS THE DATA TYPE TO BE USED FOR RENDERING
  tags?:boolean
}

export const convertTagsIntoCollection = (item: PropHelpers['dataType'] ) => {
  if(!item) return null;
  let collectionItem = item as collectionType;
  if(!collectionItem.id) {
    const { id, title } = parseTagFromSlug(collectionItem.slug);
    collectionItem.id = id;
    collectionItem.title = title;
  }
  if(!collectionItem.title) collectionItem.title = '';
  return collectionItem;
}

export const CheckboxList = (props: PropTypes) => {
  const {
    allowCreation,
    selectedItems,
    preSelectedData,
    setSelectedItems,
    placeholder,
    itemDataType,
    tags
  } = props;

  const user = useSelector((state: ReduxStateType) => state.user);

  const [allData, setAllData] = useState<PropHelpers['dataType'][]>([]);
  const [message, setMessage] = useState<PropHelpers['message']>(null);
  const [loading, setLoading] = useState<string | null>(null);

  const [filterList, setFilterList] = useState<PropHelpers['dataType'][]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const reCaptchaRef =  useRef<ReCAPTCHA>(null);


  const DISPATCH = useDispatch();
  // #S1  - START
  // TAGS QUERIES
  const { loading: tagsLoading, data: tagsData } = useQuery(GET_TAGS, {
    skip: itemDataType !== 'tagType',
    fetchPolicy: 'network-only',

  });
  const [upsertTag, { loading: addingTags }] = useMutation(ADD_TAG);
  // COLLECTION QUERIES
  const { loading: collectionsLoading, data: collectionsData } = useQuery(
    COLLECTIONS_FOR_SIDE_NAV,
    {
      variables: {
        roles: [0,1,2]
      }
    }
  );
  const [upsertCollection, { loading: addingCollection }] = useMutation(
    UPSERT_COLLECTION()
  );
  // #S1  - END
  
  // #U1 - SET ALL DATA ONCE THE QUERY IS COMPLETED
  useEffect(() => {
    const collectionsList = collectionsData?.collections?.data
    if (tagsData) {
      setAllData((tagsData?.tags?.data || []).map((tag) => ({
        slug: `${((tag?.title || '') as string).toLowerCase()}-${tag?.id}`
      })));
      if (tagsData?.tags?.data?.length) {
        searchHandler(searchTerm, (tagsData?.tags?.data || []).map((tag) => ({
          slug: `${((tag?.title || '') as string).toLowerCase()}-${tag.id}`
        })));
      }
    } else if (Array.isArray(collectionsList)) {
      setAllData(collectionsList);
      if (collectionsList.length) {
        searchHandler(
          searchTerm,
          collectionsList
        );
        DISPATCH(loadCollections(collectionsList));
      }
    }
  }, [tagsData, collectionsData]);

  // #U2 - UPDATE LOADING BASED ON QUERY OPERATIONS
  useEffect(() => {
    if (tagsLoading || collectionsLoading) {
      // SET LOADING ON FETCH DATA
      setLoading('Loading...');
    } else if (addingTags || addingCollection) {
      // SET LOADING ON ADD DATA
      setLoading('Adding new data...');
    } else {
      // RESET LOADING TO EMPTY
      setLoading(null);
    }
  }, [tagsLoading, collectionsLoading, addingTags, addingCollection]);

  // SET SELECTED ITEMS BASED ON CHECK AND UNCHECK OF ITEMS
  const onChange = (newTag: PropHelpers['dataType']) => {
    var tempData = selectedItems;
    if (selectedItems.findIndex((p) => convertTagsIntoCollection(p).id === convertTagsIntoCollection(newTag).id) >= 0) {
      tempData = selectedItems.filter((st) => convertTagsIntoCollection(st).id !== convertTagsIntoCollection(newTag).id);
    } else {
      tempData = [...selectedItems, newTag];
    }
    setSelectedItems(tempData);
  };

  // #C1 - CREAT A NEW DATA ITEM BY DISPATCHING A MUTATION FOR THE DATA TYPE
  const onCreate = async (name: string) => {

    const isHuman= await ValidateHuman(reCaptchaRef.current);

    if (!name || name.trim() === '' || !isHuman) return;
    if (itemDataType === 'tagType') {
      upsertTag({
        variables: {
          title: name,
        },
      })
        .then((res) => {
          const data = res.data.upsertTag
          const newTag = {
            slug: `${data.title}-${data.id}`
          };
          DISPATCH(setReloadSideNavFilters(true));
          // add to the local state
          let allTags = [newTag, ...allData];
          setAllData(allTags);
          setSelectedItems([...selectedItems, newTag]);
          searchHandler('', allTags);
        })
        .catch((err) => {
          setMessage('Error saving new Tag! please try again');
        });
    } else if (itemDataType === 'collectionType') {
      upsertCollection({
        variables: {
          title: name,
          state: 2
        },
      })
        .then((res) => {
          const collectionData = res?.data?.upsertCollection?.data;

          const newCollection = {
            id: collectionData.id,
            members: [],
            state: collectionData.state,
            title: collectionData.title,
            user_name: user.userName,
            slug: collectionData.slug
            
          };
          DISPATCH(addCollectionToStore(newCollection as collectionType));
          // add to the local state
          let tempData = [newCollection, ...allData];
          setAllData(tempData);
          setSelectedItems([...selectedItems, newCollection]);
          searchHandler('', tempData);
        })
        .catch((err) => {
          setMessage('Error saving new Collection! please try again');
        });
    }
  };

  // UPDATE THE STATE OF SEARCH TERM AND HANDLE SEARCH TO FILTER LIST ITEMS
  function searchHandler(text: string, itemsList: PropHelpers['dataType'][]) {

    if(itemDataType==='tagType'&&text.length>30)
    {
      return
    }
    let val = text?.trim() || '';
    
   
    if (val) {
      let finalList = itemsList
        .filter((item) =>
          convertTagsIntoCollection(item).title
            .trim()
            .toLocaleLowerCase()
            .includes(text.toLocaleLowerCase())
        )
        .sort((a, b) => {
          // Sort results by matching name with text(searched term) position in list item label fields
          if (
            convertTagsIntoCollection(a).title.toLowerCase().indexOf(text.toLowerCase()) >
            convertTagsIntoCollection(b).title.toLowerCase().indexOf(text.toLowerCase())
          ) {
            return 1;
          } else if (
            convertTagsIntoCollection(a).title.toLowerCase().indexOf(text.toLowerCase()) <
            convertTagsIntoCollection(b).title.toLowerCase().indexOf(text.toLowerCase())
          ) {
            return -1;
          } else {
            if (convertTagsIntoCollection(a).title > convertTagsIntoCollection(b).title) {
              return 1;
            }
            return -1;
          }
        });

      if (preSelectedData) {
        finalList = finalList.sort(
          (a, b) =>
            preSelectedData.findIndex((p) => convertTagsIntoCollection(p).id === convertTagsIntoCollection(b).id) -
            preSelectedData.findIndex((p) => convertTagsIntoCollection(p).id === convertTagsIntoCollection(a).id)
        );
      }
      setFilterList(finalList);
    } else {
      let finalList = itemsList;
      if (preSelectedData) {
        finalList = finalList.sort(
          (a, b) =>
            preSelectedData.findIndex((p) => convertTagsIntoCollection(p).id === convertTagsIntoCollection(b).id) -
            preSelectedData.findIndex((p) => convertTagsIntoCollection(p).id === convertTagsIntoCollection(a).id)
        );
      }
      setFilterList(finalList);
    }
    if(itemDataType==="tagType")
    {
      setSearchTerm(text.split(' ').join('_').toLowerCase());
    }
    else{
      setSearchTerm(text)
    }
  }

  return (
    <div className="side-nav-dropdown tags-list-wrapper">
      {loading ? (
        <span className="tags-list__loading">{loading}</span>
      ) : (
        <>
          <InvisibleRecaptcha inputRef={reCaptchaRef}/>
          <CheckList
            placeholder={placeholder}
            allData={allData}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchHandler={searchHandler}
            allowCreation={allowCreation}
            filterList={filterList}
            onCreate={onCreate}
            selectedItems={selectedItems}
            onChange={onChange}
            itemDataType={itemDataType}
          />
        </>
        
      )}
      {message && <span className="message__error">{message}</span>}
    </div>
  );
};

export default CheckboxList;
