import React from 'react';

import ImagesGroupCarousel from './uiComponents/ImagesGroupCarousel';
import ImagesGroupThumbnail from './uiComponents/ImagesGroupThumbnail';

import './styles.css';

interface Proptypes {
  images?: string[];
  mode?: 'thumbnail' | 'carousel';
  imageCount?: number;
  onClick?: () => void;
  children?: React.ReactElement;
  renderImage?: (index: number, defaultRender: React.ReactElement) => React.ReactElement;
}

function ImagesGroup({ images, mode = 'thumbnail',imageCount, onClick,children, renderImage }: Proptypes) {
  if (images?.length === 0) return null;
  if (images?.length === 1){
    if(images[0]){
      const imageElement = (
        <div className={`images-group images-group--single${onClick ? ' images-group--clickable' : ''}`} onClick={onClick}>
          {children}
          <img src={ images[0]} alt={images[0]} className="images-group__image" />
        </div>
      )
      if(renderImage) return renderImage(0, imageElement)
      return imageElement
    }
    return null
    
  }
    
  return (
    <div className={`images-group images-group--${mode}${onClick ? ' images-group--clickable' : ''}`} onClick={onClick}>
      {mode === 'carousel' ? (
        <ImagesGroupCarousel images={images} renderImage={renderImage} />
      ) : (
        <ImagesGroupThumbnail images={images} imageCount={imageCount}/>
      )}
    </div>
  );
}

export {
  ImagesGroup as default,
  ImagesGroupCarousel as ImagesCarousel,
  ImagesGroupThumbnail as ImagesThumbnail,
};
