import { useMutation } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { UPSERT_COLLECTION } from '../../../../containers/data/collection';
import ScrapFilePopupContainer from '../../../../containers/ScrapFilePopupContainer';
import ScrapNotePopupContainer from '../../../../containers/ScrapNotePopupContainer';
import { slugifyString } from '../../../../helpers';
import { parseTryCatchError } from '../../../../helpers/parseTryCatchError';
import { setNewScrapPopup, setReloadSideNavCollections } from '../../../../redux/action/utils';
import CreateCollectionModal from '../../../Collections/components/CreateCollectionModal';
import {
  ArchiveIcon,
  FileIcon,
  ImagesIcon,
  NotesIcon,
  WebPageIcon,
} from '../../../Global/icons';
import CreateScrapNavIcon from '../CreateScrapNavIcon';
import CreateScrapPopup from '../CreateScrapPopup';
import CreateWebPage from '../CreateWebPage';
import SelectScrap from '../SelectScrap';
import SelectScrapItem from '../SelectScrap/SelectScrapItem';
import * as config from '../../../../settings.json';

import './styles.css';

export interface CreateScrapPhaseType {
  mode?: 'scrap-type-defined' | 'scrap-type-undefined';
  type?: 'page' | 'image(s)' | 'file' | 'note' | 'collection' | 'new-scrap';
}

export interface NewScrapSelectOptionType {
  ScrapIcon: React.ElementType;
  title: string;
  value: CreateScrapPhaseType['type'];
  featureLabel?: string;
}

const optionsList: Array<NewScrapSelectOptionType> = [
  { ScrapIcon: NotesIcon, title: 'Scrap a Note', value: 'note', featureLabel: 'notes' },
  { ScrapIcon: ImagesIcon, title: 'Scrap Image(s)', value: 'image(s)', featureLabel: 'galleries' },
  {
    ScrapIcon: FileIcon,
    title: 'Scrap a File',
    value: 'file',
    featureLabel: 'documents',
  },
  { ScrapIcon: WebPageIcon, title: 'Scrap a Web Page', value: 'page', featureLabel: 'pages' },
  { ScrapIcon: ArchiveIcon, title: 'Create a Collection', value: 'collection' },
];

const configValues = { ...config };
// @ts-ignore - there is not point is exposing config here
// so just ignore this line for type checks
let excludedFeatures = configValues.default?.exclude || configValues.exclude || [];

// remove the features form the list that are disabled for the release
const options = optionsList.filter(opt => !excludedFeatures.includes(opt.featureLabel));

/* 
  * THIS IS JUST A REFERENCE TO OLD OPTION MENU SO AS TO UTILIZE THE OPTION VALUES FOR NEW ITEMS
  const options: Array<NewScrapSelectOptionType> = [
    { iconClass: 'window', title: 'Web Page', value: 'page' },
    { iconClass: 'cart', title: 'Product', value: 'product' },
    { iconClass: 'type', title: 'Text', value: 'note' },
    { iconClass: 'card-image', title: 'Image', value: 'screenshot' },
    { iconClass: 'images', title: 'Image Gallery', value: 'gallery' },
    { iconClass: 'file-earmark-richtext', title: 'PDF', value: 'document' },
    { iconClass: 'play-btn', title: 'Video', value: 'video' },
  ];
*/

const CreateScrapIconToggler = () => {
  const { push } = useHistory();
  const [phase, setPhase] = useState<CreateScrapPhaseType>(null);

  const dispatch = useDispatch();
  // COLLECTION MODAL STATES
  const [creatingCollection, setCreatingCollection] = useState(false);
  const [showCreateCollection, setShowCreateCollection] = useState(false);

  const [upsertCollection] = useMutation(UPSERT_COLLECTION());

  // CREATE WEB PAGE
  const [webPage, setWebPage] = useState(false);
  // CREATE IMAGES SCRAP POPUP TOGGLER
  // CREATE FILE SCRAP POPUP TOGGLER
  const [showFilePopup, setShowFilePopup] = useState(false);
  // CREATE NOTE SCRAP POPUP TOGGLER
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [showScrapPopup, setShowScrapPopup] = useState(false);

  const createScrapRef=useRef(null);
  /*
    TODO:
    * CREATE A NEW SHARABLE COMPONENT FOR COLLECTION CREATION AND SHARE THE SAME WITH SIDE NAV CONTAINER 
    * WAS NOT IMPLEMENTED AT THE MOMENT BECAUSE THE OTHER TEAM MEMBERS WERE WORKING ON THE CHILD COMPONENT REGARDING NEW DESIGNS AND UPDATES
      ** SO THE TO DO NEEDS TO BE WAIT UNTIL THOSE UPDATES ARE UP AND LIVE
  */

  // Function handler for creating new collection
  const createCollectionHandler = async (variables: {
    title: string;
    desc?: string;
    cover_image?: string;
    view_type?: number;
  }) => {    
    setCreatingCollection(true);
    try {
      var collectionData = await upsertCollection({
        variables,
      });
      console.log(collectionData,"test")
      setCreatingCollection(false);
      setShowCreateCollection(false);

      push(
        `/c/${slugifyString(
          collectionData.data.upsertCollection.data.title,
          false
        )}/${collectionData.data.upsertCollection.data.id}`
      );
      setCreatingCollection(false);
      dispatch(setReloadSideNavCollections(true));
    } catch (error) {
      setCreatingCollection(false);
      throw new Error(parseTryCatchError(error));
    }
    
  };
  const clickHandler = (option: NewScrapSelectOptionType) => {
    switch (option.value) {
      // case 'gallery':
      //   push('/gallery');
      //   setPhase(null);
      //   break;
      case 'collection':
        // OPEN NEW COLLECTION MODAL
        setShowCreateCollection(true);
        setPhase(null);
        break;
      case 'page':
        dispatch(setNewScrapPopup({
          type: 'Web',
        }))  
        setPhase(null);
        break;
      case 'image(s)':
        // dispatch(setScrapImagesPopup({ isGridMode: true, defaultEdit: true }));
        dispatch(setNewScrapPopup({
          type: 'Images',
        }))
        setPhase(null);
        break;
      case 'note':
        dispatch(setNewScrapPopup({
          type: 'Note',
        }))
        setPhase(null);
        break;
      case 'file':
        dispatch(setNewScrapPopup({
          type: 'File',
        }))
        setPhase(null);
        break;
      default:
        setPhase({
          type: option.value,
          mode: 'scrap-type-defined',
        });
        break;
    }
  };

  const render = () => {
    const { mode, type } = phase;
    if (mode === 'scrap-type-undefined') {
      return (
        <SelectScrap options={options} close={setPhase.bind(null, null)}>
          {(option, i) => (
            <SelectScrapItem
              option={option}
              key={type}
              onClick={clickHandler.bind(null, option)}
            />
          )}
        </SelectScrap>
      );
    } else return null;
  };

  const onOutsideClick = () => {
    setPhase((old) => {
      if (old?.mode === 'scrap-type-defined') return old;
      return null;
    });
  };
  return (
    <div ref={createScrapRef} onClick={()=>setShowScrapPopup(!showScrapPopup)} className="create-scrap-popup__wrapper">
      <CreateScrapNavIcon
        onClick={() => {
          if (phase) {setPhase(null)}
          else {setPhase({ mode: 'scrap-type-undefined' })}
        }}
        active={!!phase}
      />
      {/* {phase && (
        <div className={`create-scrap-popup`}>
          <div className="create-scrap-popup__content">{render()}</div>
        </div>
      )} */}
      {phase  && (
        <CreateScrapPopup
          hideOverflow={phase.mode === 'scrap-type-defined'}
          onOutsideClick={onOutsideClick}
        >
          {render()}
        </CreateScrapPopup>
      )}
      <CreateCollectionModal
        isCreating={creatingCollection}
        onCreate={createCollectionHandler}
        controlled={{
          open: showCreateCollection,
          setOpen: setShowCreateCollection,
        }}
      />
      <CreateWebPage
        controlled={{
          show: webPage,
          setShow: setWebPage,
        }}
      />
      <ScrapNotePopupContainer
        setShow={setShowNotePopup}
        show={showNotePopup}
      />
      <ScrapFilePopupContainer
        setShow={setShowFilePopup}
        show={showFilePopup}
      />
    </div>
  );
};

export default CreateScrapIconToggler;
