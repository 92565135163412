import React, { useState, useEffect, useRef } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { collectionType } from '../types/collections';
import { RequestStatusObjectType } from '../types/requestStatusType';
import { parseTryCatchError } from '../helpers/parseTryCatchError';
import CollaboratedCollections from '../components/CollaboratedCollections';
import ScrapControls from '../components/Global/ScrapControls';
import { useHistory } from 'react-router-dom';
import { getResponseMessages, slugifyString } from '../helpers';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../redux/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  setCollaborationPopup,
  setCollectionSettingsPopup,
  setIsMainPageLoading,
  setReloadSideNavCollections,
} from '../redux/action/utils';
import { setCollectionAction } from '../redux/action/utils';
import { COLLECTIONS_FOR_SIDE_NAV } from './data/collections';
import { collectionCardFooterOptionsType } from '../components/NewCollectionCard/UiComponents/NewCollectionFooter';
import NewCollectionCard from '../components/NewCollectionCard';
import SEOMeta from '../components/Meta/SEOMeta';
import GET_REACTIONS from './data/getReactions';
import { ReactionType } from '../components/Comments';
import ADD_REACTION from './data/addReaction';
import REMOVE_REACTION from './data/removeReaction';
import { setCollectionMessenger } from '../redux/action/messengersAction';
import MATCHED_BOOKMARKS from './data/matchedBookmarks';
interface Proptypes {
  allCollections?: boolean
}
const CollaboratedCollectionsContainer = ({allCollections}: Proptypes) => {
  const { push } = useHistory();
  const client = useApolloClient();
  const [addLike] = useMutation(ADD_REACTION());
  const [removeLike] = useMutation(REMOVE_REACTION());
  const [collections, setCollections] = useState<collectionType[]>([]);
  const [scrapsCount, setScrapsCount] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [fetchStatus, setFetchStatus] = useState<RequestStatusObjectType>({
    status: 'processing',
  });
  const [masonryKey, setMasonryKey] = useState('');
  const reactionsRef = useRef<ReactionType[]>([])
  const [reFetchCollection, setRefetchCollection] = useState(false);
  const [page, setPage] = useState(1);
  const [leaveLoader, setLeaveLoader] = useState(false);
  const [bookmarkedIds, setBookmarkedIds] = useState<Array<collectionType['id']>>([])
  const faSpinnerIcon = faSpinner as IconProp;
  const user = useSelector((state: ReduxStateType) => state.user);
  const collectionAction = useSelector(
    (state: ReduxStateType) => state.utils.collectionAction
  );
  const collectionMessenger = useSelector((state: ReduxStateType) => state.messengers.collectionMessenger);
  const { expanded } = useSelector((state: ReduxStateType) => state.utils);
  // const [lastCollection, setLastCollection] = useState<collectionType>(null);
  let first = 18;
  const dispatch = useDispatch();
  useEffect(() => {
    if (collectionAction) {
      const {
        collection: actionCollection,
        type: actionType,
      } = collectionAction;
      let newArr = [...collections];
      switch (actionType) {
        case 'edit':
          collections?.map((collection, i) => {
            if (collection.id === actionCollection.id) {
              //@ts-ignore
              newArr[i] = actionCollection;
              setCollections(newArr);
            }
            return true;
          });
          break;
        case 'delete':
          onScrapDelete(actionCollection as collectionType);
          break;
        default:
          break;
      }
    }
    dispatch(setCollectionAction(null));
  }, [collectionAction]);

  function footerOptionClickHandler(
    type: collectionCardFooterOptionsType['list'][number],
    collection
  ) {
    switch (type) {
      case 'edit':
        dispatch(setCollectionSettingsPopup({ collection }, false));
        break;
      case 'manageCollaborators':
        dispatch(
          setCollaborationPopup({
            collectionId: collection.id,
            onSaved: () => {},
          })
        );
        break;
      default:
        break;
    }
  }

  const footerDropdownOptions = (collection) => {
    const collectionIsMine = collection?.user_name === user.userName;
    const footerDropdownOptions: collectionCardFooterOptionsType = collectionIsMine
      ? {
        list: ['edit', 'share'],
        onOptionClick: (type) => footerOptionClickHandler(type, collection),
      }
      : {
        list: ['share'],
        onOptionClick: (type) => footerOptionClickHandler(type, collection),
      };
    return footerDropdownOptions;
  };


  const loadBookmarksHandler = async (ids: Array<number | string>, idKey: 'scrap_ids' | 'child_collection_ids') => {
    if(!ids.length) return []
    try {
      const response = await client.query({
        query: MATCHED_BOOKMARKS(),
        variables: {[idKey]: ids}
      })
      const {error,isSuccess} = getResponseMessages(response?.data?.matchedBookmarks)
      if(!isSuccess) throw new Error(error[0])
      const idsFromResponse: Array<collectionType['id']> = (response?.data?.matchedBookmarks?.data || []).map(item => item?.child_collection_id);
      const orderedIds: Array<collectionType['id']> = new Array(ids.length)
      ids.forEach((id, i) =>  {
        orderedIds[i] = idsFromResponse.find(data => data == id) ?? null;
      })
      return orderedIds;
    } catch (error) {
      
      return []
    }
  }
  
  async function loadLikesHandler(collection_ids: Array<collectionType['id']>) {
    const response = await client.query({
      query: GET_REACTIONS(),
      variables: {
        collection_ids,
        reactable_type: 2
      }
    })
    const fetchedReactions: Array<ReactionType> = response?.data?.getReactions?.data || []
    const orderedReactions: Array<ReactionType> = new Array(collection_ids.length)
    collection_ids.forEach((id, i) =>  {
      orderedReactions[i] = fetchedReactions.find(data => +data.id === +id) ?? null
    })
    return orderedReactions;
  }
  async function onScrapDelete(collection: collectionType, variables = {}) {
    const allCollections = [...collections];
    const collectionIndex = allCollections.findIndex(
      (item) => item.id === collection.id
    );
    setBookmarkedIds(old => old.filter((id, i) => i !== collectionIndex))
    allCollections.splice(collectionIndex, 1);
    try {
      const data = await fetchCollectionsHandler();

      if (
        Array.isArray(data.collections) &&
        data.collections.length === first
      ) {
        allCollections.push(data.collections[first - 1]);
      }
      setMasonryKey((Math.random() * 1000).toString());
      dispatch(setReloadSideNavCollections(true));
      setLeaveLoader(false);
      //@ts-ignore
      setTotalCount(data.total);
      setRefetchCollection(false);
      setFetchStatus({ status: 'success' });
    } catch (error) {
      if (collectionIndex > -1) {
        allCollections.splice(collectionIndex, 0, collection);
        // @ts-ignore
        setCollections((old) => ({ ...old, data: allCollections }));
        setMasonryKey((Math.random() * 1000).toString());
      }
      setFetchStatus({ status: 'error' });
    }

    setCollections((old) => {
      setMasonryKey((Math.random() * 1000).toString());
      return allCollections;
    });
  }

  async function fetchCollectionsHandler(lastCollection?:collectionType) {
    try {
      const response = await client.query({
        query: COLLECTIONS_FOR_SIDE_NAV,
        variables: {
          first: 40,
          collaboration: true,
          roles: allCollections ? [0,1,2] : [1],
          search_after: lastCollection
            ? lastCollection.updated_at
            : null,
          tie_breaker_param: 'id',  
          tie_breaker_id: lastCollection && lastCollection?.id,
          sort_param: 'updated_at',
        },
      });
      const data: {
        collections: collectionType[];
        total: number;
        scrapsCount:[];
      } = {
        collections: response.data.collections.data,
        total: response.data.collections.paginatorInfo.total,
        scrapsCount: response.data.collections.scrapsCount
      };
      if (Array.isArray(data.collections)) return data;
      else throw new Error('Failed to fetch collections!');
    } catch (error) {
      throw new Error(parseTryCatchError(error));
    }
  }
  async function loadMoreHandler() {
    let lastCollection;
    if (collections.length) {
      lastCollection=collections[collections.length - 1];
    }
    try {
      setFetchStatus({ status: 'processing' });
      const newPage = page + 1;
      const data = await fetchCollectionsHandler(lastCollection);
      if (!data.collections.length) throw new Error('Failed to load data');
      setCollections((old) => [...old, ...data.collections]);
      const collectionIds = data?.collections.map(collection => collection.id)
      if(collectionIds.length) {
        const reactionsResponse = await loadLikesHandler(collectionIds)
        reactionsRef.current.push(...reactionsResponse)
        const bookmarkedIdsFromResponse = await loadBookmarksHandler(collectionIds, 'child_collection_ids')
        setBookmarkedIds(old =>[...old, ...bookmarkedIdsFromResponse]);
      }
      
      setPage(newPage);
    } catch (error) {
      //
    }
    setFetchStatus({ status: 'success' });
  }

  // Reason: Might get utilized in future to validate if user is collaborator or not
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const amICollaborator = (collection) => {
    const collData = collection.collaborators.filter(
      (collaborator) =>
        Number(collaborator.role.role_id) === 1 &&
        collaborator.username === user?.userName
    );
    return collData.length > 0 ? true : false;
  };

  const initialLoadHandler = async () => {
    try {
      setPage(1);
      setFetchStatus({ status: 'processing' });
      const response = await fetchCollectionsHandler();
      const fetchedCollections = response.collections
      setCollections((prev) => {
        setMasonryKey((Math.random() * 1000).toString());
        return response.collections;
      });
      setScrapsCount(response.scrapsCount)
      dispatch(setReloadSideNavCollections(true));
      setLeaveLoader(false);
      setTotalCount(response.total);
      setRefetchCollection(false);
      const collectionIds = fetchedCollections.map(collection => collection.id)
      const reactionsResponse = await loadLikesHandler(collectionIds)
      reactionsRef.current = reactionsResponse
      const bookmarkedIdsFromResponse = await loadBookmarksHandler(collectionIds, 'child_collection_ids')
      setBookmarkedIds(bookmarkedIdsFromResponse);
      setFetchStatus({ status: 'success' });
    } catch (error) {
      setFetchStatus({ status: 'error', message: parseTryCatchError(error) });
    }
  }

  const likeClickedHandler = async (collectionId: collectionType['id'], collectionIndex: number, isLiked: boolean) => {
    try {
      const reactionData = reactionsRef.current[collectionIndex];
      if(reactionData) {
        reactionData.is_reacted = isLiked
      } else {
        reactionsRef.current[collectionIndex] = {
          id: +collectionId,
          is_reacted: isLiked,
          reactable_type: 2,
        }
      }
    } catch (error) {
      alert('Something went wrong');
    }
    
  }
  const bookmarkToggledHandler = (collectionIndex: number, action: 'save' | 'unsave') => {
    const collection = collections[collectionIndex]
    setBookmarkedIds(old => old.map((id, i) => {
      if(collectionIndex === i) {
        return action === 'save' ? collection.id : null;
      }
      return id;
    }))
  }

  

  useEffect(() => {
    initialLoadHandler();
  }, [reFetchCollection]);
  useEffect(() => {
    if(!collectionMessenger) return;
    const {type, commentsCount: comment_count, data: {
      id
    }} = collectionMessenger
    if(type === 'likes-and-comments') {
      setCollections(old => old.map(collection => {
        if(id == collection.id) {
          return {
            ...collection,
            comment_count
          }
        }
        return collection;
      }))
    }
    dispatch(setCollectionMessenger(null));
  }, [collectionMessenger])
  useEffect(() => {
    dispatch(setIsMainPageLoading(false));
  }, []);

  return (
    <>
      {leaveLoader && (
        <div className="delete-scrap__loading-overlay">
          <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
        </div>
      )}
      <SEOMeta title='Scrappi | Collaborations' />
      <CollaboratedCollections
        isLoading={fetchStatus.status === 'processing'}
        collections={collections}
        totalCount={totalCount}
        masonryKey={masonryKey+expanded}
        loadMore={loadMoreHandler}
        renderControls={
          fetchStatus.status === 'processing' && !collections?.length
            ? null
            : () => {
              return (
                <ScrapControls
                  scrapCount={totalCount}
                  countLabel="Collection"
                />
              );
            }
        }
      >
        {(collection, i) => {

          return (
            <NewCollectionCard
              isBookmarked={!!bookmarkedIds[i]}
              onBookmarkToggled={bookmarkToggledHandler.bind(null, i)}
              onLikeClicked={likeClickedHandler.bind(null, collection.id, i)}
              data={collection}
              cardLayout="compact"
              onCardClick={() => {
                push(
                  `/c/${slugifyString(collection.title, false)}/${
                    collection.id
                  }`
                );
              }}
              footerDropdownOptions={footerDropdownOptions(collection)}
              scrapCount={
                scrapsCount.filter((item) => item.id === collection.id)[0]
                  ?.scraps_count
              }
            />
          );
        }}
      </CollaboratedCollections>
    </>
  );
};

export default CollaboratedCollectionsContainer;
