import React from 'react';
import './styles.css';

import { Collaborator } from '../../../Global/icons';
import { collectionType } from '../../../../types/collections';
import CollectionHeaderAvatar from '../../../CollectionCreationWrapper/components/CollectionCreationHeader/CollectionHeaderAvatar';
import { useHistory } from 'react-router-dom';
import { GroupMemberType } from '../../../../containers/GroupShareContainer/types';
import { formatTimestampDate, showFullDate } from '../../../../helpers';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../../redux/store';

interface Proptypes {
  fromString?: string;
  collaborators?: collectionType['members'] | GroupMemberType[];
  avatar?: string;
  className?: string;
  maxLine?: number;
  count?: number;
  cover_image?: string;
  user_name?: string;
  display_name?: string;
  type?: 'collection' | 'group';
  groupNickName?: string;
  view?: 'all-shares' | 'group-page-details';
  showOwner?: boolean;
  date?: string;
}

const NewCollectionHeader = ({
  fromString,
  avatar,
  collaborators,
  className,
  maxLine,
  count,
  cover_image = '',
  user_name = '',
  display_name = '',
  type,
  groupNickName = '',
  view,
  showOwner: showAuthor,
  date
}: Proptypes) => {
  const user = useSelector((state: ReduxStateType) => state.user)
  const isGroup = type === 'group';
  const { push } = useHistory();
  const showOwner = showAuthor || (collaborators?.length <= 1 && type !== 'group');
  return (
    <div
      className={`new-collection-header ${className ? className : ''} ${
        !cover_image
          ? 'new-collection-header--show-full'
          : 'new-collection-header--show-half'
      }`}
    >
      <span
        className={`new-collection-header__collaborator  new-collection-header__collaborator--owner-${
          !isGroup && showOwner ? 'visible' : 'hidden'
        }`}
      >
        {((isGroup || !showOwner) && !groupNickName) && collaborators.length ? (
          <span className="new-collection-header__collaborator__icon">
            <Collaborator />
          </span>
        ) : null}
        {!(isGroup || !showOwner) && (
          <span className="new-collection-header__collaborator__owner">
            <CollectionHeaderAvatar
              fromString={fromString}
              className="new-collection-header__owner__icon"
              userName={user_name}
              image={avatar}
            />
          </span>
        )}

        {!showOwner && (groupNickName || collaborators.length) ? (
          <>
            <p className="new-collection-header__collaborator__members">
              {groupNickName && count > 1
                ? groupNickName
                : collaborators
                  ?.map((item) => item.display_name ?? item.user_name)
                  .filter((name) => !!name)
                  .join(', ')}
            </p>
            {/* {!groupNickName && count > 0 && view === 'all-shares' && (
              <span className={`new-collection-header__collaborator__count`}>
                {count}
              </span>
            )} */}
            <span className={`new-collection-header__collaborator__count`}>
              {count}
            </span>
          </>
        ) : null}
        {!isGroup && showOwner && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              push(`/${user_name}${fromString ?? ''}`);
            }}
            className="new-collection-header__owner-details"
          >
            {display_name && (
              <span className="new-collection-header__owner-details__display-name">
                {display_name}
              </span>
            )}
            {user_name && (
              <span className="new-collection-header__owner-details__user-name">
                {'@' + user_name}
              </span>
            )}
          </button>
        )}
        {date && (
          <span className="new-collection-header__date" title={showFullDate(date, user.timezone)}>          
            <span className="new-collection-header__date-pointer" />{' '}
            {formatTimestampDate(date, user.timezone)}
          </span>
        )}
      </span>
      {/* <span>{namesString}</span> */}
    </div>
  );
};

export default NewCollectionHeader;