import { gql } from '@apollo/client';
const GET_GROUP_SHARE_LIST = gql`
  query getGroupShareList($page: Int, $first: Int) {
    getGroupShareList(page: $page, first: $first) {
      paginatorInfo {
        total
        count
      }
      data {
        id
        user_id
        nickname
        members_count
        scraps_count
        owner_avatar
        owner_user_name
        owner_display_name
        reaction_count
        comment_count
        created_at
        updated_at
        members {
          id
          display_name
          user_id
          user_name
          avatar
        }
      }
      messages {
        error
        success
      }
    }
  }
`;
const GET_GROUP_SHARE_LIST_FOR_SIDE_NAV = gql`
  query getGroupShareList($page: Int, $first: Int) {
    getGroupShareList(page: $page, first: $first) {
      paginatorInfo {
        total
        count
      }
      data {
        id
        user_id
        nickname
        members_count
        owner_user_name
        owner_display_name
        members {
          user_id
          user_name
        }
      }
      messages {
        error
        success
      }
    }
  }
`;
export { GET_GROUP_SHARE_LIST as default, GET_GROUP_SHARE_LIST_FOR_SIDE_NAV };
