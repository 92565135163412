import React from 'react';
import GlobalSearchBarWrapper from '../../containers/GlobalSearchBarWrapper';
import { DoorIcon, ProfileIcon } from '../Global/icons';
import './PublicNavigation.css';
import { useLocation } from 'react-router-dom';
import { PROFILE_SETUP_PATH } from '../../helpers/config';

interface PublicNavigationProps {
  hideActionButtons: boolean;
}

const PublicNavigation = ({ hideActionButtons }: PublicNavigationProps) => {
  const {pathname} = useLocation();
  return (
    <nav className="public-navigation">
      <div className="public-navigation__search-wrapper">
        <div className="public-navigation__logo">
          <a href="/">
            <img
              src="/dashboard/img/Group.svg"
              alt="Scrappi"
              className="public-navigation__logo-desktop"
            />
            <img
              src="/dashboard/logo-icon.png"
              alt="Scrappi"
              className="public-navigation__logo-mobile"
            />
          </a>
        </div>

        {pathname !== PROFILE_SETUP_PATH && <div className="public-navigation__global-search">
          <GlobalSearchBarWrapper
            isPublic={true}
            placeholder="Find Scrappers & Collections"
          />
        </div>}
        {/* UNCOMMENT THE ABOVE TO IMPLEMENT PUBLIC SEARCH FOR NON LOGGED IN USERS */}
      </div>
      {!hideActionButtons && (
        <div className="public-navigation__link-wrapper">
          <a href="/login" className="public-navigation__link">
            <DoorIcon />
            <span className="public-navigation__link-label">Log In</span>
          </a>
          <a href="/register" className="public-navigation__link">
            <ProfileIcon />
            <span className="public-navigation__link-label-register">
              Sign Up
            </span>
          </a>
        </div>
      )}
    </nav>
  );
};

PublicNavigation.defaultProps = {
  hideActionButtons: false,
};

export default PublicNavigation;
