import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import ReCAPTCHA from 'react-google-recaptcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CountryIso2, CountrySelector } from 'react-international-phone';
import { phone } from 'phone';
import { REGISTER } from './mutations';
import AuthHeaderLayout from './components/AuthHeaderLayout';
import VerifyOTP from './components/VerifyOTP';
import GoogleButton, { GoogleProfile } from './components/GoogleButton';
import FacebookButton, { FacebookProfile } from './components/FacebookButton';
import AppleButton from './components/AppleButton';
import Account_Message from './components/Messages';
import { ExclamationIcon } from '../Global/icons';
import LabelTextInput from '../Global/LabelTextInput';
import InvisibleRecaptcha from '../Global/InvisibleRecaptcha';
import ConfirmationScreen from '../Global/ConfirmationScreen';
import TabSwitch from '../TabSwitch';
import { TabSwitchDefaultTabs } from '../TabSwitch/types';
import { LOGIN_VIA, PROFILE_SETUP_PATH } from '../../helpers/config';
import { mapUserZone } from '../../helpers/getUserTimeZone';
import { validateEmail } from '../../helpers/validators';
import { parseTryCatchError } from '../../helpers/parseTryCatchError';

import {
  defaultCountries,
  parseCountry,
} from 'react-international-phone';
import {allowedCountries} from '../../settings.json'
import { onlyNumbers, setToken } from '../../helpers';

import './react-international-phone.css';
import './styles.css';

const faSpinnerIcon = faSpinner as IconProp;

interface Proptypes {
  onSubmit: (
    email: string,
    password: string,
    onError: (message: string) => void,
    onSuccess: Function
  ) => void;
  handleRedirection?: (onboard_status?: boolean) => void;
}

interface SocialData {
  social_id: string;
  name?: string;
  email: string;
  first_name?: string;
  last_name?: string;
  email_verified: boolean;
  login_via?: number;
}

const Login = (props: Proptypes) => {
  const { handleRedirection } = props;
  const [googleRegisterMutation] = useMutation(REGISTER());
  const [email, setEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [googleProcessing, setGoogleProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [activeTab, setActiveTab] = useState<TabSwitchDefaultTabs['tabs']>(
    'Email'
  );
  const [countryCode, setCountryCode] = useState({
    dialCode: '1',
    country: 'us' as CountryIso2,
  });
  // Phone Number and Validation
  const [phoneNumber, setPhoneNumber] = useState('');
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  // FOR COUNTRY AND TIMEZONE
  const ct = require('countries-and-timezones');
  const [timezone, setTimeZone] = useState('');
  const [country, setCountry] = useState('');

  // For registration confirmation screen
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [socialFormData, setSocialFormData] = useState<
    SocialData | undefined
  >();
  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return allowedCountries.includes(iso2);
  });
  useEffect(() => {
    const getTimezone = async () => {
      let timezoneFromIntl = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let userTimeZone = await mapUserZone(timezoneFromIntl);
      let userCountry = await ct.getCountryForTimezone(userTimeZone);
      setTimeZone(userTimeZone);
      setCountry(await mapUserZone(userCountry?.name, 'country'));
    };
    getTimezone();
  }, []);

  // Handle Error
  const handleError = (err) => {
    // IF CONDITION TO CHECK FOR THE VALIDATION ERROR CAUSED DUE TO USERNAME BEING ALREADY TAKEN
    if (
      err &&
      err.graphQLErrors &&
      err.graphQLErrors[0]?.extensions?.validation
    ) {
      const { name, email: errorEmail } =
        err.graphQLErrors[0].extensions.validation || {};

      let message = 'Some error occurred';
      if (Array.isArray(errorEmail)) {
        message = errorEmail[0];
      } else if (Array.isArray(name)) {
        message = name[0];
      }
      setErrorMessage(message || parseTryCatchError(err));
    } else {
      setErrorMessage(parseTryCatchError(err));
    }
    setLoading(false);
  };

  const registerGoogle = (data: SocialData) => {
    setLoading(true);
    googleRegisterMutation({
      variables: {
        email: data.email,
        social_id: data.social_id,
        user_name: data.name,
        timezone: timezone,
        email_verified: data.email_verified,
        country: country,
        login_via: data.login_via ?? LOGIN_VIA.google,
        register_from: 1,
      },
    })
      .then((val) => {
        const registerResponse = val.data.register;
        var responseData = registerResponse.data;
        if (responseData.access_token) {
          setToken(responseData.access_token);
          if (responseData.user?.profile?.onboard_status) {
            window.location.replace(PROFILE_SETUP_PATH);
          } else {
            window.location.replace('/');
          }
        } else {
          let errorMessage = 'Something went wrong. Please try again later.';
          if (registerResponse?.messages?.error?.length > 0)
            errorMessage = registerResponse.messages.error[0];
          setErrorMessage(errorMessage);
          setLoading(false);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const registerRedirect = (profileObj: GoogleProfile | FacebookProfile) => {
    const formData = {
      social_id:
        // @ts-ignore
        profileObj.googleId || profileObj.facebookId || profileObj.userID,
      email: profileObj.email?.length > 0 ? profileObj.email : '',
      email_verified: profileObj.email ? true : false,
      login_via: profileObj.login_via ?? LOGIN_VIA.google,
    };
    setSocialFormData(formData);
    setShowConfirmation(true);
  };

  // Uncommenting this will be utilized for getting back the email password login functionality
  /*
  const onLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage(null);
    const isHuman = await ValidateHuman(reCaptchaRef.current);
    if (!isHuman) return;
    setIsProcessing(true);
    onSubmit(
      email,
      password,
      (message) => {
        setErrorMessage(message);
        setIsProcessing(false);
      },
      () => {}
    );
  };
  */
  // Return Form Fields based on Active Tab
  const returnFormFields = () => {
    if (activeTab === 'Email') {
      return (
        <>
          <div className="form-group">
            <LabelTextInput
              placeholder="Enter your email"
              inputType={'email'}
              inputName={'email'}
              value={email}
              label="Email"
              className={`input-field ${errorMessage ? 'is-invalid' : ''}`}
              onChange={(newEmail) => {
                setEmail(newEmail);
              }}
              disabled={isProcessing}
              required
              showLabelOnFocus
            />
          </div>
          {/* Removed password field for login, might be used later  */}
          {/* <div className="form-group mt-10">
            <LabelTextInput
              placeholder="Password"
              inputType={'password'}
              inputName={'password'}
              value={password}
              label="Password"
              className={`input-field ${errorMessage ? 'is-invalid' : ''}`}
              onChange={(newPass) => {
                setPassword(newPass);
              }}
              disabled={isProcessing}
              required
              showLabelOnFocus
              togglePass
            />
          </div> */}
        </>
      );
    }
    return (
      <div className="form-group auth__phone">
        <CountrySelector
          selectedCountry={countryCode.country}
          className="auth__phone-country"
          onSelect={({ dialCode, iso2: country }) => {
            setCountryCode({ dialCode, country });
          }}
          countries={countries}
        />
        <div className="auth__phone-country__dialcode">
          +{countryCode.dialCode}
        </div>
        <LabelTextInput
          placeholder="(201) 555-0123"
          inputType={'text'}
          inputName={'phoneNumber'}
          value={phoneNumber}
          className={`auth__phone-input ${errorMessage ? 'is-invalid' : ''}`}
          onChange={(val) => {
            setPhoneNumber(
              onlyNumbers({
                value: val,
                oldValue: phoneNumber,
                max: 13,
                compareLength: true,
              })
            );
          }}
          required
        />
      </div>
    );
  };

  // Check IF Get Code button needs to be disabled
  const isOTPDisabled = () => {
    return (
      isProcessing ||
      ((email.length <= 0 || !validateEmail(email)) && activeTab === 'Email') ||
      (!phone(phoneNumber, {
        country: countryCode.country,
      }).isValid &&
        activeTab === 'SMS')
    );
  };

  // Updating verifyOTP to show OTP Screen
  const updateVerifyOTP = () => {
    if (activeTab === 'Email') {
      if (validateEmail(email)) {
        setVerifyOTP(true);
        setErrorMessage('');
      } else {
        setErrorMessage('The email must be a valid email address.');
      }
    } else {
      if (
        phone(phoneNumber, {
          country: countryCode.country,
        }).isValid
      ) {
        setVerifyOTP(true);
        setErrorMessage('');
      } else {
        setErrorMessage('The number must be a valid phone number.');
      }
    }
  };

  const tabSwitchHandler = (tab: string) => {
    setActiveTab(tab as TabSwitchDefaultTabs['tabs']);
    setErrorMessage(null);
  }
  

  // Return VerifyOTP Screen or Login Screen
  const returnWrapper = () => {
    if (verifyOTP) {
      return (
        <VerifyOTP
          isLogin
          sentTo={
            activeTab === 'Email'
              ? email
              : `+${countryCode.dialCode} ${phoneNumber}`
          }
          isMobileNumber={activeTab !== 'Email'}
          countryCode={countryCode.dialCode}
          verifyFor="login"
          isProcesssing={isProcessing}
          setVerifyOTP={setVerifyOTP}
          setIsProcessing={setIsProcessing}
          onSuccess={() => {}}
          error={errorMessage}
          setError={setErrorMessage}
          confirmRegister
        />
      );
    }
    return (
      <form>
        <InvisibleRecaptcha inputRef={reCaptchaRef} />
        <p className="auth__message-otp">
          <strong>Log in</strong> easily with a one-time code.
        </p>
        <TabSwitch
          onChange={tabSwitchHandler}
          activeTab={activeTab}
        />
        {returnFormFields()}
        {errorMessage && (
          <div className="form-group">
            <span role="alert" className="sl-auth__error">
              <ExclamationIcon /> {errorMessage}
            </span>
          </div>
        )}

        <div className="form-group form-group__action">
          {/* Removed login button, might be used later */}
          {/* {activeTab === 'Email' && (
              <button
                className="button button__primary sl-auth__register-button"
                disabled={
                  googleProcessing ||
                  isProcessing ||
                  (email.length > 0 && password.length > 0 ? false : true)
                }
              >
                Log In
              </button>
            )} */}
          <button
            type="submit"
            className={`button sl-auth__register-button ${
              isOTPDisabled() ? 'button__secondary' : 'button__primary'
            } button-otp`}
            disabled={isOTPDisabled()}
            onClick={updateVerifyOTP}
          >
            {Account_Message.codeLabel}
          </button>
        </div>

        <div className="sl-auth__or">
          <span className="sl-auth__or-label">Or</span>
        </div>
        <GoogleButton
          processing={googleProcessing}
          label={Account_Message.googleLoginLabel}
          setProcessing={setGoogleProcessing}
          registerRedirect={registerRedirect}
          handleRedirection={handleRedirection}
          setMainLoading={setLoading}
        />

        <FacebookButton
          text={Account_Message.facebookLoginLabel}
          registerRedirect={registerRedirect}
          handleRedirection={handleRedirection}
          setMainLoading={setLoading}
        />

        <AppleButton
          text={Account_Message.appleLoginLabel}
          registerRedirect={registerRedirect}
          handleRedirection={handleRedirection}
          setMainLoading={setLoading}
        />
      </form>
    );
  };

  return (
    <AuthHeaderLayout title="Log In" helmetTitle="Scrappi">
      {showConfirmation ? (
        <ConfirmationScreen
          onConfirmClick={() => {
            setShowConfirmation(false);
            try {
              registerGoogle(socialFormData);
            } catch (e) {
              handleError(e);
            }
          }}
          errorMessage={Account_Message.accountNotFound}
          errorDescription={Account_Message.createAccountDescription}
          backButtonLabel={Account_Message.backToLogin}
          confirmButtonLabel={Account_Message.createAccount}
        />
      ) : (
        <>
          {(googleProcessing || isProcessing || loading) && (
            <div className="auth__loading">
              <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
            </div>
          )}
          {returnWrapper()}
        </>
      )}
    </AuthHeaderLayout>
  );
};

export default Login;