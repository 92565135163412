/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import LabelTextInput from '../Global/LabelTextInput';
import Button from '../uiComponents/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../Global/InvisibleRecaptcha';
import { RequestStatusObjectType } from '../../types/requestStatusType';
import { getScrapData } from '../CreateScrap/components/CreateWebPage/getScrapData';
import { parseTryCatchError } from '../../helpers/parseTryCatchError';
import ValidateHuman from '../../helpers/validators/isHuman';
import { TickIcon } from '../Global/icons';
import { scrapType } from '../../types/scrapType';

interface Proptypes {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataFetched: (data: any) => void;
  scrapId?: scrapType['id'];
}

const getYoutubeId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : false;
};

// using the regex to get the twitter tweet id from the url
const getTweetId = (url) => {
  const regExp = /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w-]*)/;
  const regExpX = /(?:http:\/\/)?(?:www\.)?x\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w-]*)/;

  const match = url.match(regExp) || url.match(regExpX);

  return match && match[1].length ? match[1] : false;
};

// using the regex to get the twitter tweet id from the url
const getTiktokId = (url) => {
  // Parse basic url and embeds
  const basicReg = /tiktok\.com(.*)\/video\/(\d+)/gm;
  const basicParsed = basicReg.exec(url);
  if (basicParsed && basicParsed.length > 2) {
    return basicParsed[2];
  }

  return false;
};

// create a function that checks if url has query param of not
// and return boolean value
const hasQueryParams = (url) => {
  const regExp = /\?/gm;
  const match = url.match(regExp);

  return match && match.length ? true : false;
};

// get the social post ids from the url
// Facebook and tiktok are not supported for now
function getSocialMeta(url, serviceType) {
  let result = null;

  switch (serviceType) {
    case 'twitter':
      url = hasQueryParams(url) ? url.split('?')[0] : url;
      result = {
        tweetId: getTweetId(url),
      };
      break;

    case 'youtube':
      result = {
        videoId: `https://www.youtube.com/embed/${getYoutubeId(url)}`,
      };
      break;

    case 'pinterest':
      url = hasQueryParams(url) ? url.split('?')[0] : url;
      result = {
        pinUrl: url,
      };
      break;

    case 'instagram':
      url = hasQueryParams(url) ? url.split('?')[0] : url;
      result = {
        instaUrl: url,
      };
      break;

    default:
      break;
  }

  return result;
}

export default function NewScrapPopupLinkInput({ onDataFetched, scrapId }: Proptypes) {
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const [status, setStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  const [url, setUrl] = useState('');
  const fetchHandler = async () => {
    let targetURL = url;

    // replace mixed case http or https to lowercase http or https
    if (targetURL.toLowerCase().includes('http') || targetURL.toLowerCase().includes('https')) {
      targetURL = targetURL.replace(/http[s]?:/i, (match) => match.toLowerCase());
    }

    // check if the url value is valid and has http or https
    // if not, add the value to the url
    if (!targetURL.includes('http') && !targetURL.includes('https')) {
      targetURL = `https://${targetURL}`;
    }

    try {
      setStatus({ status: 'processing' });
      const isHuman = await ValidateHuman(reCaptchaRef.current);
      if (!isHuman) {
        throw new Error('You must be a human!');
      }
      const response = await getScrapData(targetURL, scrapId);
      const data: any = response.data?.createScrap?.data;

      let { id, meta_title, meta_desc, favicon, image, price, service, html, url: scrapURL } = data || {};

      let isSocial = null;
      const hasService = service;
      if (hasService) {
        isSocial = getSocialMeta(targetURL, service);
      }
      const variables = {
        id,
        meta_title,
        meta_desc,
        price,
        meta: JSON.stringify(isSocial),
        service: hasService,
        original_favicon: favicon,
        url: scrapURL,
        html
      };
      if(image) variables['thumbnail'] = image;
      setStatus({
        status: 'success',
      });
      onDataFetched(variables);
    } catch (error) {
      setStatus({
        status: 'error',
        message: parseTryCatchError(error),
      });
    }
  };
  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await fetchHandler();
      }}
      className="new-scrap-popup__link"
    >
      <InvisibleRecaptcha inputRef={reCaptchaRef} />
      <LabelTextInput
        className="new-scrap-popup__link-input"
        placeholder="Enter URL"
        showLabelOnFocus={true}
        value={url}
        label="URL"
        onChange={setUrl}
      />
      <Button
        icon={<TickIcon />}
        className="new-scrap-popup__link-button"
        buttonType="orange-filled"
        disabled={!url || status.status === 'processing'}

      >
        Fetch
      </Button>
    </form>
  );
}
