import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['group_id', 'Int!'],
  ['first', 'Int'],
  ['page', 'Int'],
  ['tags', '[TagSlugInput]'],
  ['untagged', 'Boolean'],
]);

export default function GROUP_SCRAPS(responseString = '') {
  const dataString = responseString
    ? responseString
    : `
    paginatorInfo {
        total
        count  
    }
    share_scraps_comments {
      scrap_id
      comments {
        id
        user_id
        text
        created_at
        updated_at
        reaction_count
      }
      commenting_users {
        user_id
        user_name
        display_name
        avatar
      } 
    }
    reaction_comment_counts {
      scrap_id
      reaction_count
      comment_count
    }
    data {
        id
        user_id
        user_name
        display_name
        avatar
        title
        desc
        status
        state
        private_key
        service
        meta
        meta_title
        meta_desc
        price
        created_at
        updated_at
        url
        domain
        favicon
        original_favicon
        annotations {
            highlighted_text
        }
        annotations_count
        documents_count
        documents {
            file_name
            file_type
            file_path
        }
        audios_count
        audios{
            file_name
            file_path
        }
        videos_count
        videos{
            file_name
            file_path
        }
        images_count
        images {
            file_name
            file_path
            order
            file_status
            file_config
            desc
        }
        tags {
            slug
        }
        collections{
            id
        }
        order
        areFilesExpanded @client
        isUgcExpanded @client
        isMetaExpanded @client
        areAnnotationsExpanded @client
        isAnnotationExpanded @client
        isTagsExpanded @client
        isCollectionsExpanded @client
    }
  `;
  return gql`
  query GroupScraps(${parametersTypeString}){
    groupScraps(${parametersAssignString}){
      ${defaultResponse}
      ${dataString}
    }
  } 
  `;
}
