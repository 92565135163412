/* eslint-disable no-case-declarations */
import React, { useEffect, useState, useContext, useMemo, useRef } from 'react';

import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import GLOBAL_SEARCH_COLLECTION from './data/globalSearchCollection';
import GLOBAL_SEARCH_SHARE from './data/globalSearchShare';
import FILTER_SCRAPS from './data/filterScraps';
import GLOBAL_SEARCH_USERS from './data/globalSearchUsers';
import qs from 'qs';
import { useLocation, useHistory } from 'react-router-dom';
import { getResponseMessages, removeSpecialCharacters, returnScrapSlug, slugifyString } from '../helpers';
import { entryTypes } from '../types/feed';
import { collectionType } from '../types/collections';
import {
  GlobalSearchTabsType,
  GlobalSearchUserType, KnotFetch,
} from '../types/globalSearchDataType';
import SearchResults from '../components/SearchResults/Index';
import GlobalFiltersSearch from '../components/GlobalSearch/components/GlobalFiltersSearch';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../redux/store';
import {
  changeBatchEditMode,
} from '../redux/action/batchEditAction';
import { returnShareLink } from '../helpers/returnShareLinks';
import GlobalScrapperItem from '../components/GlobalSearch/components/GlobalScrappers/components/GlobalScrapperItem';
import ScrapControls from '../components/Global/ScrapControls';
import {
  defaultSortConfig as sortConfig,
} from '../components/Global/ScrapControls/ScrapControlsSort';
import {
  setCollectionAction,
  setDeleteScrapPopup,
  setScrapPopup,
  setIsMainPageLoading,
  setCollectionSettingsPopup,
  setCollaborationPopup,
  setWarningPopup,
} from '../redux/action/utils';
import ReloadContext from '../helpers/contexts/reloadContext';
import { scrapType } from '../types/scrapType';
import ScrapCard from '../components/ScrapCard';
import graphQlWrapper from '../apollo/client';
import NewCollectionCard from '../components/NewCollectionCard';
import { GroupType } from './GroupShareContainer/types';
import GLOBAL_USERS_COLLECTIONS_COUNT from './data/globalUsersCollectionsCount';
import { setOneScrapAction } from '../redux/action/onScrapActions';
import { collectionCardFooterOptionsType } from '../components/NewCollectionCard/UiComponents/NewCollectionFooter';
import LEAVE_GROUP from './data/leaveGroup';
import DELETE_GROUP from './data/deleteGroup';
import { setDrawer } from '../redux/action/drawerAction';
import SEOMeta from '../components/Meta/SEOMeta';
import { ReactionType } from '../components/Comments';
import GET_REACTIONS from './data/getReactions';
import ADD_REACTION from './data/addReaction';
import REMOVE_REACTION from './data/removeReaction';
import EmptyPageMessage from '../components/Global/EmptyPageMessage';
import { setCollectionMessenger, setGroupMessenger } from '../redux/action/messengersAction';
import MATCHED_BOOKMARKS from './data/matchedBookmarks';
import { updateScrapsLikesAndComments } from '../redux/action/scrapsLikesAndCommentsActions';
import { updateGroupsLikesAndComments } from '../redux/action/groupsLikesAndCommentsActions';
import { updateCollectionsLikesAndComments } from '../redux/action/collectionsLikesAndCommentsActions';


const { MyCollections, PublicCollections, Scrappers, Scraps, Shares } = KnotFetch

type KnotEnumsPropertyType = 'Scraps' | 'My Collections' | 'Public Collections' | 'Scrappers' | 'Shares';

const first = 30;
interface ParsedSearchType {
  type?: 'scraps' | 'scrappers' | 'collections' | 'shares';
  mode?: 'personal' | 'global';
  types?: string;
  tags?: string;
  untagged?: string;
  text?: string;
}
interface personalScrapsDataType {
  type: 'personal-scraps';
  data: scrapType[];
  total?: number;
}
interface personalCollectionsDataType {
  type: 'personal-collections';
  data: collectionType[];
  total?: number;
}
interface globalCollectionsDataType {
  type: 'global-collections';
  data: collectionType[];
  total?: number;
}

interface scrappersDataType {
  type: 'global-scrappers';
  data: GlobalSearchUserType[];
  total?: number;
}
interface groupSharesDataType {
  type: 'personal-shares';
  data: GroupType[];
  total?: number;
}




export type generalSearchResultsDataType =
  | personalScrapsDataType
  | personalCollectionsDataType
  | globalCollectionsDataType
  | scrappersDataType
  | groupSharesDataType;

// FUNCTION FOR RETURNING ACTIVE SORT LABEL BASED ON VALUES OF sort_by and sort_param
const returnActiveSort = ({
  sort_by,
  sort_param,
}: { sort_param?: string; sort_by?: string } = {}) => {
  if (!sort_by || !sort_param) return 'Relevancy';
  if (sort_by === 'desc' && sort_param === 'created_at') return 'Newest';
  else return 'Last Updated';
};

const publicClient = graphQlWrapper(null, true);
interface Proptypes {
  isPublic?: boolean;
}

const SearchResultsContainer = ({isPublic}: Proptypes) => {
  const oneScrapAction = useSelector((state: ReduxStateType) => state.oneScrapAction)
  const collectionMessenger = useSelector(
    (state: ReduxStateType) => state.messengers.collectionMessenger
  );
  const groupMessenger = useSelector(
    (state: ReduxStateType) => state.messengers.groupMessenger
  );
  const { reload, setReload } = useContext(ReloadContext);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const collectionAction = useSelector(
    (state: ReduxStateType) => state.utils.collectionAction
  );
  const batchEditMode = useSelector(
    (state: ReduxStateType) => state.batchEdit?.mode
  );
  const user = useSelector((state: ReduxStateType) => state.user);

  const { search, pathname } = useLocation();
  const parsedSearch: ParsedSearchType =  search ? qs.parse(search.substring(1),) : {};
  const { mode, type, text, tags: parsedTags, types, untagged } = parsedSearch;
  // VARIABLE TO FIND OUT WHICH TAB IS CURRENTLY ACTIVE
  let activeTab: KnotEnumsPropertyType;
  if (mode === 'global') {
    if (type === 'collections') {
      activeTab = PublicCollections;
    } else if (type === 'scrappers') {
      activeTab = Scrappers;
    }
  } else if (mode === 'personal') {
    if (type === 'scraps') {
      activeTab = Scraps;
    } else if (type === 'collections') {
      activeTab = MyCollections;
    } else if(type === 'shares') {
      activeTab = Shares;
    }
  }

  const [sortingParams, setSortingParams] = useState({
    sort_param: null,
    sort_by: null,
  });

  // const [scrapsComments, setScrapsComments] = useState<ScrapCommentsType[]>([]);
  const reactionsRef = useRef<{
    data: ReactionType[];
    currentTab: KnotEnumsPropertyType;
  }>({
    data: [],
    currentTab: activeTab,
  });
  const [bookmarkedIds, setBookmarkedIds] = useState<{
    data: Array<string | number>;
    currentTab: KnotEnumsPropertyType
  }>({
    data: [],
    currentTab: activeTab
  })
  // const [pagesConfig, setPagesConfig] = useState({
  //   [Scraps]: 1,
  //   [MyCollections]: 1,
  //   [PublicCollections]: 1,
  //   [Shares]: 1,
  //   [Scrappers]: 1,
  // });
  const [usersCollectionsCount, setUsersCollectionsCount] = useState<Array<{
    user_id: number;
    collections_count:number;
  }>>([])
  const [metaLoading, setMetaLoading] = useState(false);
  const [lastKnot, setLastKnot] = useState({
    [Scraps]: null,
    [MyCollections]: null,
    [PublicCollections]: null,
    [Shares]: null,
    [Scrappers]: null,
  });

  
  
  
  const client = useApolloClient();
  
  let activeSort = returnActiveSort(sortingParams);
  if (
    parsedSearch.mode === 'global' &&
    parsedSearch.type === 'collections' &&
    activeSort === 'Last Updated'
  ) {
    activeSort = 'Last edited';
  }

  
  
  
  let tags = [];
  if(parsedTags) {
    if(Array.isArray(parsedTags)) tags.push(...parsedTags.map(slug => ({
      slug
    }))) 
    else tags.push({
      slug: parsedTags
    })

  }
  

  const variablesMapper = {
    [Scraps]: {
      first,
      text,
      tie_breaker_id: lastKnot[Scraps] && lastKnot[Scraps].id,
      search_after: lastKnot[Scraps] && lastKnot[Scraps].updated_at,
      ...sortingParams,
      tags: tags && tags,
      types,
      untagged: !!untagged,
    },
    [MyCollections]: {
      first,
      search_after: lastKnot[MyCollections] && lastKnot[MyCollections].updated_at,
      tie_breaker_id: lastKnot[MyCollections] && lastKnot[MyCollections].id,
      text,
    },
    [PublicCollections]: {
      first,
      search_after: lastKnot[PublicCollections] && lastKnot[PublicCollections].updated_at,
      tie_breaker_id: lastKnot[PublicCollections] && lastKnot[PublicCollections].id,
      text,
    },
    [Shares]: {
      first,
      search_after: lastKnot[Shares] && lastKnot[Shares].updated_at,
      tie_breaker_id: lastKnot[Shares] && lastKnot[Shares].id,
      text,
    },
    [Scrappers]: {
      first,
      search_after: lastKnot[Scrappers] && lastKnot[Scrappers].updated_at,
      tie_breaker_param: 'id',
      text,
      tie_breaker_id: lastKnot[Scrappers] && lastKnot[Scrappers].id
    },
  };
  const variables = variablesMapper[activeTab];
  const scrapsQuery = useQuery(FILTER_SCRAPS(), {
    variables: variablesMapper[Scraps],
    fetchPolicy: 'cache-and-network',
  });

  const myCollectionsQuery = useQuery(GLOBAL_SEARCH_COLLECTION(), {
    variables: variablesMapper[MyCollections],
    fetchPolicy: 'cache-and-network',
    skip: isPublic
    
  });

  const publicCollectionsQuery = useQuery(GLOBAL_SEARCH_COLLECTION(), {
    variables: variablesMapper[PublicCollections],
    fetchPolicy: 'cache-and-network',
    client: publicClient
  });
  const groupSharesQuery = useQuery(GLOBAL_SEARCH_SHARE(), {
    variables: variablesMapper[Shares],
    fetchPolicy: 'cache-and-network'
  });


  const scrappersQuery = useQuery(GLOBAL_SEARCH_USERS(), {
    variables: variablesMapper[Scrappers],
    fetchPolicy: 'cache-and-network',
  });
  const queries = {
    [Scraps]: scrapsQuery,
    [MyCollections]: myCollectionsQuery,
    [PublicCollections]: publicCollectionsQuery,
    [Shares]: groupSharesQuery,
    [Scrappers]: scrappersQuery,
  };
  const filtersCount = {
    
    isLoaded: (function () {
      if (!queries[MyCollections].data && queries[MyCollections].loading) return false;
      else if (!queries[PublicCollections].data && queries[PublicCollections].loading) return false;
      else if (!queries[Scraps].data && queries[Scraps].loading) return false;
      else if (!queries[Shares].data && queries[Shares].loading) return false;
      else if (!queries[Scrappers].data && queries[Scrappers].loading) return false;
      else return true;
    })(),
  };
  
  const filterTabs: GlobalSearchTabsType = [];
  if (queries[Scraps].data?.filterScraps?.data?.length)
    filterTabs.push({ value: Scraps, label: 'Scraps' });
  if (queries[MyCollections].data?.globalSearchCollection?.data?.length)
    filterTabs.push({ value: MyCollections, label: 'Collections' });
  if (queries[PublicCollections].data?.globalSearchCollection?.data?.length)
    filterTabs.push({ value: PublicCollections, label: 'Public' });
  if (queries[Shares].data?.globalSearchShare?.data?.length)
    filterTabs.push({ value: Shares, label: 'Shares' });

  if (queries[Scrappers]?.data?.globalSearchUsers?.data?.knot?.length)
    filterTabs.push({ value: Scrappers, label: 'Scrappers' });
  const activeQuery = queries[activeTab];
  const { data, loading, previousData, reobserve, fetchMore, updateQuery } = activeQuery || {};
  let actualData = data || previousData; // THIS IS DONE TO PREVENT THE ODD BEHAVIOR HAPPENNING IN PAGINATION BECAUSE ON LOADING MORE RESULTS, DATA BECOMES NULL FOR FEW SECONDS
  if (!lastKnot[activeTab]) actualData = data; // THIS IS DONE TO PREVENT SHOWING OLD DATA IF THERE IS NO CACHE AND PAGE VALUE IS ONE
  
  // STORE KNOTS DATA BASED ON VALUE ACTIVE TAB WHICH WILL BE USED TO DISPLAY ON SEARCH RESULTS
  const knots: generalSearchResultsDataType = useMemo(() => {
    switch (activeTab) {
      case PublicCollections:
        return {
          type: 'global-collections',
          data: actualData?.globalSearchCollection?.data as collectionType[],
          total: actualData?.globalSearchCollection?.paginatorInfo?.total
        };
        
      case Scrappers:
        return {
          type: 'global-scrappers',
          data: actualData?.globalSearchUsers?.data?.knot,
          total: actualData?.globalSearchUsers?.paginatorInfo?.total,
        };
      case Scraps:
        return {
          type: 'personal-scraps',
          data: actualData?.filterScraps?.data as scrapType[],
          total: actualData?.filterScraps?.paginatorInfo?.total
        };
      case Shares:
        return {
          type: 'personal-shares',
          data: actualData?.globalSearchShare?.data,
          total: actualData?.globalSearchShare?.paginatorInfo?.total
        }
      case MyCollections:
        return {
          type: 'personal-collections',
          data: actualData?.globalSearchCollection?.data as collectionType[],
          total: actualData?.globalSearchCollection?.paginatorInfo?.total
        };
  
      default:
        break;
    }
  },[activeTab, actualData]);
  
  // THIS IS DONE TO AVOID ODD BEHAVIOR HAPPENING SOMETIME TO PREVENT SHOWING MORE DATA(DUE TO CACHE) THAN WHAT IS REQUIRED UPTO CERTAIN PAGE
  // if (knots?.data) {
  //   if (knots.data.length > pagesConfig[activeTab] * first) {
  //     knots.data = knots.data.slice(0, pagesConfig[activeTab] * first - 1);
  //   }
  // }
  const fromString = '?from=' + encodeURIComponent(pathname + search);
  const isLoading = !filtersCount.isLoaded || loading;

  const [leaveGroupMutation] = useMutation(LEAVE_GROUP());
  const [deleteGroupMutation] = useMutation(DELETE_GROUP());
  const [addLike] = useMutation(ADD_REACTION());
  const [removeLike] = useMutation(REMOVE_REACTION());


  const loadBookmarksHandler = async (
    ids: Array<number | string>,
    idKey: 'scrap_ids' | 'child_collection_ids'
  ) => {
    if (!ids.length) return [];
    try {
      const response = await client.query({
        query: MATCHED_BOOKMARKS(),
        variables: { [idKey]: ids },
      });
      const { error, isSuccess } = getResponseMessages(
        response?.data?.matchedBookmarks
      );
      if (!isSuccess) throw new Error(error[0]);
      const idsFromResponse: Array<collectionType['id']> = (
        response?.data?.matchedBookmarks?.data || []
      ).map(
        (item) =>
          item[idKey === 'scrap_ids' ? 'scrap_id' : 'child_collection_id']
      );
      const orderedIds: Array<collectionType['id']> = new Array(ids.length);
      ids.forEach((id, i) => {
        orderedIds[i] = idsFromResponse.find((data) => data == id) ?? null;
      });
      return orderedIds;
    } catch (error) {
      return [];
    }
  };

  const loadReactionsHandler = async (ids: Array<number| string>, type: generalSearchResultsDataType['type']) => {
    if(!ids.length) return [];
    const response = await client.query({query: GET_REACTIONS() , variables: {
      [type === 'personal-scraps' ? 'scrap_ids' : 'collection_ids']: ids,
      reactable_type: type === 'personal-scraps' ? 1 : 2      
    }})
    const fetchedReactions: Array<ReactionType> = response?.data?.getReactions?.data || []
    const orderedReactions: Array<ReactionType> = new Array(ids.length)
    ids.forEach((id, i) =>  {
      orderedReactions[i] = fetchedReactions.find(data => data.id == id) ?? null
    })
    return orderedReactions;
  }

  function resetPages() {
    setLastKnot({
      [MyCollections]: null, 
      [PublicCollections]: null,
      [Scrappers]: null,
      [Scraps]: null,
      [Shares]: null
    });
  }

  function loadMoreHandler() {
    try {
      if(knots.data.length && knots.data.length < knots?.total)
        setLastKnot((old) => ({ ...old, [activeTab]: knots.data[knots.data.length - 1] }));
    } catch (error) {
      //
    }
  }

  // FUNCTION HANDLER TO RESPOND ON CHANGING FILTERS WHEN CLICK OR AUTO SWITCH OCCURS ON TABS
  async function filtersChangeHandler(
    filterType: string,
    filterMode: string,
    forceRender: boolean = false
  ) {
    const { text, mode, type } = parsedSearch;
    if (filterType !== type || filterMode !== mode || forceRender) {
      if (batchEditMode) dispatch(changeBatchEditMode(false));
      resetPages();
      dispatch(setIsMainPageLoading(true));
      push(
        `/search?mode=${filterMode}&type=${filterType}${
          text && text.trim() !== '' ? `&text=${text}` : ''
        }`
      );
    }
  }
  // FUNCTION HANDLER TO RETURN LINK OF CARDS SHOWN IN SEARCH RESULTS
  function returnOnClickLink(
    knot: generalSearchResultsDataType['data'][number],
    type: generalSearchResultsDataType['type']
  ) {
    let link = '';
    switch (type) {
      case 'global-collections':
      case 'personal-collections':
        if (
          type === 'personal-collections' ||
          (knot as collectionType).user_name === user?.userName
        ) {
          link = `/c/${slugifyString(removeSpecialCharacters((knot as collectionType).title), false)}/${
            (knot as collectionType).id
          }`;
        } else
          link = returnShareLink(
            'collection',
            removeSpecialCharacters((knot as collectionType).title) + '-' + (knot as collectionType).id,
            (knot as collectionType).user_name,
            true
          );
        break;
      case 'global-scrappers':
        link = returnShareLink(
          'user',
          '',
          (knot as GlobalSearchUserType).user_name,
          true
        );
        break;
      case 'personal-scraps':
        link = returnShareLink(
          'scrap',
          returnScrapSlug(knot as entryTypes),
          (knot as entryTypes).userName,
          true
        );
        break;
      case 'personal-shares':
        link = `/shares/${(knot as GroupType).id}`  
    }
    if (link) link += fromString;
    return link;
  }
  
  async function likeClickHandler(id: number | string, knotIndex: number, currentlyReacted: boolean) {
    try {
      const reactable_type = knots.type === 'personal-scraps' ? 1 : 2
      const knotLike = reactionsRef.current.data[knotIndex];
      if(knotLike) {
        knotLike.is_reacted = !currentlyReacted;
      } else {
        reactionsRef.current.data[knotIndex] = {
          is_reacted: !currentlyReacted,
          id: +id,
          reactable_type
        }
      }
    } catch (error) {
      alert('failed to add reaction');
    }
    
    

  }

  // FUNCTION HANDLER FOR SORTING CHANGES
  async function sortHandler(val: string) {
    const selectedSort = sortConfig[
      val === 'Last Updated' ? 'Last edited' : val
    ] || {
      sort_by: null,
      sort_param: null,
    };
    dispatch(setIsMainPageLoading(true));
    setSortingParams(selectedSort);
    resetPages();
    dispatch(setIsMainPageLoading(false));
  }

  // FUNCTION HANDLER TO RENDER SCRAP CONTROLS
  function renderScrapControls() {
    // Returning null for now to hide scrap controls
    // return null;
    if (!knots?.data?.length) return;
    var countLabel = 'Scrap';
    if (knots.type.includes('collections')) {
      countLabel = 'Collection';
    } else if (knots.type.includes('scrappers')) {
      countLabel = 'Scrapper';
    }
    return (
      <ScrapControls
        scrapCount={knots?.total}
        countLabel={countLabel}
        loading={isLoading && !knots?.data?.length}
        hideCount
      >
        {/* {knots.type === 'personal-scraps' && (
          <ScrapControlsSelect
            onClick={() => {
              dispatch(changeBatchEditMode(!batchEditMode));
              dispatch(clearBatchEditScraps());
            }}
          />
        )} */}
        {/* {knots.type !== 'global-scrappers' && (
          <ScrapControlsSort
            onClick={() => {}}
            onOptionClick={sortHandler}
            options={[
              'Relevancy',
              knots.type === 'global-collections'
                ? 'Last edited'
                : 'Last Updated',
              'Newest',
            ]}
            activeOption={activeSort}
          />
        )} */}
      </ScrapControls>
    );
  }

  function collectionFooterDropdownOptionClick(
    type: collectionCardFooterOptionsType['list'][number],
    collection
  ) {
    switch (type) {
      case 'edit':
        dispatch(setCollectionSettingsPopup({ collection }, false));
        break;
      case 'manageCollaborators':
        dispatch(
          setCollaborationPopup({
            collectionId: collection.id,
            onSaved: () => {},
          })
        );
        break;
      default:
        break;
    }
  }

  // Handler function to update bookmarkedIds state when user saves/unsaves the knot
  const bookmarkToggledHandler = (knotIndex: number, action: 'save' | 'unsave') => {
    const knotItem = knots.data[knotIndex]
    setBookmarkedIds(old => ({
      ...old,
      data: old.data.map((id, i) => {
        if(i === knotIndex) return action === 'save' ? knotItem.id : null;
        return id
      })
    }))
  }


  // FUNCTION TO RENDER CARD ON SEARCH RESULTS
  function renderKnotItem(
    knot: generalSearchResultsDataType['data'][number],
    type: generalSearchResultsDataType['type'],
    index: number,
  ) {
    const metaData =
      type === 'personal-shares'
        ? (data?.globalSearchShare?.shareMetaData ?? [])[index]
        : (data?.globalSearchCollection?.collection_meta_data ?? [])[index];
    const scrapsCount = type === 'personal-shares' ? (data?.globalSearchShare?.scrapsCount ?? [])[index] : (data?.globalSearchCollection?.scrapsCount ?? [])[index];
    const collectionCount = usersCollectionsCount[index]?.collections_count
    const isBookmarked =  bookmarkedIds.currentTab === activeTab && !!bookmarkedIds.data[index];
    switch (type) {
      case 'personal-scraps':
        return (
          <ScrapCard
            onBookmarkToggled={bookmarkToggledHandler.bind(null,index)}
            isBookmarked={isBookmarked}
            // @ts-ignore
            onLikeToggled={user?.id && likeClickHandler.bind(null,knot.id, index)}
            fromString={fromString}
            scrap={knot as scrapType}
            onEditClick={() => {
              dispatch(
                setScrapPopup({
                  type: 'id',
                  defaultEdit: true,
                  data: (knot as scrapType).id,
                })
              );
            }}
            onDeleteClick={() => {
              dispatch(
                setDeleteScrapPopup({
                  scrap: knot as scrapType,
                  onComplete: (scrap) => {
                    knotDeletedHandler(scrap);
                    dispatch(setDeleteScrapPopup(null));
                  },
                })
              );
            }}
          />
        );

      case 'global-collections':
      case 'personal-collections':
        const collection: collectionType = {
          ...knot as collectionType,
          ...metaData,
          scraps_count: scrapsCount.scraps_count
        }
        const collectionIsMine = user.userName === collection.user_name;
        const footerDropdownOptions: collectionCardFooterOptionsType = {
          list: collectionIsMine ? ['edit', 'share'] : ['share'],
          onOptionClick: (type) => collectionFooterDropdownOptionClick(type, collection),
        };
        return (
          <NewCollectionCard
            isBookmarked={isBookmarked}
            onBookmarkToggled={bookmarkToggledHandler.bind(null,index)}
            onLikeClicked={user.user_id && likeClickHandler.bind(null, collection.id,index)}
            fromString={fromString}
            onCardClick={() => {
              if(metaData) {
                push(returnOnClickLink(collection, type));
              }  
            }}
            onMatchedScrapsClick={() => {
              dispatch(
                setDrawer({
                  type: 'matched-scraps',
                  data: {
                    collection: collection,
                    openLink: returnOnClickLink(collection, type),
                    usePublicClient: type === 'global-collections',
                  },
                })
              )
            }}
            data={collection}
            type="collection"
            cardLayout="compact"
            scrapCount={scrapsCount.scraps_count}
            footerDropdownOptions={footerDropdownOptions}
          />
        );
      case 'personal-shares':
        const group: GroupType = {...(knot as GroupType), ...{
          owner_user_name: metaData?.user_name,
          owner_display_name: metaData?.display_name,
          owner_avatar: metaData?.avatar,
          scraps_count: scrapsCount?.scraps_count,
          members: metaData?.members,
          comment_count: metaData?.comment_count,
          reaction_count: metaData?.reaction_count
        }}
        return (
          <NewCollectionCard
            onLikeClicked={likeClickHandler.bind(null, group.id, index)}
            fromString={fromString}
            onCardClick={() => {
              push(returnOnClickLink(group, type));
            }}
            type="group"
            data={group}
            cardLayout="compact"
            scrapCount={scrapsCount?.scraps_count}
            onMatchedScrapsClick={() => {
              dispatch(
                setDrawer({
                  type: 'matched-scraps',
                  data: {
                    group: group,
                    openLink: returnOnClickLink(group, type)
                  },
                })
              )
            }}
            // Commented for now to hide dropdown options in group
            // footerDropdownOptions={{
            //   list: group.owner_user_name === user.userName
            //     ? ['mute', 'bookmark', 'convert', 'editShareGroup', 'delete']
            //     : ['mute', 'bookmark', 'leave'],
            //   onOptionClick: groupFooterDropdownOptionClick.bind(null, data),
            // }}
          />
        );
      case 'global-scrappers':
        // const scrapper = ...{...knot as GlobalSearchTabsType}
        return (
          <GlobalScrapperItem
            scrapper={{...knot as GlobalSearchUserType, collection_count: collectionCount}}
            shareLink={returnOnClickLink(knot, type)}
          />
        );
    }
  }
  // FUNCTION HANDLER TO REMOVE KNOT FROM CACHE WHEN IT GETS DELETED
  function knotDeletedHandler(
    knot: Partial<scrapType> | Partial<collectionType> | Partial<GroupType>
  ) {

    const knotIndex = knots.data.findIndex(knotItem => knotItem.id == knot.id);
    reactionsRef.current.data.splice(knotIndex, 1);
    setBookmarkedIds(old => ({
      ...old,
      data: old.data.filter((_,i) => i !== knotIndex)
    }))
    const clientToUse = activeTab === 'Public Collections' ? publicClient : client
    clientToUse.cache.updateQuery(
      {
        query:
          activeTab === 'Scraps'
            ? FILTER_SCRAPS() 
            : GLOBAL_SEARCH_COLLECTION(),
        variables,
        overwrite: true,
      },
      (data) => {
        switch (activeTab) {
          case 'Scraps':
            return {
              ...data,
              filterScraps: {
                ...data.filterScraps,
                data: data.filterScraps.data.filter((item) => item.id !== knot.id),
                paginatorInfo: {
                  ...data?.filterScraps.paginatorInfo,
                  total: data?.filterScraps.paginatorInfo.total - 1
                }
              }
            }
          case 'My Collections':
          case 'Public Collections':
            return {
              ...data,
              globalSearchCollection: {
                ...data.globalSearchCollection,
                collection_meta_data: data.globalSearchCollection.collection_meta_data.filter(({id}) => id !== knot.id),
                data: data.globalSearchCollection.data.filter((item) => item.id !== knot.id),
                paginatorInfo: {
                  ...data.globalSearchCollection.paginatorInfo,
                  total: data.globalSearchCollection.paginatorInfo.total - 1
                }
              },
            };
          case 'Shares': 
            return {
              ...data,
              globalSearchShare: {
                ...data.globalSearchShare,
                shareMetaData: data.globalSearchShare.shareMetaData.filter(({id}) => id !== knot.id),
                data: data.globalSearchShare.data.filter((item) => item.id !== knot.id),
                paginatorInfo: {
                  ...data.globalSearchShare.paginatorInfo,
                  total: data.globalSearchShare.paginatorInfo.total - 1
                }
              },
            };
        
          default:
            break;
        }
        // const knotMapper =
        //   activeTab === 'Public Collections' ? 'globalSearch' : 'filterKnot';
        // if (Array.isArray(data[knotMapper]?.data.knot)) {
        //   return {
        //     ...data,
        //     [knotMapper]: {
        //       ...data[knotMapper],
        //       data: {
        //         ...data[knotMapper].data,
        //         knot: data[knotMapper].data.knot.filter(
        //           (item) => item.id !== knot.id
        //         ),
        //       },
        //     },
        //   };
        // }
        // return data;
      }
    );
    fetchMore({ variables });
  }
  

  useEffect(() => {
    if (!sortingParams.sort_by && !sortingParams.sort_param)
      setSortingParams({
        sort_by: null,
        sort_param: null,
      });
    resetPages();
    if (activeQuery?.data) reobserve();
  }, [search]);
  const updateKnotHandler = (knot: Partial<scrapType | collectionType>) => {
    const clientToUse =
      activeTab === 'Public Collections' ? publicClient : client;
    clientToUse.cache.updateQuery(
      {
        query:
          activeTab === 'Scraps' ? FILTER_SCRAPS() : GLOBAL_SEARCH_COLLECTION(),
        variables,
        overwrite: true,
      },
      (data) => {
        switch (activeTab) {
          case 'Scraps':
            const newData = {
              ...data,
              filterScraps: {
                ...data.filterScraps,
                data: data.filterScraps.data.map((item) => {
                  if (item.id === knot.id) {
                    return { ...item, ...knot };
                  }
                  return item;
                }),
              },
            };

            return newData;
          case 'My Collections':
          case 'Public Collections':
            return {
              ...data,
              globalSearchCollection: {
                ...data.globalSearchCollection,
                data: data.globalSearchCollection.data.map((item) => {
                  if (item.id === knot.id) {
                    const newData = { ...item, ...knot };
                    delete newData['show_author'];
                    return newData;
                  }
                  return item;
                }),
              },
            };

          default:
            break;
        }
        return data;
      }
    );
  };

  useEffect(() => {
    if (oneScrapAction.scrap || oneScrapAction.type && data) {
      const { scrap, type } = oneScrapAction;
      switch (type) {
        case 'edit':
          updateKnotHandler(scrap);
          break;
        case 'delete':
          knotDeletedHandler(scrap);
          break;

        default:
          break;
      }
      dispatch(setOneScrapAction(null))
    }
    
  
  }, [oneScrapAction]);

  useEffect(() => {
    if (!groupMessenger) return;
    if(!data) {
      dispatch(setGroupMessenger(null));
      return;
    }
    const {
      type,
      commentsCount: comment_count,
      data: { id },
    } = groupMessenger;
    if (type === 'likes-and-comments') {
      updateQuery((prevData) => {
        return {
          ...prevData,
          globalSearchShare: {
            ...prevData.globalSearchShare,
            shareMetaData: prevData.globalSearchShare.shareMetaData.map(
              (data) => {
                if (id == data.id) {
                  return {
                    ...data,
                    comment_count,
                  };
                }
                return data;
              }
            ),
          },
        };
      });
    }
    dispatch(setGroupMessenger(null));
  }, [groupMessenger]);

  useEffect(() => {
    if (!collectionMessenger) return;
    if(!data) {
      dispatch(setCollectionMessenger(null));
      return;
    }
    const { type, commentsCount: comment_count, data: { id } } = collectionMessenger;
    if (type === 'likes-and-comments') {
      updateQuery((prevData) => {
        return {
          ...prevData,
          globalSearchCollection: {
            ...prevData.globalSearchCollection,
            collection_meta_data: prevData.globalSearchCollection.collection_meta_data.map(
              (data) => {
                if (id == data.id) {
                  return {
                    ...data,
                    comment_count,
                  };
                }
                return data;
              }
            ),
          },
        };
      });
    }
    dispatch(setCollectionMessenger(null));
  },[collectionMessenger]);

  useEffect(() => {
    if (collectionAction && data) {
      const {
        collection: actionCollection,
        type: actionType,
      } = collectionAction;
      switch (actionType) {
        case 'edit':
          updateKnotHandler(actionCollection);
          break;
        case 'delete':
          knotDeletedHandler(actionCollection);
          break;

        default:
          break;
      }
    }
    dispatch(setCollectionAction(null));
  }, [collectionAction]);

  useEffect(() => {
    dispatch(setIsMainPageLoading(!filtersCount.isLoaded || loading));
  }, [filtersCount.isLoaded, loading]);


  
  useEffect(() => {
    if (reload) {
      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'filter' });
      switch (activeTab) {
        case MyCollections:
        case Scraps:
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'filterKnot' });
          break;
        case PublicCollections:
          client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'globalSearch' });
          break;
        case Scrappers:
          client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'globalSearchUsers',
          });
          break;

        default:
          break;
      }
      resetPages()
      setReload(false);
    }
  }, [reload]);
  const updateMetaListHandler = async () => {
    let isResetMeta = false
    if(metaLoading) {
      return;    
    }
    if(!['My Collections', 'Public Collections', 'Shares', 'Scrappers'].includes(activeTab)) {
      setUsersCollectionsCount([]);
      return;
    }
    if(activeTab === 'Scrappers') {

      const idsToFetch = [];
      const allIds = data.globalSearchUsers.data?.knot?.map(user => user.id);
      if(!allIds?.length) return;
      if(allIds[0] !==  usersCollectionsCount[0]?.user_id) {
        isResetMeta = true;
        idsToFetch.push(...allIds);
      }
      else {
        if(usersCollectionsCount.length  >= allIds.length) return;
        if(usersCollectionsCount[usersCollectionsCount.length - 1]?.user_id === allIds[usersCollectionsCount.length - 1]) idsToFetch.push(...allIds.slice(usersCollectionsCount.length))
      }
      
      if(!idsToFetch.length) return;
      setMetaLoading(true);
      const response = await client.query({
        query: GLOBAL_USERS_COLLECTIONS_COUNT(),
        variables: {
          user_ids: idsToFetch.map(user_id => ({
            user_id
          }))
        },
      })
      const metaData = response?.data?.globalUsersCollectionsCount?.data ?? [];
      const sortedData = new Array(idsToFetch.length);
      idsToFetch.forEach((id, i) => {
        const item = metaData.find(item => item.user_id === id)
        if(item) sortedData[i] = item
      })
      setUsersCollectionsCount(isResetMeta ? sortedData : [...usersCollectionsCount,...sortedData])
      setMetaLoading(false)
      
    } 
  }
  
  useEffect(() => {
    dispatch(setIsMainPageLoading(loading && !data));
  }, [loading, data]);

  useEffect(() => {
    if(!loading && data) {
      updateMetaListHandler();
      if(knots.type !== 'global-scrappers') {
        const reactionsScrapIds = reactionsRef.current.currentTab === activeTab && reactionsRef.current?.data.length
          ? knots.data.slice(reactionsRef.current.data.length).map((scrap) => scrap.id)
          : knots.data.map((scrap) => scrap.id);
        // @ts-ignore
        loadReactionsHandler(reactionsScrapIds, knots.type).then(reactionsResponse => {
          if(activeTab !== reactionsRef.current.currentTab) {
            reactionsRef.current = {
              currentTab: activeTab,
              data: reactionsResponse,
            }
          } else {
            reactionsRef.current.data.push(...reactionsResponse)
          }
          if(!reactionsResponse.length) return;
          const filteredReactions = reactionsResponse.filter(item => !!item)
          if(activeTab === 'Scraps') {
            
            dispatch(updateScrapsLikesAndComments(filteredReactions.map((reaction, i) => {
              const scrap = (knots.data as scrapType[]).find(scrapData => scrapData.id == reaction.id);
              return {
                scrapId: reaction.id,
                data: {
                  isLiked: reaction.is_reacted,
                  likesCount: scrap?.reaction_count,
                }
              }
            })))
          } else if(activeTab === 'Shares') {
            const groupsMeta= data?.globalSearchShare?.shareMetaData ?? []
            dispatch(updateGroupsLikesAndComments(filteredReactions.map(reaction => {
              const groupMeta = groupsMeta.find(meta => meta.id == reaction.id)
              return {
                groupId: reaction.id,
                data: {
                  likesCount: groupMeta?.reaction_count,
                  isLiked: reaction.is_reacted,
                  commentsData: {
                    count: groupMeta?.comment_count,
                  }
                }
              }
            })))
          } else {
            const collectionsMeta = data?.globalSearchCollection?.collection_meta_data ?? [];
            dispatch(updateCollectionsLikesAndComments(filteredReactions.map(reaction => {
              const collectionMeta = collectionsMeta.find(meta => meta?.id == reaction.id)
              return {
                collectionId: reaction.id,
                data: {
                  isLiked: reaction.is_reacted,
                  likesCount: collectionMeta?.reaction_count,
                  commentsData: {
                    count: collectionMeta?.comment_count,
                  }
                }
              }
            })))
          }
        })
      }
      if (
        [
          'personal-scraps',
          'personal-collections',
          'global-collections',
        ].includes(knots.type)
      ) {
        const bookmarkIdsToFetch =
          bookmarkedIds.currentTab === activeTab && bookmarkedIds.data.length
            ? knots.data
              .slice(bookmarkedIds.data.length)
              .map((scrap) => scrap.id)
            : knots.data.map((scrap) => scrap.id);
        loadBookmarksHandler(
          bookmarkIdsToFetch,
          activeTab === 'Scraps' ? 'scrap_ids' : 'child_collection_ids'
        ).then((ids) => {
          setBookmarkedIds(() => {
            if(activeTab !== bookmarkedIds.currentTab)
              return {
                currentTab: activeTab,
                data: ids,
              }
            return {
              ...bookmarkedIds, 
              data: bookmarkedIds?.data?.length
                ? [...bookmarkedIds.data, ...ids]
                : ids
            }  
          })
        });
      }

      
    }
  }, [loading, data]);
  useEffect(() => {
    setUsersCollectionsCount([]);
  }, [activeTab]);
  
  useEffect(() => {
    if(!isPublic) {
      const el: HTMLDivElement = document.querySelector('.search-results--public')
      if(el) {
        el.style.display = 'none'
      }
    }
  }, [isPublic])

  const masonryKey = useMemo(() =>  {
    return (Math.random() * 1000).toString()
  }, [sortingParams, search, reload, batchEditMode, filtersCount.isLoaded, knots?.total])
  if(!activeTab) return (
    <EmptyPageMessage header={{}}>
      <span>
        Looks like there are no results here. But you can always try later!
      </span>
    </EmptyPageMessage>);
  return (
    <>
      <SEOMeta title={`Scrappi | Search`} />
      <SearchResults
        isPublic={isPublic}
        loadMore={loadMoreHandler}
        renderScrapControls={renderScrapControls}
        loading={isLoading}
        key={search}
        masonryKey={masonryKey}
        knots={knots}
        renderFilters={() => (
          <GlobalFiltersSearch
            tabs={filterTabs}
            disabled={!filtersCount.isLoaded || !knots?.data?.length}
            selectedType={parsedSearch?.type}
            selectedMode={parsedSearch?.mode}
            onFilterChange={filtersChangeHandler}
            loading={!filtersCount.isLoaded}
          />
        )}
      >
        {renderKnotItem}
      </SearchResults>
    </>
    
  );
};

export default SearchResultsContainer;