import React, { useEffect, useState } from 'react';
import './styles.css';
import NewPopup from '../Global/NewPopup';
import { useLazyQuery } from '@apollo/client';
import GET_SUBSCRIPTION_ITEM_URL from '../../containers/data/getHostedPageForASubscriptionItem';
import { NewClose } from '../Global/icons';
import CANCEL_SUBSCRIPTION from '../../containers/data/cancelSubscription';

interface PropTypes {
    name?: string;
    price?: number;
    className?: string;
    buttonDetail?: string;
    isActive: boolean;
    included?: Array<string>;
    id?: string;
    isAnnual?: boolean;
    customerItemUnitPrice?: number;
    modifiedPriceId?: string;
    modifiedCustomerActivePlanPriceId?: string;
    modifiedCustomerActivePlanPriceFrequency?: string;

}

const PriceCard = ({
    name,
    price,
    buttonDetail,
    isActive,
    included,
    id,
    isAnnual,
    customerItemUnitPrice,
    modifiedPriceId,
    modifiedCustomerActivePlanPriceId,
    modifiedCustomerActivePlanPriceFrequency
}: PropTypes) => {


    const [openChargeBeePortal, setOpenChargeBeePortal] = useState<boolean>(false);
    const [openCancelSubscriptionPopup, setOpenCancelSubscriptionPopup] = useState<boolean>(false);
    const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
    const [itemPriceId, setItemPriceId] = useState<string | null>(null);
    const [unitPrice, setUnitPrice] = useState<string | null>(null);

    const isActivePlan =
        modifiedPriceId === modifiedCustomerActivePlanPriceId &&
        modifiedCustomerActivePlanPriceFrequency === (isAnnual ? 'Yearly' : 'Monthly');

    const isLowerPrice = price !== undefined && price < customerItemUnitPrice;

    const disablePackage = ((isActivePlan || isLowerPrice) && modifiedCustomerActivePlanPriceFrequency == (isAnnual ? 'Yearly' : 'Monthly') && price !== 0)


    const [
        fetchSubscriptionItemUrl,
        { data: subscriptionItemUrl, loading: subscriptionItemLoading },
    ] = useLazyQuery(GET_SUBSCRIPTION_ITEM_URL(), {
        variables: {
            itemPriceId,
            unitPrice
        },
    });

    const [
        cancelSubscriptionQuery,
        { data: cancelSubscriptionData, loading: cancelSubscriptionLoading },
    ] = useLazyQuery(CANCEL_SUBSCRIPTION);

    const onChangeSubscription = (itemPriceId: string, unitPrice: string) => {
        if (unitPrice == "0") {
            setOpenCancelSubscriptionPopup(true);
        }
        else {
            setOpenChargeBeePortal(true);
            setItemPriceId(itemPriceId);
            setUnitPrice(unitPrice);
        }

    };

    useEffect(() => {
        const fetchData = async () => {
            if (openChargeBeePortal && itemPriceId !== null && unitPrice !== null) {
                await fetchSubscriptionItemUrl({ variables: { itemPriceId, unitPrice } });

            }
        };
        fetchData();
    }, [openChargeBeePortal, itemPriceId, unitPrice]);

    useEffect(() => {

        if (cancelSubscriptionData?.data?.is_cancelled) {
            setOpenCancelSubscriptionPopup(false);
        }


    }, [cancelSubscriptionData])


    function render(close: () => void) {
        return (
            <>

                <div className="delete-scrap">

                </div>

                <p className='delete-scrap__message'>Are you sure that you want to unsubscribe?</p>
            </>
        );
    }

    function cancelSubscriptionHandler() {
        cancelSubscriptionQuery();
    }


    return (
        <>
            {(openChargeBeePortal && subscriptionItemUrl?.getHostedPageForASubscriptionItem?.data?.url) && (
                <NewPopup
                    defaultOpen

                    body={{
                        className: 'charge-bee-popup__body',
                    }}
                    className='charge-bee-popup'
                >
                    {close => (

                        <>
                            {
                                iframeLoaded
                                &&
                                <button
                                    onClick={() => {
                                        close();
                                        setOpenChargeBeePortal(false);
                                        setIframeLoaded(false);
                                    }}
                                    className="charge-bee-popup__button new-popup__header-button"
                                >
                                    <NewClose className="new-popup__header-button__icon" />
                                </button>

                            }

                            <iframe
                                onLoad={() => setIframeLoaded(true)}
                                src={subscriptionItemUrl?.getHostedPageForASubscriptionItem?.data?.url}
                                className='charge-bee-popup__iframe'
                            ></iframe>
                        </>

                    )}
                </NewPopup>
            )}

            {openCancelSubscriptionPopup && (
                <NewPopup
                    className='delete-scrap'
                    header={{
                        heading: 'Unsubscribe the plan',
                    }}
                    onClosed={() => setOpenCancelSubscriptionPopup(false)}
                    defaultOpen={true}
                    footer={{
                        onSubmit: () => cancelSubscriptionHandler(),
                        submitLabel: 'Delete',
                    }}
                >
                    {render}
                </NewPopup>
            )}
            <div className={`package${isActive ? ' package--active' : ''} ${disablePackage ? 'package--disable' : ''}`} onClick={() => onChangeSubscription(id || '', String(price) || '')}>
                <div className='package__name'>{name}</div>
                <div className={`package__price__${isAnnual ? 'annually' : 'monthly'}`}>${price}</div>
                <div className='package__trial'>{buttonDetail}</div>
                <ul className='package__list'>
                    {included && included.map((items, index) => (
                        <li key={index} className='package__list-item'>
                            <strong>{items}</strong>
                        </li>
                    ))}
                </ul>
            </div>
        </>

    );
};

export default PriceCard;