import React, { Dispatch, SetStateAction, useState } from 'react';
import { GOOGLE_LOGIN_MUTATION } from '../../../../containers/Login';
import { useMutation } from '@apollo/client';
import { ExclamationIcon } from '../../../Global/icons';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { performNativeSocialLogin, setToken } from '../../../../helpers';
import { ProfileType } from '../../types/ProfileType';
import './styles.css';
import { LOGIN_VIA } from '../../../../helpers/config';
export interface GoogleProfile {
  googleId: string;
  imageUrl: string;
  email: string;
  name: string;
  givenName: string;
  familyName: string;
  login_via?: number;
  userID?: string;
}
interface Proptypes {
  processing: boolean;
  label: string;
  setProcessing: (val: boolean) => void;
  registerRedirect: (profileObj: GoogleProfile) => void;
  handleRedirection?: (onboard_status?: boolean) => void;
  setMainLoading?: Dispatch<SetStateAction<boolean>>;
  overrideSubmit?: boolean;
  onSuccess?: (data?: ProfileType) => void;
}

const GoogleButton = (props: Proptypes) => {
  const {
    label,
    processing,
    setProcessing,
    registerRedirect,
    handleRedirection,
    setMainLoading,
    overrideSubmit,
    onSuccess,
  } = props;
  const [disableGoogle, setDisableGoogle] = useState(false);
  const [googleLoginMutation] = useMutation(GOOGLE_LOGIN_MUTATION);
  const checkGoogleUser = (accessToken: string, profileObj: GoogleProfile) => {
    googleLoginMutation({
      variables: {
        email: profileObj.email,
        social_token: accessToken,
      },
    }).then((val) => {
      var response = val.data.login;
      if (response.data.access_token) {
        setToken(response.data.access_token);
        if (handleRedirection) {
          handleRedirection(response.data.user?.profile?.onboard_status);
        } else {
          window.location.replace('/');
        }
      } else {
        setProcessing && setProcessing(false);
        setMainLoading && setMainLoading(false);
        registerRedirect && registerRedirect(profileObj);
      }
    });
  };

  const responseGoogle = useGoogleLogin({
    scope: 'openid profile email',
    onSuccess: (codeResponse) => {
      setMainLoading && setMainLoading(true);
      if (codeResponse.access_token) {
        var url = `https://www.googleapis.com/oauth2/v3/userinfo`;
        axios({
          method: 'get',
          url,
          params: {
            access_token: codeResponse.access_token,
            scope: codeResponse.scope,
          },
        })
          .then(function (response) {
            if (response.data) {
              const googleResponse = {
                accessToken: codeResponse.access_token,
                profileObj: {
                  googleId: response.data['sub'],
                  imageUrl: response.data['picture'],
                  email: response.data['email'],
                  name: response.data['name'],
                  givenName: response.data['given_name'],
                  familyName: response.data['family_name'],
                },
              };
              setProcessing(true);
              if (overrideSubmit) {
                onSuccess({
                  social_token: googleResponse.accessToken,
                  email: googleResponse.profileObj.email,
                  login_via: LOGIN_VIA.google,
                });
              } else {
                checkGoogleUser(
                  googleResponse.accessToken,
                  googleResponse.profileObj
                );
              }
            }
          })
          .catch((e) => {
            setProcessing(false);
            setDisableGoogle(true);
            setMainLoading && setMainLoading(false);
          });
      } else {
        setDisableGoogle(true);
        setMainLoading && setMainLoading(false);
      }
    },
    onError: () => {
      setDisableGoogle(true);
      setMainLoading && setMainLoading(false);
    },
  });

  return (
    <>
      <button
        onClick={() =>
          performNativeSocialLogin('google', () => responseGoogle())
        }
        disabled={processing || disableGoogle}
        className="google-button"
      >
        <img src="/dashboard/img/google.png" alt="Google" />
        <span>{label}</span>
      </button>
      {disableGoogle && (
        <div className="form-group google-error">
          <span role="alert" className="sl-auth__error">
            <ExclamationIcon /> Google Log In Failed.
          </span>
        </div>
      )}
    </>
  );
};

export default GoogleButton;
