import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import EmptyClipboard from './EmptyClipboard';
import { OnboardingPopStyles, OnboardingPopBaseStepsStyles } from "../OnboardingHelpers";

interface OnboardingProps {
  run?: boolean;
}

const OnboardingWelcome = ({ run }: OnboardingProps) => {
  const [runState] = useState(run || false);
  const [emptyClipboard, setEmptyClipboard] = useState(false);

  useEffect(() => {
    // rerun the component
  }, [run]);

  const stepsValue = {
    run: runState,
    steps: [
      {
        target: '.onboard-info',
        title: 'Welcome to Scrappi!',
        content: 'Create your first Scrap! Copy on dashboard anything and everything worth saving',
        disableBeacon: true,
        placement: 'right',
        isFixed: true,
        styles: OnboardingPopBaseStepsStyles,
      },
    ],
  };

  return (
    <div>
      {/* there is some issue in the libraries types with certain properties,
          so we need to ignore the typescript error */}
      <Joyride
        // @ts-ignore
        steps={stepsValue.steps}
        run={runState}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
        hideCloseButton
        hideBackButton
        callback={(data) => {
          if (data.action === 'close') {
            // setEmptyClipboard(true);
            console.log('close');
          }
        }}
        locale={{
          close: 'Skip',
          last: 'End tour',
        }}
        styles={OnboardingPopStyles}
      />

      <EmptyClipboard emptyClipboard={emptyClipboard} />
    </div>
  );
};

export default OnboardingWelcome;