import React, { useEffect, useState } from "react";
import NewPopup from "../../Global/NewPopup";

interface Proptypes {
  emptyClipboard?: boolean;
}

const EmptyClipboard = ( { emptyClipboard } : Proptypes) => {
  const [show, setShow] = useState(emptyClipboard || false);

  useEffect(() => {
    setShow(emptyClipboard);
  }, [emptyClipboard]);

  return (
    <NewPopup
      className='delete-scrap'
      controlled={{
        show: show,
        setShow: setShow,
      }}
      header={{
        heading: 'Clipboard empty',
      }}
      footer={{
        onSubmit: () => setShow(false),
        submitLabel: 'Ok',
      }}
    >
      <p>Do something here</p>
    </NewPopup>
  )
}

export default EmptyClipboard;