import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([['group_id', 'Int!']]);

export default function GET_GROUP(responseString = '') {
  const dataString = responseString
    ? responseString
    : `
    id
    user_id
    nickname
    owner_user_name
    owner_display_name
    owner_avatar
    members_count
    scraps_count
    members {
      id
      user_id
      avatar
      user_name
      display_name    
    }
    reaction_count
    comment_count
    updated_at
    created_at
  `;
  return gql`
  query GetGroup(${parametersTypeString}){
    getGroup(${parametersAssignString}){
      ${defaultResponse}
      data{
        ${dataString}
      }  
    }
  } 
  `;
}
