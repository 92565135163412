import React, { useMemo } from 'react';
import { NewGlobeIcon, PrivateIcon, ShareIcon } from '../icons';
import './styles.css';

interface Proptypes{
  state:number
  onClick?: () => void;
}

const StateLabel = ({ state, onClick }: Proptypes) => {
  const iconRenderer = useMemo(() => {
    switch (state) {
      case 3:
        return <NewGlobeIcon />
      case 2:
        return <ShareIcon />
      default:
        return <PrivateIcon />;
    }
  }, [state])
  return (
    <div className={`state-label${onClick ? ' state-label--clickable' : ''}`} onClick={onClick}>
      <span className="state-label__icon">
        {iconRenderer}
      </span>
    </div>
  );
};

export default StateLabel;
