import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import qs from 'qs';
import { ACCEPT_COLLECTION_COLLABORATOR } from './data/collaboration';
import { Register } from '../components/Account';
import PublicPageWrapper from '../components/Global/PublicPageWrapper';
import { mapUserZone } from '../helpers/getUserTimeZone';
import {
  INVITE_USER_PATH,
  LOGIN_VIA,
  PROFILE_SETUP_PATH,
} from '../helpers/config';
import { setToken } from '../helpers';
import {
  GET_EMAIL,
  REGISTER,
} from '../components/Account/mutations';
import { ProfileType } from '../components/Account/types';

const RegisterWrapper = (props) => {
  const ct = require('countries-and-timezones');
  const [registerMutation] = useMutation(REGISTER());
  const { search, pathname } = useLocation();
  const { replace } = useHistory();
  const urlParamValues = qs.parse(search.substring(1));
  const { token, type } = urlParamValues || {};
  const [timezone, setTimeZone] = useState('');
  const [country, setCountry] = useState('');

  // For collaboration token and type passed
  const [prefillData, setPrefillData] = useState<string | undefined>();
  const [countryCode, setCountryCode] = useState<string | undefined>();
  const [countryISO, setCountryISO] = useState<string | undefined>();
  const generalErrorMessage = 'Something went wrong, please try again';
  const [fetchErrorMessage, setFetchErrorMessage] = useState<
    string | undefined
  >();
  const [acceptCollaborator] = useMutation(ACCEPT_COLLECTION_COLLABORATOR);

  // For invited user - Start
  const { data: userData, loading } = useQuery(GET_EMAIL, {
    variables: { token },
    skip: !token || (!!type && type !== 'contact'),
  });
  useEffect(() => {
    if (token && !loading && pathname === INVITE_USER_PATH && userData) {
      if (userData.registerDetail?.data) {
        const uData = userData.registerDetail.data;
        if (uData.email || uData.phone) {
          setPrefillData(uData.email ?? uData.phone);
          if (uData.country_code) {
            setCountryCode(uData.country_code?.replace('+', ''));
            setCountryISO(uData.country_iso);
          }
        } else {
          replace(INVITE_USER_PATH);
        }
      }
    }
  }, [userData]);
  // For invited user - End

  useEffect(() => {
    const checkCollaborator = async () => {
      const response = await acceptCollaborator({
        variables: { token, type },
      });

      const collaboratorData = response?.data?.acceptCollectionCollaborator || {};

      if (
        collaboratorData?.status === 2 ||
        collaboratorData?.messages?.error?.length > 0
      ) {
        // error handling when server returns some error or status code equal to 2
        setFetchErrorMessage(
          collaboratorData.messages?.error[0] || generalErrorMessage
        );
      } else {
        // else we get email from the server
        setPrefillData(collaboratorData?.data?.email);
      }
    };
    if (token && type && type !== 'contact') {
      checkCollaborator();
    }
  }, [token, type]);

  useEffect(() => {
    const getTimezone = async () => {
      let timezoneFromIntl = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let userTimeZone = await mapUserZone(timezoneFromIntl);
      let userCountry = await ct.getCountryForTimezone(userTimeZone);
      setTimeZone(userTimeZone);
      setCountry(await mapUserZone(userCountry?.name, 'country'));
    };
    getTimezone();
  }, []);

  const submitHandler = (
    data: ProfileType,
    onError: (message: string) => void,
    onSuccess: Function
  ) => {
    registerMutation({
      variables: {
        email: data.email,
        password: data.password,
        password_confirmation: data.password,
        user_name: data.name,
        timezone: timezone,
        country: country,
        register_from: 1,
        ...(token ? { token } : {}),
        ...(type ? { type } : {}),
      },
    })
      .then((val) => {
        var response = val.data.register;
        if (response?.data?.access_token) {
          setToken(response.data.access_token);
          onSuccess();
          if (response.data.user?.profile?.onboard_status) {
            window.location.replace(PROFILE_SETUP_PATH);
          } else {
            window.location.replace('/');
          }
        } else {
          let errorMessage = 'Something went wrong. Please try again later.';
          if (response?.messages?.error?.length > 0)
            errorMessage = response.messages.error[0];
          onError(errorMessage);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const error: any = err;
        // IF CONDITION TO CHECK FOR THE VALIDATION ERROR CAUSED DUE TO USERNAME BEING ALREADY TAKEN
        if (
          error.graphQLErrors &&
          error.graphQLErrors[0]?.extensions?.validation?.email
        )
          onError(error.graphQLErrors[0]?.extensions?.validation?.email[0]);
        else if (
          error.graphQLErrors &&
          error.graphQLErrors[0]?.extensions?.validation?.name
        )
          onError(error.graphQLErrors[0]?.extensions?.validation?.name[0]);
        else onError('Some error has occurred. Please try again later.');
      });
  };

  const registerGoogle = (
    data: {
      social_id: string;
      name?: string;
      email: string;
      first_name?: string;
      last_name?: string;
      email_verified: boolean;
      login_via?: number;
    },
    onError: (message: string) => void,
    onSuccess: Function
  ) => {
    registerMutation({
      variables: {
        email: data.email,
        social_id: data.social_id,
        user_name: data.name,
        timezone: timezone,
        email_verified: data.email_verified,
        country: country,
        login_via: data.login_via ?? LOGIN_VIA.google, // 2 is for Google
        register_from: 1,
        ...(token ? { token } : {}),
      },
    })
      .then((val) => {
        var response = val.data.register;
        if (response.data.access_token) {
          setToken(response.data.access_token);
          onSuccess();
          if (response.data.user?.profile?.onboard_status) {
            window.location.replace(PROFILE_SETUP_PATH);
          } else {
            window.location.replace('/');
          }
        } else {
          let errorMessage = 'Something went wrong. Please try again later.';
          if (response?.messages?.error?.length > 0)
            errorMessage = response.messages.error[0];
          onError(errorMessage);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const error: any = err;
        // IF CONDITION TO CHECK FOR THE VALIDATION ERROR CAUSED DUE TO USERNAME BEING ALREADY TAKEN
        if (
          error.graphQLErrors &&
          error.graphQLErrors[0]?.extensions?.validation?.name
        )
          onError(error.graphQLErrors[0]?.extensions?.validation?.name[0]);
        else if (
          error.graphQLErrors &&
          error.graphQLErrors[0]?.extensions?.validation?.email
        )
          onError(error.graphQLErrors[0]?.extensions?.validation?.email[0]);
        else onError(err);
      });
  };

  return (
    <PublicPageWrapper>
      {!loading && (
        <Register
          onSubmit={submitHandler}
          registerGoogle={registerGoogle}
          prefillData={prefillData}
          prefillCountryCode={countryCode}
          prefillCountryISO={countryISO}
          errorMessage={fetchErrorMessage}
        />
      )}
    </PublicPageWrapper>
  );
};

export default RegisterWrapper;