import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import { formatTimestampDate, showFullDate } from '../../../helpers';
import Avatar from '../../Global/Avatar';
import userInitials from '../../../helpers/userInitial';

interface Proptypes {
  fromString?: string;
  userName: string;
  headline?: string;
  avatar: string;
  userDisplayName: string;
  created_at: string;
  updated_at: string;
}
const ScrapCardHeader = ({
  fromString,
  userName,
  headline,
  avatar,
  userDisplayName,
  created_at,
  updated_at,
}: Proptypes) => {
  const history = useHistory();

  const user = useSelector((state: ReduxStateType) => state.user);

  return (
    <div
      className="scrap-card__header"
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/${userName}${fromString ?? ''}`);
      }}
    >
      <div className="scrap-card__user-wrapper">
        <Avatar
          avatar={avatar}
          creator_initials={userInitials(userDisplayName)}
          userName={userName}
          className={'scrap-card__user-avatar'}
        />
        <div className="scrap-card__user-details">
          <div className="scrap-card__user-names-wrapper">
            {userDisplayName && (
              <span className="scrap-card__user-full-name">
                {userDisplayName}
              </span>
            )}

            <div className="scrap-card__time">
              {created_at && (
                <span
                  className="scrap-card__created-at"
                  title={showFullDate(created_at, user.timezone)}
                >
                  • {formatTimestampDate(created_at, user.timezone)}
                </span>
              )}
            </div>
          </div>

          {headline && (
            <span className='scrap-card__headline'>{headline}</span>
          )}

          <span className="scrap-card__user-name">{userName}</span>
        </div>
      </div>
    </div>
  );
};

export default ScrapCardHeader;
