import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  CountryIso2,
  CountrySelector,
  defaultCountries,
  parseCountry,
} from 'react-international-phone';
import phone from 'phone';
import AuthHeaderLayout from './components/AuthHeaderLayout';
import GoogleButton, { GoogleProfile } from './components/GoogleButton';
import FacebookButton, { FacebookProfile } from './components/FacebookButton';
import VerifyOTP from './components/VerifyOTP';
import AppleButton from './components/AppleButton';
import { ProfileType } from './types';
import LabelTextInput from '../Global/LabelTextInput';
import TabSwitch from '../TabSwitch';
import { TabSwitchDefaultTabs } from '../TabSwitch/types';
import { validateEmail } from '../../helpers/validators';
import { LOGIN_VIA, STORAGE_KEYS } from '../../helpers/config';
import { onlyNumbers, storageListener } from '../../helpers';
import './react-international-phone.css';
import { ExclamationSquare } from '../Global/icons';
import Account_Message from './components/Messages';
import './styles.css';
import { ReduxStateType } from '../../redux/store';
import { useSelector } from 'react-redux';
import Close from '../Global/icons/Close';
import {allowedCountries} from '../../settings.json'
const faSpinnerIcon = faSpinner as IconProp;

interface Proptypes {
  onSubmit?: (
    data: ProfileType,
    onError: (message: string) => void,
    onSuccess: Function
  ) => void;
  registerGoogle?: (
    data: {
      social_id: string;
      name?: string;
      email: string;
      first_name?: string;
      last_name?: string;
      email_verified: boolean;
      login_via?: number;
    },
    onError: (message: string) => void,
    onSuccess: Function
  ) => void;
  prefillData?: string;
  prefillCountryCode?: string;
  prefillCountryISO?: string;
  errorMessage?: string;
  editLoginMethod?: boolean;
  overrideSubmit?: boolean;
  onClose?: () => void;
}

const Register = ({
  onSubmit,
  registerGoogle,
  prefillData,
  prefillCountryCode,
  prefillCountryISO,
  errorMessage,
  editLoginMethod = false,
  overrideSubmit,
  onClose,
}: Proptypes) => {
  const [continueEmail, setContinueEmail] = useState(
    prefillData && !editLoginMethod ? true : false
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [googleProcessing, setGoogleProcessing] = useState(false);
  const [loading, setMainLoading] = useState(false);
  const user = useSelector((state: ReduxStateType) => state.user);
  const [email, setEmail] = useState('');
  const [activeTab, setActiveTab] = useState<TabSwitchDefaultTabs['tabs']>(
    'Email'
  );
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [countryCode, setCountryCode] = useState({
    dialCode: '1',
    country: 'us' as CountryIso2,
  });
  // Phone Number and Validation
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const inputRef = useRef(null);
  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return allowedCountries.includes(iso2);
  });
  const registerRedirect = (profileObj: GoogleProfile | FacebookProfile) => {
    setIsProcessing(false);
    const formData = {
      social_id:
        // @ts-ignore
        profileObj.googleId || profileObj.facebookId || profileObj.userID,
      email: profileObj.email?.length > 0 ? profileObj.email : email,
      email_verified: profileObj.email ? true : false,
      login_via: profileObj.login_via ?? LOGIN_VIA.google,
    };
    try {
      registerGoogle(
        formData,
        (error) => {},
        () => {}
      );
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    if (prefillData && !errorMessage) {
      !editLoginMethod && setContinueEmail(true);
      if (prefillCountryCode && prefillCountryISO) {
        setPhoneNumber(prefillData);
        const countryData = defaultCountries.filter((dc) => {
          const c = parseCountry(dc);
          return (
            c.dialCode === prefillCountryCode && c.iso2 === prefillCountryISO
          );
        });
        if (countryData.length) {
          setCountryCode({
            dialCode: prefillCountryCode,
            country: prefillCountryISO as CountryIso2,
          });
          setActiveTab('SMS');
        }
      } else {
        setEmail(prefillData);
      }
    }
  }, [prefillData, prefillCountryCode]);

  // For native error listener
  useEffect(() => {
    if (typeof document === 'object') {
      storageListener(STORAGE_KEYS.LOGIN_ERROR, (error: string) => {
        setError(error);
      });
    }
  }, []);
  // Return Form Fields based on Active Tab
  const returnFormFields = () => {
    if (activeTab === 'Email') {
      return (
        <div className="form-group">
          <LabelTextInput
            placeholder="Enter your email"
            inputType={'email'}
            inputName={'email'}
            value={email}
            label="Email"
            className={`input-field ${errorMessage ? 'is-invalid' : ''}`}
            onChange={setEmail}
            disabled={isProcessing || continueEmail}
            required
            showLabelOnFocus
            alwaysShowLabel={continueEmail}
            inputRef={inputRef}
          />
        </div>
      );
    }
    return (
      <div className="form-group auth__phone">
        <CountrySelector
          selectedCountry={countryCode.country}
          className="auth__phone-country"
          onSelect={({ dialCode, iso2: country }) => {
            setCountryCode({ dialCode, country });
          }}
          disabled={isProcessing || continueEmail}
          countries={countries}
        />
        <div className="auth__phone-country__dialcode">
          +{countryCode.dialCode}
        </div>
        <LabelTextInput
          placeholder="(201) 555-0123"
          inputType={'text'}
          inputName={'phoneNumber'}
          value={phoneNumber}
          className={`auth__phone-input ${errorMessage ? 'is-invalid' : ''}`}
          disabled={isProcessing || continueEmail}
          onChange={(val) => {
            setPhoneNumber(
              onlyNumbers({
                value: val,
                oldValue: phoneNumber,
                max: 13,
                compareLength: true,
              })
            );
          }}
          required
        />
      </div>
    );
  };

  // Check IF Get Code button needs to be disabled
  const isOTPDisabled = () => {
    return (
      isProcessing ||
      ((email.length <= 0 || !validateEmail(email)) && activeTab === 'Email') ||
      (!phone(phoneNumber, {
        country: countryCode.country,
      }).isValid &&
        activeTab === 'SMS')
    );
  };

  // Updating verifyOTP to show OTP Screen
  const updateVerifyOTP = () => {
    if (activeTab === 'Email') {
      if (validateEmail(email)) {
        setVerifyOTP(true);
        setError('');
      } else {
        setError('The email must be a valid email address.');
      }
    } else if (
      phone(phoneNumber, {
        country: countryCode.country,
      }).isValid
    ) {
      setVerifyOTP(true);
      setError('');
    } else {
      setError('The number must be a valid phone number.');
    }
  };

  const tabSwitchHandler = (tab: string) => {
    setActiveTab(tab as TabSwitchDefaultTabs['tabs']);
    setError(null);
  }

  // Return VerifyOTP Screen or Login Screen
  const returnWrapper = () => {
    const returnSuccessHandler = () => {
      if (overrideSubmit) {
        return (AddMethod) => {
          onSubmit(
            AddMethod,
            (err) => {
              setError(err);
              setIsProcessing(false);
              setGoogleProcessing(false);
              setMainLoading(false);
            },
            () => {
              setIsProcessing(false);
            }
          );
        };
      } else {
        return () => {};
      }
    };

    if (verifyOTP) {
      return (
        <VerifyOTP
          sentTo={
            activeTab === 'Email'
              ? email
              : `+${countryCode.dialCode} ${phoneNumber}`
          }
          isMobileNumber={activeTab !== 'Email'}
          countryCode={countryCode.dialCode}
          countryISO={countryCode.country}
          verifyFor="login"
          isProcesssing={isProcessing}
          setIsProcessing={setIsProcessing}
          onSuccess={returnSuccessHandler()}
          overrideSubmit={overrideSubmit}
          setVerifyOTP={setVerifyOTP}
          error={error}
          setError={setError}
          onClose={onClose}
        />
      );
    }

    return (
      <form onSubmit={e => {
        e.preventDefault();
        updateVerifyOTP();
      }}>
        <p className="auth__message-otp">
          {!editLoginMethod ? (
            <>
              <strong>
                {overrideSubmit
                  ? Account_Message.login
                  : Account_Message.signIn}
              </strong>{' '}
              easily with a one-time code.
            </>
          ) : (
            'Please enter your new email address.'
          )}
        </p>
        {!editLoginMethod && !continueEmail && !user.phone && (
          <TabSwitch
            onChange={tabSwitchHandler}
            activeTab={activeTab}
          />
        )}
        {returnFormFields()}
        <div className="form-group form-group__action">
          <button
            type='submit'
            className={`button sl-auth__register-button email__button ${
              isOTPDisabled() ? 'button__secondary' : 'button__primary'
            }`}
            disabled={isOTPDisabled()}
            onClick={updateVerifyOTP}
          >
            {Account_Message.codeLabel}
          </button>
        </div>
        {onClose && (
          <button className="login-popup__container__close-icon">
            <Close
              onClick={() => {
                onClose();
              }}
            />
          </button>
        )}
        {error && (
          <div className="sl-auth__error otp-error">
            <span className="otp-error__icon">
              <ExclamationSquare />
            </span>
            <span>{error}</span>
          </div>
        )}
        {!editLoginMethod && !continueEmail && (
          <>
            <div className="sl-auth__or">
              <span className="sl-auth__or-label">Or</span>
            </div>
            <GoogleButton
              processing={googleProcessing}
              label={Account_Message.googleButtonLabel}
              setProcessing={setGoogleProcessing}
              registerRedirect={registerRedirect}
              setMainLoading={setMainLoading}
              overrideSubmit={overrideSubmit}
              onSuccess={returnSuccessHandler()}
            />
            <FacebookButton
              text={Account_Message.facebookButtonLabel}
              registerRedirect={registerRedirect}
              setMainLoading={setMainLoading}
              overrideSubmit={overrideSubmit}
              onSuccess={returnSuccessHandler()}
            />
            <AppleButton
              text={Account_Message.appleButtonLabel}
              registerRedirect={registerRedirect}
              setMainLoading={setMainLoading}
              overrideSubmit={overrideSubmit}
              onSuccess={returnSuccessHandler()}
            />
          </>
        )}
      </form>
    );
  };

  return (
    <AuthHeaderLayout
      title={overrideSubmit ? Account_Message.login : Account_Message.signIn}
      helmetTitle="Scrappi"
    >
      <>
        {(googleProcessing || isProcessing || loading) && (
          <div className="auth__loading">
            <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
          </div>
        )}
        {errorMessage && (
          <span role="alert" className="sl-auth__error-wrapper">
            {errorMessage}
          </span>
        )}
        {returnWrapper()}
      </>
    </AuthHeaderLayout>
  );
};

export default Register;
