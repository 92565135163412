import React, { useCallback, useEffect, useRef, useState } from 'react';
import GET_GROUP_SHARE_LIST from './data/getGroupShareList';
import { useMutation, useQuery } from '@apollo/client';
import AllSharesCard from '../components/Global/AllSharesCard';
import NewCollectionCard from '../components/NewCollectionCard';
import { useHistory } from 'react-router-dom';
import { GroupType } from './GroupShareContainer/types';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../redux/store';
import { collectionCardFooterOptionsType } from '../components/NewCollectionCard/UiComponents/NewCollectionFooter';
import { setGroupDetailsPopup } from '../redux/action/popupsAction';
import { setCollectionSettingsPopup, setReloadSideNavShares, setWarningPopup } from '../redux/action/utils';
import LEAVE_GROUP from './data/leaveGroup';
import DELETE_GROUP from './data/deleteGroup';
import graphQlWrapper from '../apollo/client';
import { getResponseMessages } from '../helpers';
import { setGroupMessenger } from '../redux/action/messengersAction';
import SEOMeta from '../components/Meta/SEOMeta';
import RenderConvertMessage from '../components/GroupDetailsPopup/RenderConvertMessage';
import { ReactionType } from '../components/Comments';
import GET_REACTIONS from './data/getReactions';
import ADD_REACTION from './data/addReaction';
import REMOVE_REACTION from './data/removeReaction';
import { updateGroupsLikesAndComments } from '../redux/action/groupsLikesAndCommentsActions';

// Creating this new client instance to prevent the interference of cache for query
// GET_GROUP_SHARE_LIST between side navigation's and this component's useQuery
const client = graphQlWrapper(null);


const numOfRecords = 10;
const AllSharesContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const groupMessenger = useSelector((state: ReduxStateType) => state.messengers.groupMessenger);
  const user = useSelector((state: ReduxStateType) => state.user);

  const [page, setPage] = useState(1);
  const reactionsRef = useRef<ReactionType[]>([])
  
  let variables = {
    page: page,
    first: numOfRecords,
  };

  const { data: allShareData, loading, fetchMore } = useQuery(GET_GROUP_SHARE_LIST, {
    variables,
    fetchPolicy: 'cache-and-network',
    client,
  });
  const [leaveGroupMutation] = useMutation(LEAVE_GROUP());
  const [deleteGroupMutation] = useMutation(DELETE_GROUP());
  const [addLike] = useMutation(ADD_REACTION());
  const [removeLike] = useMutation(REMOVE_REACTION());

  let data: GroupType[] = allShareData?.getGroupShareList.data;
  let paginatorInfo = allShareData?.getGroupShareList?.paginatorInfo?.total;
  let count = allShareData?.getGroupShareList.paginatorInfo.count;
  const msKey = JSON.stringify({
    paginatorInfo,
    count,
  });
  const onLoadMore = () => {
    if (data.length % numOfRecords !== 0) return;
    setPage(Math.floor(data.length / numOfRecords) + 1);
  };

  const cardClickHandler = (id: string) => {
    history.push(`/shares/${id}`);
  };

  function deleteShareFromCache(group: GroupType) {
    dispatch(setReloadSideNavShares(true));
    client.cache.modify({
      id: 'ROOT_QUERY',
      fields: {
        getGroupShareList(prevCache) {
          return {
            ...prevCache,
            paginatorInfo: {
              ...prevCache.paginatorInfo,
              total: prevCache.paginatorInfo.total - 1,
            },
            data: prevCache.data.filter((item: GroupType) => {
              // @ts-ignore
              return item.id !== group.id;
            }),
          };
        }
      }
    })
    fetchMore({ variables });
  }

  async function loadLikesHandler(collection_ids: Array<GroupType['id']>) {
    const response = await client.query({
      query: GET_REACTIONS(),
      variables: {
        collection_ids,
        reactable_type: 2
      }
    })
    const fetchedReactions: Array<ReactionType> = response?.data?.getReactions?.data || []
    const orderedReactions: Array<ReactionType> = new Array(collection_ids.length)
    collection_ids.forEach((id, i) =>  {
      orderedReactions[i] = fetchedReactions.find(data => +data.id === +id) ?? null
    })
    return orderedReactions;
  }

  async function leaveGroupHandler(group: GroupType) {
    const res = await leaveGroupMutation({
      variables: {
        group_id: group.id,
      },
    });
    const { isSuccess, error } = getResponseMessages(res?.data?.leaveGroup);
    if(isSuccess) {
      deleteShareFromCache(group);
    }
    else throw new Error(error[0]);
  }
  async function deleteGroupHandler(group: GroupType) {
    const res = await deleteGroupMutation({
      variables: {
        group_id: group.id,
      },
    });
    const { isSuccess, error } = getResponseMessages(res?.data?.deleteGroup)
    if(isSuccess) {
      deleteShareFromCache(group)      
    }
    else throw new Error(error[0]);
  }

  async function likeClickHandler(shareId: GroupType['id'], shareIndex: number, isLiked: boolean) {
    try {
      const reactionData = reactionsRef.current[shareIndex];
      if(reactionData) {
        reactionData.is_reacted = isLiked;
      } else {
        reactionsRef.current[shareIndex] = {
          id: +shareId,
          is_reacted: isLiked,
          reactable_type: 2,
        }
      }
    } catch (error) {
      alert('Something went wrong');
    }
  }

  const dropdownOptionClickHandler = useCallback(
    (group: GroupType ,option: collectionCardFooterOptionsType['list'][number]) => {
      switch (option) {
        case 'editShareGroup':
          dispatch(
            setGroupDetailsPopup({
              type: 'groupData',
              data: group,
              dontRedirectOnMerge: true,
            })
          );
          break;
          
        case 'leave':
          dispatch(
            setWarningPopup({
              warningMessage:"Are you sure you want to leave this Share?",
              header:"Warning",
              leftLoader:true,
              cancel:{
                label:"Cancel",
              },
              submit:{
                label:"Yes",
                cb: leaveGroupHandler.bind(null, group)
              },
              processingLabel: 'Leaving'
            })
          ) 
          break;
        case 'delete':
          dispatch(
            setWarningPopup({
              warningMessage:"Are you sure you want to delete this Share?",
              header:"Warning",
              leftLoader:true,
              cancel:{
                label:"Cancel",
              },
              submit:{
                label:"Yes",
                cb: deleteGroupHandler.bind(null, group)
              },
              processingLabel: 'Deleting'
            })
          ) 
          break;
        case 'convert':
          dispatch(
            setWarningPopup({
              renderCustomMessage: () => <RenderConvertMessage />,
              className: 'convert-message',
              header: 'Convert',
              leftLoader:true,
              cancel: {
                label: 'Cancel',
              },
              submit: {
                label: 'Convert',
                cb: () => {
                  // deleteUser();
                  dispatch(
                    setCollectionSettingsPopup({
                      collection: group,
                      type: 'group-convert',
                    })
                  );
                },
              },
            })
          );  
          break;
        default:
          break;
      }
    },
    [variables]
  );
  
  useEffect(() => {
    if(groupMessenger) {
      const { type, data, commentsCount: comment_count } = groupMessenger;
      if(type === 'groups-merge') {
        client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getGroupShareList' });
        setPage(1);
      } else if(type === 'likes-and-comments') {
        client.cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            getGroupShareList(prevCache) {
              return {
                ...prevCache,
                data: prevCache.data.map((item: GroupType) => {
                  if (item.id === data.id) {
                    return {
                      ...item,
                      comment_count
                    };
                  }
                  return item;
                }),
              };
            },
          },
        });
      } else {
        client.cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            getGroupShareList(prevCache) {
              return {
                ...prevCache,
                data: prevCache.data.map((item: GroupType) => {
                  if(item.id === data.id) return { ...item, ...data };
                  return item;
                }),
              };
            }
          }
        })
      }
      
      dispatch(setGroupMessenger(null));
      
    }
  }, [groupMessenger])

  useEffect(() => {
    if (loading || !data?.length) return;
    const reactionsGroupIds = reactionsRef.current.length
      ? data.slice(reactionsRef.current.length).map((share) => share.id)
      : data.map((share) => share.id);
    loadLikesHandler(reactionsGroupIds).then((reactions) => {
      dispatch(updateGroupsLikesAndComments(reactions.map(reaction => {
        const group = data.find(groupData => +groupData.id === +reaction.id)
        return {
          groupId: reaction.id,
          data: {
            isLiked: reaction.is_reacted,
            likesCount: group?.reaction_count,
            commentsData: {
              count: group?.comment_count,
            }
          }
        }
      })))
      reactionsRef.current.push(...reactions)
    });
  }, [loading, data]);


  return (
    <>
      <SEOMeta title={`Scrappi | All Shares`} />
      
      <AllSharesCard
        shareData={data}
        onLoadMore={onLoadMore}
        masonryKey={msKey}
        loading={loading}
      >
        {(data, i) => {
          const groupisMine = data?.owner_user_name === user.userName;
          return (
            <NewCollectionCard
              onLikeClicked={likeClickHandler.bind(null, data.id, i)}
              footerDropdownOptions={{
                list: groupisMine
                  ? ['bookmark', 'convert', 'editShareGroup', 'delete']
                  : ['bookmark', 'leave'],
                onOptionClick: dropdownOptionClickHandler.bind(null, data),
              }}
              view="all-shares"
              scrapCount={data.scraps_count}
              data={data}
              type="group"
              onCardClick={cardClickHandler.bind(null, data.id)}
              cardLayout='compact'
            />
          );
        }}
      </AllSharesCard>
    </>
  );
};

export default AllSharesContainer;