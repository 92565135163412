import React, { useMemo, useState } from 'react';
import {
  BookmarkIcon,
  CollectionOutlined,
  HeartIcon,
  InviteIcon,
  LeaveIcon,
  ReplyIcon,
  ShareIcon,
  SlashedEye,
  TrashOutlineIcon,
} from '../../../Global/icons';
import TripleDot from '../../../Global/icons/TripleDot';
import MyDropDown from '../../../Global/MyDropDown';
import './styles.css';
import PencilEdit from '../../../Global/icons/PencilEdit';
import {
  formatTimestampDate,
  handleShare,
  returnTimeDifference,
  showFullDate,
} from '../../../../helpers';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../../redux/store';
import { returnShareLink } from '../../../../helpers/returnShareLinks';
import Icon from '../../../Global/Icon';
import { Collaborator } from '../../../Global/icons';
import Organize from '../../../Global/icons/Organize';
import StateLabel from '../../../Global/StateLabel';

interface dropdownType {
  valueField: footerOptionsType;
  textField: string;
  icon?: React.ReactElement;
  isIconStroked?: boolean;
  className?: string;
}

interface Proptypes {
  isBookmarked?: boolean;
  isLiked?: boolean;
  onLikeClick?: () => void;
  dropdownOptions?: collectionCardFooterOptionsType;
  scrapCount?: number;
  visibilityStatus?: number;
  userName?: string;
  slug?: string;
  private_key?: string;
  updatedAt?: string;
  createdAt?: string;
  onCommentsClick?: () => void;
  commentsCount?: number;
  reactionsCount?: number;
}
type footerOptionsType = 'edit' | 'manageCollaborators' | 'share' | 'organize' | 'leaveCollection' | 'mute' | 'bookmark' | 'convert' | 'editShareGroup' | 'delete' | 'leave';
export interface collectionCardFooterOptionsType {
  list: Array<footerOptionsType>;
  onOptionClick: (type: footerOptionsType) => void;
}


const NewCollectionCardFooter = ({
  isBookmarked,
  dropdownOptions,
  scrapCount,
  visibilityStatus,
  slug,
  private_key,
  userName,
  updatedAt,
  createdAt,
  isLiked,
  onLikeClick,
  onCommentsClick,
  commentsCount,
  reactionsCount,
}: Proptypes) => {
  const optionsMapper: {
    [key in footerOptionsType]: dropdownType;
  } = {
    edit: {
      valueField: 'edit',
      textField: 'Edit',
      icon: <PencilEdit />,
    },
    manageCollaborators: {
      valueField: 'manageCollaborators',
      textField: 'Manage Collaborators',
      icon: <Collaborator />,
    },
    share: {
      valueField: 'share',
      textField: 'Share Collection',
      icon: <ShareIcon />,
    },
    organize: {
      valueField: 'organize',
      textField: 'Organize Collection',
      icon: <Organize />,
    },
    leaveCollection: {
      valueField: 'leaveCollection',
      textField: 'Leave',
      icon: <LeaveIcon />
    },
    // Group options
    mute: {
      valueField: 'mute',
      textField: 'Mute',
      icon: <SlashedEye />
    },
    bookmark: {
      valueField: 'bookmark',
      textField: isBookmarked ? 'Saved' : 'Save',
      icon: <BookmarkIcon isFilled={isBookmarked} />,
      className: isBookmarked ? 'dropdown__item--active' : '' 
    },
    convert: {
      valueField: 'convert',
      textField: 'Convert',
      icon: <CollectionOutlined />
    },
    delete: {
      valueField: 'delete',
      textField: 'Delete',
      icon: <TrashOutlineIcon />,
      isIconStroked: true
    },
    editShareGroup: {
      valueField: 'editShareGroup',
      textField: 'Edit Share Group',
      icon: <InviteIcon />
    },
    leave: {
      valueField: 'leave',
      textField: 'Leave',
      icon: <LeaveIcon />
    },
  
  };
  let possibleState = [
    {
      title: 'Private',
      value: 1,
    },
    {
      title: 'Shareable',
      value: 2,
    },
    {
      title: 'Public',
      value: 3,
    },
  ];
  const { list, onOptionClick } = dropdownOptions || {};
  const user = useSelector((state: ReduxStateType) => state.user);
  const dropdownList: dropdownType[] = [];
  if (list?.length) {
    list.forEach((item) => {
      if (item === 'share' && visibilityStatus !== 2 && visibilityStatus !== 3)
        return;
      dropdownList.push(optionsMapper[item]);
    });
  }
  const [copied, setCopied] = useState<{
    direction: 'left' | 'right'
  }>(null);
  const shareUrl = returnShareLink(
    'collection',
    slug,
    userName,
    false,
    visibilityStatus,
    private_key
  );

  const handleCopy = (direction: typeof copied['direction']) => {
    // @ts-ignore
    handleShare(shareUrl, 'show', () => {
      setCopied({ direction });
      setTimeout(() => setCopied(null), 1500);
      navigator.clipboard.writeText(encodeURI(shareUrl || ''));
    });
  };

  
  const visibilityState = useMemo(() => possibleState.find((option) => option.value === visibilityStatus)?.value, [visibilityStatus])
  return (
    <div className="collection-card-wrapper__footer">
      <div className="collection-card-wrapper__footer__scraps-count">
        <span className="collection-card-wrapper__footer__count-holder">
          <span>{scrapCount}</span>
        </span>
      </div>
      
      <div className="collection-card-wrapper__footer__icon-right">
        <div className='collection-card-wrapper__footer__date-comments-wrapper'>
          <span className="collection-card-wrapper__footer__icons">
            <span className="collection-card-wrapper__footer__share-wrapper">
              <StateLabel state={visibilityState} onClick={visibilityState === 2 ? handleCopy.bind(null, 'left') : null} />
            </span>
          </span>
          {(updatedAt && returnTimeDifference(createdAt, updatedAt) > 15) ? (
            <span className="collection-card-wrapper__footer__time">
              <p
                className="collection-header__head-last-updated__meta-time collection-header__head-last-updated__meta-time__updated_at"
                title={showFullDate(updatedAt, user.timezone)}
              >
                Edited {formatTimestampDate(updatedAt, user.timezone)}
              </p>
            </span>
          ) : null}
        </div>

        <div className="collection-card-wrapper__footer__settings-holder">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onLikeClick();
            }}
            className={`collection-card-wrapper__footer__like${isLiked ? ' collection-card-wrapper__footer__like--active' : ''}${user.user_id ? '' : ' collection-card-wrapper__footer__like--disabled'}`}
          >
            <HeartIcon isFilled={isLiked} /> {reactionsCount > 0 ? reactionsCount : ''}
          </button>
          {onCommentsClick && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onCommentsClick();
              }}
              className="collection-card-wrapper__footer__comments"
            >
              <ReplyIcon />{commentsCount ? `${commentsCount}` : ''}
            </button>
          )}
          {list?.includes('organize') && (
            <span
              className="collection-card-wrapper__footer__organize"
              onClick={(e) => {
                e.stopPropagation();
                onOptionClick(optionsMapper.organize.valueField);
              }}
            >
              {optionsMapper.organize.icon}
              <span className="collection-card-wrapper__footer__organize-text">
                Organize
              </span>
            </span>
          )}
          
          {dropdownList.length ? (
            <MyDropDown<dropdownType>
              dropDownList={dropdownList}
              customButton={{
                customRender: (showDropDownListHandler) => {
                  return (
                    <TripleDot
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => {
                        e.stopPropagation();
                        showDropDownListHandler();
                      }}
                    />
                  );
                },
              }}
            >
              {(item, onClicked) => {
                if (item.valueField === 'organize') {
                  return null;
                } else
                  return (
                    <div
                      key={item.valueField}
                      className={`dropdown__item${item.className ? ' ' + item.className : ''}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        item.valueField === 'share' && handleCopy('right');
                        onOptionClick(item.valueField);
                        onClicked && onClicked();
                      }}
                    >
                      <span
                        className={`dropdown__item-icon${
                          item.isIconStroked
                            ? ' dropdown__item-icon--stroked'
                            : ''
                        }`}
                      >
                        {item?.icon}
                      </span>
                      <span className="dropdown__item-label">
                        {item?.textField}
                      </span>
                    </div>
                  );
              }}
            </MyDropDown>
          ) : null}
          {copied && (
            <div className={`collection-card-wrapper__footer__copied collection-card-wrapper__footer__copied--${copied.direction}`}>
              <Icon iconClass="check-square" size="big" />
              Link copied to clipboard
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewCollectionCardFooter;
