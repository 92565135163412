import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['collection_id', 'Int!'],
  ['username', 'String'],
  ['private_key', 'String'],
  ['tags', '[TagSlugInput]'],
  ['untagged', 'Boolean'],
  ['first', 'Int'],
  ['page', 'Int'],
  ['sort_param', 'String'],
  ['sort_by', 'String'],
]);

export default function COLLECTION_SCRAPS(dataQueryString = '', paginatorInfoString = '') {
  return gql`
  query CollectionScraps(${parametersTypeString}){
    collectionScraps(${parametersAssignString}){
      ${defaultResponse}
      ${paginatorInfoString ? `
        paginatorInfo {
            ${paginatorInfoString}
        }
      ` : ''}
      ${dataQueryString ? `
        data {
            ${dataQueryString}
        }
      ` : ''}
      scrap_user_data {
        user_id
        user_name
        display_name
        avatar
      }
      collection_scraps_comments {
        scrap_id
        comments {
          id
          user_id
          text
          created_at
          updated_at
          reaction_count
        }
        commenting_users {
          user_id
          user_name
          display_name
          avatar
        } 
      }
      reaction_comment_counts {
        scrap_id
        reaction_count
        comment_count
      }
    }
  } 
  `;
}


const dataQueryString = `
    id
    user_id
    title
    desc
    status
    state
    private_key
    service
    meta
    meta_title
    meta_desc
    price
    created_at
    updated_at
    scrap_created_at
    url
    domain
    favicon
    original_favicon
    annotations {
        highlighted_text
    }
    annotations_count
    documents_count
    documents {
        file_name
        file_type
        file_path
    }
    audios_count
    audios{
        file_name
        file_path
    }
    videos_count
    videos{
        file_name
        file_path
    }
    images_count
    images {
        file_name
        file_path
        order
        file_status
        file_config
        desc
    }
    tags {
        slug
    }
    collections{
        id
    }
    order
    areFilesExpanded @client
    isUgcExpanded @client
    isMetaExpanded @client
    areAnnotationsExpanded @client
    isAnnotationExpanded @client
    isTagsExpanded @client
    isCollectionsExpanded @client
`
export const COLLECTION_SCRAPS_FOR_COLLECTION_PAGE = COLLECTION_SCRAPS(dataQueryString, 'total')
