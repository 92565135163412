import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import Scrap from "../../../containers/data/scrap";
import { getScrapTags } from "../helpers/getScrapTags";
import { getScrapImageTags } from "../helpers/getScrapImageTags";

const AIScrappTagging = ({ scrapId, username, privateKey }) => {
  const [scrapData, setScrapData] = useState(null);
  const [imageData, setImageData] = useState(null)
  const [loadingTags, setLoadingTags] = useState(false);
  const { data, loading } = useQuery(Scrap, {
    variables: {
      scrap_id: scrapId,
      username,
      private_key: privateKey,
    },
  });

  useEffect(() => {
    if (data) {
      setLoadingTags(true);
      setScrapData(null);
      getScrapTags(data.scrap.data).then((tags) => {
        setScrapData(tags.data);
      }).catch((err) => {
        console.log('There was an error loading tags for this Scrap');
      }).finally(() => {
        setLoadingTags(false);
      })

      const hasImage = data.scrap.data.images[0]?.file_path;
      if(hasImage) {
        getScrapImageTags(hasImage).then((tags) => {
          setImageData(tags.data);
        }).catch((err) => {
          console.log('There was an error loading tags for this Scrap');
        })
      }
    }
  }, [data]);

  if (loading) return <div>Loading Scrap data...</div>

  if(loadingTags) return <div>Analyzing Scrap content and image...</div>;

  return (
    <div>
      <h2>Content tagging</h2>
      <p>
        {JSON.stringify(scrapData)}
      </p>

      <h2>Image tagging</h2>
      <p>
        {JSON.stringify(imageData)}
      </p>
    </div>
  );
};

export default AIScrappTagging;