import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['id', 'ID'],
  ['text', 'String'],
  ['roles', '[Int] = [1]'],
  ['first', 'Int'],
  ['search_after', 'String'],
  ['tie_breaker_id', 'String'],
  ['tie_breaker_param', 'String'],
  ['sort_param', 'String'],
  ['sort_by', 'String'],
  ['collaboration','Boolean = false']
]);

export default function COLLECTIONS(dataQueryString = '', showScrapsCount = false, paginatorInfoString = '') {
  return gql`
    query Collections(${parametersTypeString}) {
        collections(${parametersAssignString}) {
            ${defaultResponse}
            ${dataQueryString ? `
                data {
                    ${dataQueryString}
                }
            ` : ''}
            ${showScrapsCount ? `
                scrapsCount {
                    id
                    scraps_count
                }
            ` : ''}
            ${paginatorInfoString ? `
                paginatorInfo {
                    ${paginatorInfoString}
                }
            ` : ''}


        }
    }
  `;
}
const dataQueryString = `
        id
        user_id
        slug
        title
        desc
        state
        user_name
        display_name
        private_key
        sort_by
        sort_param
        view_type
        updated_at
        created_at
        used_at
        avatar
        cover_image
        show_author
        reaction_count
        comment_count
        members {
          user_id
          display_name
          avatar
          user_name
        }
      `
export const COLLECTIONS_FOR_SIDE_NAV = COLLECTIONS(
  dataQueryString,
  true,
  'total'
);
