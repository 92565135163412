import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const DoorIcon = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29775 0.133981C8.60525 -0.374668 10 0.628036 10 2.07667V13.1322V17.9242C10 19.274 8.77722 20.2644 7.51493 19.937L1.51493 18.3809C0.624596 18.15 0 17.3201 0 16.3681V4.41082C0 3.54595 0.517145 2.7718 1.29775 2.46812L7.29775 0.133981ZM8 2.07667L2 4.41082L2 16.3681L8 17.9242V13.1322V2.07667ZM4.99999 9.91628C4.99999 9.34334 5.44771 8.87889 5.99999 8.87889C6.55228 8.87889 6.99999 9.34334 6.99999 9.91628C6.99999 10.4892 6.55228 10.9537 5.99999 10.9537C5.44771 10.9537 4.99999 10.4892 4.99999 9.91628ZM12 1.6171C11.4477 1.6171 11 2.08156 11 2.6545C11 3.22744 11.4477 3.6919 12 3.6919H14V12.4176V16.1407H12C11.4477 16.1407 11 16.6051 11 17.1781C11 17.751 11.4477 18.2155 12 18.2155H14C15.1046 18.2155 16 17.2865 16 16.1407V12.4176V3.6919C16 2.54602 15.1046 1.6171 14 1.6171H12Z"
        fill="#3C3C3C"
      />
    </svg>
  );
};

export default DoorIcon;
