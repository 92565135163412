import React from 'react';
import '../OnboardingStyles.css';

interface Proptypes {
  hasScraps?: boolean;
}

const OnboardingInstruction = ( { hasScraps } : Proptypes) => {
  let keySymbol = '';
  let keyName = '';

  // platform is deprecated, userAgentData is the new way to get the platform
  // @ts-ignore
  const platform = navigator.platform || navigator.userAgentData.platform || ''
  if (platform.indexOf('Win') !== -1 || platform.indexOf('Linux') !== -1) {
    keySymbol = 'Ctrl';
    keyName = 'Control';
  } else if (platform.indexOf('Mac') !== -1) {
    keySymbol = '⌘';
    keyName = 'Command';
  }

  return (
    <div>
      <div className={`scrap-card onboard-info ${hasScraps ? 'has-scraps' : ''}`} >
        <div className="onboard-info__message">Use</div>
        <div className="onboard-info__trigger">
          <span className="onboard-key__modifier">
            <span className="onboard-key__modifier--symbol">{keySymbol}</span>
            <span className="onboard-key__modifier--name">{keyName}</span>
          </span>
          <span className="onboard-key__add">+</span>
          <span className="onboard-key__character">V</span>
        </div>
        <div className="onboard-info__action">to create a scrap.</div>
      </div>
    </div>
  );
};

export default OnboardingInstruction;