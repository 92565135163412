import React from 'react';
import NewCollectionHeader from './UiComponents/NewCollectionHeader';
import NewCollectionCardMedia from './UiComponents/NewCollectionMedia';
import NewCollectionDescription from './UiComponents/NewCollectionDescription';
import NewCollectionCardFooter, {
  collectionCardFooterOptionsType,
} from './UiComponents/NewCollectionFooter';
import './styles.css';
import NewCollectionCollaborators from './UiComponents/NewCollectionCollaborators';
import { collectionType } from '../../types/collections';
import { getResponseMessages, parseNote, returnAllGroupMembersExceptMe } from '../../helpers';
import { GroupType } from '../../containers/GroupShareContainer/types';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMember } from '../../redux/action/utils';
import SearchResultCountContainer from '../Global/SearchResultCount';
import { setDrawer } from '../../redux/action/drawerAction';
import { useMutation } from '@apollo/client';
import UPSERT_BOOKMARK from '../../containers/data/upsertBookmark';
import { ReduxStateType } from '../../redux/store';
import { sendToast } from '../../helpers/notification';
import { parseTryCatchError } from '../../helpers/parseTryCatchError';
import ADD_REACTION from '../../containers/data/addReaction';
import REMOVE_REACTION from '../../containers/data/removeReaction';
import { dislikeGroup, likeGroup } from '../../redux/action/groupsLikesAndCommentsActions';
import { dislikeCollection, likeCollection } from '../../redux/action/collectionsLikesAndCommentsActions';

interface Proptypes {
  isBookmarked?: boolean;
  fromString?: string;
  loadMeta?: boolean;
  data: GroupType | collectionType;
  footerDropdownOptions?: collectionCardFooterOptionsType;
  scrapCount?: number;
  type?: 'collection' | 'group';
  onCardClick?: () => void;
  view?: 'all-shares' | 'group-page-details';
  cardLayout?: 'enlarged' | 'compact';
  onMatchedScrapsClick?: () => void;
  onLikeClicked?: (isLiked: boolean) => void;
  onBookmarkToggled?: (action: 'save' | 'unsave') => void;
}

export default function NewCollectionCard({
  isBookmarked,
  onBookmarkToggled,
  fromString,
  data,
  footerDropdownOptions,
  scrapCount,
  type,
  onCardClick,
  view,
  cardLayout,
  loadMeta,
  onMatchedScrapsClick,
  onLikeClicked,
}: Proptypes) {
  const likeAction = type === 'group' ? likeGroup : likeCollection;
  const dislikeAction = type === 'group' ? dislikeGroup : dislikeCollection;
  const [addLike] = useMutation(ADD_REACTION());
  const [removeLike] = useMutation(REMOVE_REACTION());
  const likesAndCommentsData = useSelector((state: ReduxStateType) =>
    state[type === 'group' ? 'groupsLikesAndComments' : 'collectionsLikesAndComments'].get(+data.id)
  );
  const user = useSelector((state: ReduxStateType) => state.user);
  const [upsertBookmark] = useMutation(UPSERT_BOOKMARK())
  const dispatch = useDispatch();

  const likeClickHandler = async () => {
    const currentlyLiked = likesAndCommentsData?.isLiked
    try {
      const dispatchAction = currentlyLiked ? dislikeAction : likeAction;
      dispatch(dispatchAction(+data.id))
      onLikeClicked && onLikeClicked(!currentlyLiked)
      const mutationAction = !currentlyLiked ? addLike : removeLike;
      const response = await mutationAction({
        variables: {
          collection_id: data.id,
          reactable_type: 2,
        },
      });
      const { isSuccess, error } = getResponseMessages(
        response.data[currentlyLiked ? 'removeReaction' : 'addReaction']
      );
      if(!isSuccess) {
        throw new Error(error[0])
      }
    } catch (error) {
      sendToast('Failed to react!', 'error');
      const dispatchAction = currentlyLiked ? likeAction : dislikeAction;
      dispatch(dispatchAction(+data.id))
      onLikeClicked && onLikeClicked(currentlyLiked)
    }
  }
  const dropdownOptionClickHandler = async (
    value: collectionCardFooterOptionsType['list'][number]
  ) => {
    if(value === 'bookmark') {
      try {
        const action = isBookmarked ? 'unsave' : 'save';
        
        const response = await upsertBookmark({
          variables: {
            collection_id: data.id,
          }
        })
        const { isSuccess, error } = getResponseMessages(response.data?.upsertBookmark);
        if(!isSuccess) throw new Error(error[0])
        onBookmarkToggled && onBookmarkToggled(action)
        sendToast(action === 'save'? 'Saved Successfully': 'Successfully removed from your saved items', 'success', 2000)
      } catch (error) {
        onBookmarkToggled && onBookmarkToggled(isBookmarked ? 'save' : 'unsave');
        sendToast(parseTryCatchError(error), 'error', 2000);  
      }
      
    } else {
      footerDropdownOptions.onOptionClick(value)
    }
  };
  const render = () => {
    if (type === 'group') {
      const itemData = data as GroupType;
      const {
        id,
        owner_display_name,
        owner_user_name,
        created_at,
        updated_at,
        owner_avatar,
        members_count,
        nickname,
        matched_count,
        scraps_count,
        members,
      } = itemData;
      const membersCount = members_count ?? members?.length + 1;
      const groupMembers = returnAllGroupMembersExceptMe(itemData,user.user_id)
      return (
        <>
          <div className="new-collection-card__wrapper">
            <div className="new-collection-card__wrapper__details">
              {(scraps_count > 0 && matched_count) ? (
                <SearchResultCountContainer
                  count={matched_count}
                  onClick={onMatchedScrapsClick}
                />
              ) : null}
              <NewCollectionHeader
                date={created_at}
                fromString={fromString}
                view={view}
                avatar={owner_avatar}
                display_name={owner_display_name}
                user_name={owner_user_name}
                collaborators={groupMembers}
                type={type}
                maxLine={2}
                count={membersCount > 1 ? membersCount + 1 : 0}
                groupNickName={nickname}
              />
              <div
                className={`new-collection-card__wrapper__collaborators new-collection-card__wrapper__collaborators--show-full`}
              >
                <NewCollectionCollaborators
                  fromString={fromString}
                  collectionId={id}
                  collaborators={groupMembers}
                  onViewMoreClick={() => {
                    dispatch(
                      setShowMember({
                        member: returnAllGroupMembersExceptMe(itemData),
                        clickable: true,
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="new-collection-card__wrapper__footer-section">
            <NewCollectionCardFooter
              isBookmarked={isBookmarked}
              reactionsCount={likesAndCommentsData?.likesCount}
              commentsCount={likesAndCommentsData?.commentsData?.count}
              isLiked={likesAndCommentsData?.isLiked}
              onLikeClick={likeClickHandler}
              userName={owner_user_name}
              scrapCount={scrapCount}
              dropdownOptions={footerDropdownOptions}
              updatedAt={updated_at}
              createdAt={created_at}
              onCommentsClick={() => {
                dispatch(setDrawer({
                  type: 'comments',
                  data: {
                    variables: {
                      commentable_type: 1,
                      collection_id: +id
                    },
                    commentsFor: {
                      type: 'group',
                      id
                    }
                    
                  }
                }))
              }}
            />
          </div>
        </>
      );
    }
    const itemData = data as collectionType;
    const {
      cover_image,
      members,
      title,
      desc,
      id,
      avatar,
      slug,
      private_key,
      user_name,
      display_name,
      state,
      updated_at,
      created_at,
      show_author,
      matched_count,
      scraps_count,
    } = itemData;
    
    const membersCount = members?.length + 1;
    const showAuthor = show_author || membersCount === 1
    return (
      <>
        <div className="new-collection-card__wrapper">
          <div className="new-collection-card__wrapper__details">
            {cover_image && (
              <div className="new-collection-card__wrapper__media">
                <NewCollectionCardMedia image={cover_image} />
              </div>
            )}
            {scraps_count > 0 && matched_count ? (
              <SearchResultCountContainer
                count={matched_count}
                onClick={onMatchedScrapsClick}
              />
            ) : null}
            <NewCollectionHeader
              fromString={fromString}
              avatar={avatar}
              cover_image={cover_image}
              display_name={display_name}
              user_name={user_name}
              collaborators={
                showAuthor ? members : returnAllGroupMembersExceptMe(itemData)
              }
              type={type}
              showOwner={!!showAuthor}
              count={membersCount}
              date={created_at}
            />

            <NewCollectionDescription
              title={title}
              description={parseNote(desc)}
              className={`new-collection-card__wrapper__description ${
                !cover_image
                  ? 'new-collection-card__wrapper__description--show-full'
                  : 'new-collection-card__wrapper__description--show-half'
              }`}
            />
            <div
              className={`new-collection-card__wrapper__collaborators ${
                !cover_image
                  ? 'new-collection-card__wrapper__collaborators--show-full'
                  : 'new-collection-card__wrapper__collaborators--show-half'
              }`}
            >
              <NewCollectionCollaborators
                fromString={fromString}
                collectionId={id}
                collaborators={
                  showAuthor
                    ? members
                    : returnAllGroupMembersExceptMe(itemData)
                }
                onViewMoreClick={() => {
                  dispatch(
                    setShowMember({
                      heading: 'Collection Collaborators',
                      member: returnAllGroupMembersExceptMe(itemData),
                      clickable: true,
                    })
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="new-collection-card__wrapper__footer-section">
          <NewCollectionCardFooter
            isBookmarked={isBookmarked}
            isLiked={likesAndCommentsData?.isLiked}
            onLikeClick={likeClickHandler}
            slug={slug ?? title + '-' + id}
            private_key={private_key}
            userName={user_name}
            visibilityStatus={state}
            scrapCount={scrapCount}
            dropdownOptions={{
              ...footerDropdownOptions,
              list: user.userName
                ? [...(footerDropdownOptions?.list || []), 'bookmark']
                : footerDropdownOptions?.list || [],
              onOptionClick: dropdownOptionClickHandler,
            }}
            updatedAt={updated_at}
            createdAt={created_at}
            onCommentsClick={() => {
              dispatch(
                setDrawer({
                  type: 'comments',
                  data: {
                    variables: {
                      commentable_type: 1,
                      collection_id: +id,
                    },
                    commentsFor: {
                      id,
                      type: 'collection',
                    },
                  },
                })
              );
            }}
            commentsCount={likesAndCommentsData?.commentsData?.count}
            reactionsCount={likesAndCommentsData?.likesCount}
          />
        </div>
      </>
    );
  };
  return (
    <div
      className={`new-collection-card${
        onCardClick ? ' new-collection-card--clickable' : ''
      } new-collection-card--${cardLayout ?? 'enlarged'} new-collection-card--${type || 'collection'}`}
      onClick={onCardClick}
    >
      {render()}
    </div>
  );
}
