import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CloseIcon, LongArrowRight } from '../icons';
import './styles.css';



const OnboardSlider = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [autoSlide, setAutoSlide] = useState(true);
  const { replace } = useHistory();
  const maxSteps = 3;
  const autoSlideTimeOut = 5000;

  const steps = [
    {
      img: 'step-1.png',
      step_img: 'squirrel-1.svg',
      heading: 'Scrap from anywhere.',
      description:
        'Browse and Scrap with the extension or Share to the app to save as you go.',
    },
    {
      img: 'step-2.png',
      step_img: 'squirrel-2.svg',
      heading: 'Invite collaborators',
      description: 'Collect, research and curate together.',
    },
    {
      img: 'step-3__desktop.png',
      step_img: 'squirrel-3.svg',
      img_mobile: 'step-3__mobile.png',
      heading: 'Share with friends...\nor the world',
      description:
        'Share as you Scrap or after. Make Collections public when everyone should see them.',
    },
  ];

  const changeStep = useCallback(
    (step: number) => {
      setCurrentStep(step);
    },
    [currentStep]
  );

  const changeAutoSlide = useCallback(
    (doSlide: boolean) => {
      setAutoSlide(doSlide);
    },
    [autoSlide]
  );


  const handleClose = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e && e.preventDefault();
    changeAutoSlide(false);
    replace({
      pathname: '/',
    });
  };

  const handleNext = (step = -1) => {
    changeAutoSlide(false);
    if (step === -1 && currentStep === maxSteps - 1) {
      handleClose();
    } else {
      changeStep(step === -1 ? currentStep + 1 : step);
    }
  };

  

  useEffect(() => {
    // SET AUTO SLIDE
    const slideNext = setInterval(() => {
      if (autoSlide) {
        if (currentStep < maxSteps - 1) {
          setCurrentStep(currentStep + 1);
        } else {
          changeAutoSlide(false);
          clearTimeout(slideNext);
        }
      } else {
        changeAutoSlide(false);
        clearTimeout(slideNext);
      }
    }, autoSlideTimeOut);

    if (!autoSlide) {
      clearTimeout(slideNext);
    }

    return () => clearTimeout(slideNext);
  }, [autoSlide, currentStep]);

  return (
    <div className="onboard__wrapper">
      <Link to="/" className="onboard__close" onClick={(e) => handleClose(e)}>
        <CloseIcon />
      </Link>
      <div className="onboard__slide-wrapper">
        <div className="onboard__image-wrapper">
          <span className="onboard__slide-image-wrapper">
            {steps[currentStep].img_mobile && (
              <img
                src={`/dashboard/img/${steps[currentStep].img_mobile}`}
                alt={`onboard_slide_mobile${currentStep}`}
                className="onboard__slide-mobile"
                key={`slide-mobile${currentStep}`}
              />
            )}
            <img
              src={`/dashboard/img/${steps[currentStep].img}`}
              alt={`onboard_slide${currentStep}`}
              className="onboard__slide-desktop"
              key={`slide-desktop${currentStep}`}
            />
          </span>
        </div>

        <div className="onboard__content-wrapper">
          <div className="onboard__content-dynamic-wrapper">
            <div className="onboard__content-dynamic">
              <img
                src={`/dashboard/img/${steps[currentStep].step_img}`}
                alt={`onboard_step${currentStep}`}
                className="onboard__step-icon"
              />
              <h2>{steps[currentStep].heading}</h2>
              <p>{steps[currentStep].description}</p>
            </div>
            <div className="onboard__navigation">
              {Array.from(Array(maxSteps).keys()).map((step) => (
                <span
                  className={`onboard__step${
                    currentStep === step ? ' onboard__step-active' : ''
                  }`}
                  key={`step${step}`}
                  title={`Step ${step + 1}`}
                  onClick={() => {
                    handleNext(step);
                  }}
                >
                  &nbsp;
                </span>
              ))}
              <span className="onboard__step-next" onClick={() => handleNext()}>
                Next <LongArrowRight />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(OnboardSlider);
