import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import EmptyClipboard from './EmptyClipboard';
import { OnboardingPopStyles, OnboardingPopBaseStepsStyles } from "../OnboardingHelpers";

interface OnboardingProps {
  run?: boolean;
}

const OnboardingNewScrap = ({ run }: OnboardingProps) => {
  const [runState] = useState(run || false);

  useEffect(() => {
    // rerun the component
  }, [run]);

  const stepsValue = {
    run: runState,
    steps: [
      {
        index: 0,
        target: '.onboard-scrap',
        title: 'And just like that you have your first scrap',
        content: 'Copy on dashboard anything and everything worth saving.',
        disableBeacon: true,
        placement: 'right',
        isFixed: true,
        styles: OnboardingPopBaseStepsStyles,
        locale: {
          close: 'Next',
        }
      },
      {
        index: 1,
        target: '.onboard-save',
        title: 'Saved scraps',
        content: 'Click on Saved, to navigate to the list of Saves. We have saved some Scraps for you to check out.',
        disableBeacon: true,
        placement: 'right',
        isFixed: true,
        styles: OnboardingPopBaseStepsStyles,
        locale: {
          close: 'Skip',
        },
        spotlightClicks: true,
      },
    ],
  };

  return (
    <div>
      {/* there is some issue in the libraries types with certain properties,
          so we need to ignore the typescript error */}
      <Joyride
        // @ts-ignore
        steps={stepsValue.steps}
        run={runState}
        disableCloseOnEsck
        disableOverlayClose
        hideCloseButton
        hideBackButton
        locale={{
          close: 'Close',
          last: 'End tour',
        }}
        styles={OnboardingPopStyles}
      />
    </div>
  );
};

export default OnboardingNewScrap;