import { GroupType } from '../../containers/GroupShareContainer/types';
import { collectionType } from '../../types/collections';
import { entryTypes } from '../../types/feed';
import { scrapType } from '../../types/scrapType';
import { utilsTypes } from '../../types/utils';

export interface actionTypes {
  expanded: boolean;
  scrollToBottom?: boolean;
  hide: boolean;
  type: string;
  organizeCollection: boolean;
  reloadSideNavFilters: boolean;
  reloadSideNavCollections: boolean;
  reloadSideNavShares: boolean;
  savedScrap?: string | entryTypes;
  backUrl?: string;
  hideHistory?: boolean;
  isMainPageLoading?: boolean;
  onSaved?: () => void;
  scrapPopup?: {
    type: 'uid' | 'scrapData' | 'id';
    data: string | entryTypes;
    readOnly?: boolean;
    fromUrl?: boolean;
    defaultEdit?: boolean;
    reloadBackgroundOnClose?: boolean;
    headerTitle?:string;
    scrapType?:string;
  }
  scrapImagesPopup?: {
    isReadOnly?: boolean;
    onDone?: (images_meta: string) => void;
    scrap?: Partial<scrapType>;
    scrapDetails?: {
      scrap_id: number;
      images_Count: number;
      username: string;
      private_key: string;
    };
    isGridMode?: boolean;
    defaultEdit?: boolean;
    carouselData?: {
      index: number;
    };
  };
  scrapEmbedPopup?: {
    data: {
      meta?: string;
      service?: string;
      thumbnail?: string;
      video?: string;
    };
    modalClassName?: string;
  };
  imageDisplayPopup?: {
    imageUrl: string;
    modalClassName?: string;
  };
  warningPopup?: {
    className?: string;
    warningMessage?: string;
    header?: string;
    leftLoader?: boolean;
    cancel?: {
      label?: string;
      hide?: boolean;
      cb?: () => void;
    };
    submit?: {
      label?: string;
      cb?: () => Promise<void>;
    };
    processingLabel?: string;
  };
  newScrapPopup?: {
    type: 'Note' | 'Images' | 'File';
  };
  deleteScrapPopup?: {
    scrap: entryTypes | scrapType;
    onComplete?: (scrap: entryTypes | scrapType) => void;
  };
  showInvitationPopup?: {
    onComplete?: () => void;
    prefilledData?: number | string;
  };
  collectionSettingsPopup?: {
    collection: collectionType & GroupType;
    type?: 'group-convert' | 'collection';
  };
  collaborationPopup?: {
    collectionId?: string | number;
    onSaved: () => void;
  };
  collectionAction?: {
    collection: Partial<collectionType>;
    type: 'edit' | 'delete';
  };
  platform?: 'ios-mobile' | 'android-mobile';
  dimensions?: {
    width: number;
    height: number;
  };
  imageCarouselPopup?: {
    image: string[];
    scrap_id: number;
    images_count: number;
    private_key: string;
    username: string;
  };
  groupSharePopup?: {
    scrap_id: number;
    shareUrl: string;
  };
  showMemberPopup?: {
    member: Array<{
      name?: string[];
      id?: Number;
      user_name?: string;
      avatar?: string;
      creator_initials?: string;
      type?: string;
    }>;
    clickable?: boolean;
  };
}

const defaultState = {
  expanded: true,
  hideFilter: false,
  organizeCollectionMode: false,
  reloadSideNavFilters: false,
  reloadSideNavCollections: false,
  reloadSideNavShares: false,
  backUrl: null,
  hideHistory: true,
  dimensions: {
    width: null,
    height: null,
  },
};
const utils = (state: utilsTypes = defaultState, action: actionTypes) => {
  switch (action.type) {
    case 'TOGGLE_NAVIGATION':
      return { ...state, expanded: action.expanded };
    case 'TOGGLE_ORGANIZE_COLLECTION':
      return { ...state, organizeCollectionMode: action.organizeCollection };

    case 'UPDATE_FILTER_STATE':
      return { ...state, hideFilter: action.hide };
    case 'SET_RELOAD_SIDE_NAV_FILTERS':
      return { ...state, reloadSideNavFilters: action.reloadSideNavFilters };
    case 'SET_RELOAD_SIDE_NAV_COLLECTIONS':
      return {
        ...state,
        reloadSideNavCollections: action.reloadSideNavCollections,
      };
    case 'SET_RELOAD_SIDE_NAV_SHARES':
      return {
        ...state,
        reloadSideNavShares: action.reloadSideNavShares,
      };
    case 'SET_SAVED_SCRAP':
      return { ...state, savedScrap: action.savedScrap };
    case 'SET_BACK_URL':
      return {
        ...state,
        backUrl: action.backUrl,
        scrollToBottom: action.scrollToBottom,
      };
    case 'SET_SCRAP_POPUP':
      return { ...state, scrapPopup: action.scrapPopup };
    case 'SET_SCRAP_EMBED_POPUP':
      return { ...state, scrapEmbedPopup: action.scrapEmbedPopup };
    case 'SET_IMAGE_DISPLAY_POPUP':
      return { ...state, imageDisplayPopup: action.imageDisplayPopup };
    case 'SET_DELETE_SCRAP_POPUP':
      return { ...state, deleteScrapPopup: action.deleteScrapPopup };
    case 'SET_INVITATION_POPUP':
      return { ...state, showInvitationPopup: action.showInvitationPopup };
    case 'SET_WARNING_POPUP':
      return { ...state, warningPopup: action.warningPopup };
    case 'SET_HIDE_HISTORY':
      return { ...state, hideHistory: action.hideHistory };
    case 'SET_SCRAPS_LOADING':
      return { ...state, isMainPageLoading: action.isMainPageLoading };
    case 'SET_COLLECTION_SETTINGS_POPUP':
      return {
        ...state,
        collectionSettingsPopup: action.collectionSettingsPopup,
        scrollToBottom: action.scrollToBottom,
        onSaved: action.onSaved,
      };
    case 'SET_SCRAP_IMAGES_POPUP':
      return { ...state, scrapImagesPopup: action.scrapImagesPopup };
    case 'SET_COLLABORATION_POPUP':
      return { ...state, collaborationPopup: action.collaborationPopup };
    case 'SET_COLLECTION_ACTION':
      return { ...state, collectionAction: action.collectionAction };
    case 'SET_PLATFORM':
      return { ...state, platform: action.platform };
    case 'SET_DIMENSIONS':
      return { ...state, dimensions: action.dimensions };
    case 'SET_NEW_SCRAP_POPUP':
      return { ...state, newScrapPopup: action.newScrapPopup };
    case 'SET_IMAGE_CAROUSEL_POPUP':
      return { ...state, imageCarouselPopup: action.imageCarouselPopup };
    case 'SET_GROUP_SHARE_POPUP':
      return { ...state, groupSharePopup: action.groupSharePopup };
    case 'SET_SHOW_MEMBER_POPUP':
      return { ...state, showMemberPopup: action.showMemberPopup };
    default:
      return state;
  }
};

export default utils;
