import axios from 'axios';
import { getToken } from '../../../../apollo/getToken';
import * as config from '../../../../settings.json';
import { scrapType } from '../../../../types/scrapType';

interface scrapData {
  createScrap: {
    data: {
      uid: string;
      name: string;
      type: string;
      id: string;
      config?: string;
    };
    messages: {
      success: Array<String>;
      errors: Array<string>;
    };
  };
}

export const getScrapData = async (
  url: string,
  scrapId?: scrapType['id'],
  dontCreate = false
) => {
  // make an axios call to make post request rest api
  // get the value of token from cookie key '_token_'
  // this uses the scrapping worker to get the data
  const token = getToken();
  // move the url to configuration after initial testing
  return await axios.post<{
    createScrap?: { data: object };
    data: scrapData;
  }>(
    `${config.workerURL}/?${scrapId ? `id=${scrapId}&` : ''}${dontCreate ? '' : 'create=true&'}url=${url}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
};
