import React, { memo, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import './styles.css';
import { entryTypes } from '../../types/feed';
import {
  myScrapsPageViewSettingsType,
  ScrapLayoutSettingsType,
} from '../../types/viewSettings';
import FilterSection from '../Filters/FilterSection';
import { useSelector } from 'react-redux';
import { BatchEditType } from '../../types/batchEdit';
import EmptyPageMessage from '../Global/EmptyPageMessage';
import { useLocation } from 'react-router-dom';
import NewBackToTop from '../Global/NewBackToTopButton';
import { ReduxStateType } from '../../redux/store';
import { scrapType } from '../../types/scrapType';
import ItemsMasonry from '../ItemsMasonry';
import Onboarding from '../Onboarding';
import { getScrapData } from '../CreateScrap/components/CreateWebPage/getScrapData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const faSpinnerIcon = faSpinner as IconProp;

interface Proptypes {
  backUrl?: string;
  scraps: Array<entryTypes | scrapType>;
  children: (scrap: entryTypes | scrapType, i?: number) => React.ReactElement;
  onLoadMore: () => void;
  processing: boolean;
  masonryKey?: string;
  uncategorized?: boolean;
  isCompact?: boolean;
  renderMyScrapControls?: () => React.ReactElement;
  viewSettings: myScrapsPageViewSettingsType;
  scrapSize: ScrapLayoutSettingsType['size'];
  viewSettingsLoading?: boolean;
  hideSkeletion?: boolean;
  reloadScraps?: () => void;
}

const MyScraps = ({
  backUrl,
  scraps,
  uncategorized,
  children,
  onLoadMore,
  processing,
  masonryKey,
  isCompact,
  renderMyScrapControls,
  viewSettings,
  scrapSize,
  viewSettingsLoading,
  hideSkeletion,
  reloadScraps,
}: Proptypes) => {
  const ref = useRef<HTMLDivElement>();
  const [ output, setOutput ] = useState('');
  const [ runOnboarding, setRunOnboarding ] = useState(true);
  const [savingScrap, setSavingScrap] = useState(false);

  const user = useSelector((state: ReduxStateType) => state.user);
  const { mode: batchEditMode }: BatchEditType = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.batchEdit
  );
  const { search } = useLocation();

  /**
   * Set an event listener to read the paste event from
   */
  useEffect(() => {
    const handlePaste = async (e: ClipboardEvent) => {
      // only allow pasting when the content is different from the previous one
      if (e.clipboardData.getData('text') === output) return;

      const pastedData = e.clipboardData.getData('text');
      setSavingScrap(true);

      await getScrapData(pastedData);
      // TODO: handle the error state, when the scrap creation fails
      reloadScraps();
      setSavingScrap(false);
    };
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [output, runOnboarding, scraps]);

  let el;
  if (processing && scraps.length === 0) {
    if (hideSkeletion) el = null;
    else el = null;
  } else if (!processing && scraps.length === 0 && !search)
    el = (
      <div>
        <Onboarding type={'instruction'} />

        {savingScrap && (
          <span className="saving-scrap">
            <FontAwesomeIcon icon={faSpinnerIcon} spin size="1x" /> Saving
          </span>
        )}

        <Onboarding type={'welcome'} runOnboarding={runOnboarding} />
      </div>
      // <EmptyPageMessage
      //   header={{}}
      //   titleHeading="How to Start Scrapping"
      //   type={uncategorized ? 'empty' : 'new'}
      //   register_from={user.register_from}
      // />
    );
  else if (!processing && scraps.length === 0 && search.length > 0) {
    el = (
      <EmptyPageMessage header={{}} register_from={user.register_from}>
        <span>
          Looks like there are no results that match the applied filters.
        </span>
      </EmptyPageMessage>
    );
  } else if (scraps.length)
    el = (
      <ItemsMasonry<entryTypes | scrapType>
        containerRef={ref}
        key={masonryKey}
        items={scraps}
        onLoadMore={onLoadMore}
        loading={processing}
        overscanBy={2}
        columnWidth={350}
        columnGutter={18}
      >
        {children}
      </ItemsMasonry>
    );
  if (viewSettingsLoading) el = null;

  return (
    <div
      ref={ref}
      className={`my-scraps${
        viewSettings.viewLayout === 'Story' ? ' my-scraps--story-view' : ''
      } ${batchEditMode ? 'batch-edit--my-scraps' : ''}`}
    >
      <div className={`filters-section__sticky-wrapper`}>
        {renderMyScrapControls && (
          <div className="my-scraps__controls">{renderMyScrapControls()}</div>
        )}
        <FilterSection
          processing={processing && scraps.length === 0}
          overriddenSearch={backUrl}
          isOneScrap
        />
      </div>
      <div
        
        className={`my-scraps__masonry${
          viewSettings.viewLayout === 'Story'
            ? ' my-scraps__masonry--story-view'
            : ''
        }${
          viewSettings.viewLayout === 'Story' && scrapSize === 'Large'
            ? ' my-scraps__masonry--story-large-scraps'
            : ''
        }`}
      >
        {el}
      </div>
      <NewBackToTop />
    </div>
  );
};

function propsAreEqual(
  prevProps: Readonly<Proptypes>,
  nextProps: Readonly<Proptypes>
) {
  // Check for scraps
  if ((prevProps.scraps || []).length !== (nextProps.scraps || []).length)
    return false;
  if (!isEqual(prevProps.scraps, nextProps.scraps)) return false;

  // Check for back url
  if (prevProps.backUrl !== nextProps.backUrl) return false;

  // Check hide skeleton
  if (prevProps.hideSkeletion !== nextProps.hideSkeletion) return false;

  // Check hide skeleton
  if (prevProps.isCompact !== nextProps.isCompact) return false;

  // Check masonryKey
  if (prevProps.masonryKey !== nextProps.masonryKey) return false;

  // Check processing
  if (prevProps.processing !== nextProps.processing) return false;

  // Check scrapSize
  if (prevProps.scrapSize !== nextProps.scrapSize) return false;

  // Check uncategorized
  if (prevProps.uncategorized !== nextProps.uncategorized) return false;
  
  if (prevProps.children !== nextProps.children) return false;
  
  return true;
}

export default memo(MyScraps, propsAreEqual);
