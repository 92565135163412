import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Apple, EmailIcon, ExclamationSquare, PhoneIcon } from '../icons';
import LabelTextInput from '../LabelTextInput';
import AuthHeaderLayout from '../../Account/components/AuthHeaderLayout';
import { loadUser } from '../../../redux/action/userActions';
import { parseTryCatchError } from '../../../helpers/parseTryCatchError';
import '../../Account/styles.css';
import './styles.css';
import { LOGIN_VIA } from '../../../helpers/config';
import ToolTip from '../ToolTip';
import { EDIT_PROFILE } from '../../../containers/data/editProfile';
const faSpinnerIcon = faSpinner as IconProp;

const SIGNUP_DATA_MUTATION = gql`
  mutation upsertSignupData($display_name: String, $name: String) {
    upsertSignupData(display_name: $display_name, name: $name) {
      messages {
        error
        success
      }
      status
      http_code
    }
  }
`;

const GET_BASE_DATA = gql`
  {
    profile {
      data {
        userName
        display_name
        highlight
        avatar
        userEmail
        phone
        country
        login_via
        onboard_status
        cover_image
        social_media_accounts
        display_name
        country_code
      }
    }
  }
`;

interface ProfileSetupProps {
  userName: string;
}

const ProfileSetup = ({ userName }: ProfileSetupProps) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { collectionCollaboratorToken } = qs.parse(search.substring(1));
  const { data: userData } = useQuery(GET_BASE_DATA);
  const [upsertSignupData] = useMutation(SIGNUP_DATA_MUTATION);
  const [editProfile] = useMutation(EDIT_PROFILE);
  const [isReady, setIsReady] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [username, setUsername] = useState(userName);

  useEffect(() => {
    if (userData && userData.profile) {
      dispatch(loadUser(userData.profile.data));
      // setUsername(userData.profile.data?.userName);
      setDisplayName(userData.profile.data?.display_name ?? '');
      if (userData.profile.data?.onboard_status) {
        setIsReady(true);
      } else {
        window.location.replace('/');
      }
    }
  }, [userData]);

  // Handle Redirection on Skip or Success Submission
  const handleRedirection = () => {
    if (collectionCollaboratorToken) {
      window.location.href = `/accept-collaborator?token=${collectionCollaboratorToken}`;
    } else {
      window.location.href = '/onboard';
    }
  };

  // Check if Form is Valid
  const isFormValid = () => {
    return !isProcessing && username.length > 0;
  };

  // Handle Error
  const handleError = (err) => {
    // IF CONDITION TO CHECK FOR THE VALIDATION ERROR CAUSED DUE TO USERNAME BEING ALREADY TAKEN
    if (
      err &&
      err.graphQLErrors &&
      err.graphQLErrors[0]?.extensions?.validation
    ) {
      const { name, email: errorEmail } =
        err.graphQLErrors[0].extensions.validation || {};

      let message = 'Some error occurred';
      if (Array.isArray(errorEmail)) {
        message = errorEmail[0];
      } else if (Array.isArray(name)) {
        message = name[0];
      }
      setError(message || parseTryCatchError(err));
    } else {
      setError(parseTryCatchError(err));
    }
    setIsProcessing(false);
  };

  // Handle form submission for changing display name and user name changes
  async function onSubmit() {
    setError('');
    if (!isFormValid() || isProcessing) {
      return;
    }
    try {
      setIsProcessing(true);
      const val = await upsertSignupData({
        variables: {
          ...(displayName?.trim().length ? { display_name: displayName.trim() } : {}),
          name: username.trim(),
        },
      })
      const {
        messages: { success: successMessages, error: errorMessages },
      } = val.data.upsertSignupData;
      if (Array.isArray(successMessages) && successMessages.length) {
        await editProfile({
          variables: {onboard_status: 0}
        })
        handleRedirection();
      } else {
        let message = 'Some error occurred!';
        if (Array.isArray(errorMessages) && errorMessages.length) {
          message = errorMessages[0];
        }
        throw new Error(message);
      }
      // upsertSignupData({
      //   variables: {
      //     ...(displayName.length > 0 ? { display_name: displayName } : {}),
      //     name: username,
      //   },
      // })
      //   .then((val) => {
          
      //   })
      //   .catch((err) => {
      //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
      //     const error: any = err;
      //     // IF CONDITION TO CHECK FOR THE VALIDATION ERROR CAUSED DUE TO USERNAME BEING ALREADY TAKEN
      //     if (
      //       error.graphQLErrors &&
      //       error.graphQLErrors[0]?.extensions?.validation?.name
      //     ) {
      //       setError(
      //         error.graphQLErrors[0]?.extensions?.validation?.name[0] ||
      //           parseTryCatchError(err)
      //       );
      //     } else {
      //       setError('Some error has occurred. Please try again later.');
      //     }
      //     setIsProcessing(false);
      //   });
    } catch (err) {
      setIsProcessing(false);
      handleError(err);
    }
  }

  // Return Info Wrapper Text
  const returnInfoWrapper = () => {
    const isSocial = () => {
      return [LOGIN_VIA.google, LOGIN_VIA.facebook, LOGIN_VIA.apple].includes(
        userData.profile.data.login_via
      );
    };
    const returnInfoIcon = () => {
      if (userData.profile.data.login_via === LOGIN_VIA.google) {
        return <img src="/dashboard/img/google.png" alt="Google" />;
      }
      if (userData.profile.data.login_via === LOGIN_VIA.facebook) {
        return <img src="/dashboard/img/facebook.png" alt="Facebook" />;
      }
      if (userData.profile.data.login_via === LOGIN_VIA.apple) {
        return <Apple className='profile_setup--info_wrappper-icon__apple-icon'/>
      }
      if (userData.profile.data.login_via === LOGIN_VIA.phone_number) {
        return <PhoneIcon />;
      }
      return <EmailIcon />;
    };
    const returnInfoText = () => {
      if (userData.profile.data.userEmail) {
        return userData.profile.data.userEmail;
      }
      return `${userData.profile.data.country_code} ${userData.profile.data.phone}`;
    };

    return (
      <div
        className={`profile_setup--info_wrappper${
          isSocial() ? ' profile_setup--info_wrappper-social' : ''
        }`}
      >
        <div className="profile_setup--info_wrappper-icon">
          {returnInfoIcon()}
        </div>
        <div className="profile_setup--info_wrappper-text">
          {returnInfoText()}
        </div>
      </div>
    );
  };

  if (!isReady) return null;
  return (
    <AuthHeaderLayout title="Log In" helmetTitle="Scrappi">
      <>
        {isProcessing && (
          <div className="auth__loading">
            <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
          </div>
        )}
        <div className="otp-message__wrapper">
          <p className="auth__message-otp">
            Please set up your profile in order to see the full functionality of
            Scrappi.
          </p>
        </div>
        <div className="form-group">
          {returnInfoWrapper()}
          <LabelTextInput
            placeholder="Display Name"
            inputType={'text'}
            inputName={'displayName'}
            value={displayName}
            label="Display Name"
            className={`input-field`}
            onChange={(val) => {
              setDisplayName(val);
            }}
            disabled={isProcessing}
            required
            showLabelOnFocus
          />
        </div>
        <div className="form-group mt-10">
          <LabelTextInput
            placeholder="Username"
            inputType={'text'}
            inputName={'username'}
            value={username}
            label="Username"
            className={`input-field`}
            onChange={(val) => {
              if(!val?.includes(' ')) {
                setUsername(val);
              }
              
            }}
            disabled={isProcessing}
            required
            showLabelOnFocus
          />
          <ToolTip>Note that the username cannot be changed later</ToolTip>
        </div>
        <div className="profile_setup-action__wrapper">
          {error && (
            <div className="sl-auth__error otp-error">
              <span className="otp-error__icon">
                <ExclamationSquare />
              </span>
              <span>{error}</span>
            </div>
          )}
          <button
            type="button"
            className="button button__primary sl-auth__register-button"
            disabled={!isFormValid()}
            onClick={onSubmit}
          >
            Continue
          </button>
        </div>
      </>
    </AuthHeaderLayout>
  );
};

ProfileSetup.defaultProps = {
  userName: '',
};

export default ProfileSetup;
