import React from 'react';
import OnboardingWelcome from "./components/OnboardingWelcome";
import OnboardingInstruction from "./components/OnboardingInstruction";
import OnboardingNewScrap from "./components/OnboardingNewScrap";

interface Proptypes {
  type: 'welcome' | 'instruction' | 'newScrap';
  runOnboarding?: boolean;
  hasScraps?: boolean;
}

const Onboarding = ( { type, runOnboarding, hasScraps }: Proptypes ) => {
  switch (type) {
    case 'welcome': {
      return <OnboardingWelcome run={runOnboarding} />;
    }
    case 'instruction': {
      return <OnboardingInstruction hasScraps={hasScraps} />;
    }
    case 'newScrap': {
      return <OnboardingNewScrap run={runOnboarding} />;
    }
    default: {
      return null;
    }
  }
}

export default Onboarding;