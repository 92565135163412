import React, { useEffect, useRef, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import CharacterCount from '@tiptap/extension-character-count';
import Mention from '@tiptap/extension-mention';
import StarterKit, { StarterKitOptions } from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Paragraph from '@tiptap/extension-paragraph';
import { Extension, mergeAttributes } from '@tiptap/core';
import TiptapMenuBar from './MenuBar';
import Highlight from '@tiptap/extension-highlight';
import './styles.css';
import LabelTextInput from '../LabelTextInput';

// import { ReactRenderer } from '@tiptap/react';
// import tippy from 'tippy.js';
// import { MentionList } from './MentionList';
export type tiptapToolsType =
  | 'heading'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'list'
  | 'clear-format'
  | 'emoji-picker'
  | 'link'
  | 'highlight-text'
  | 'strike';
interface propTypes {
  onLoad?: () => void;
  text?: string;
  placeholder?: string;
  editorFor?: string;
  edit?: boolean;
  imageDescriptionIndex?: number;
  setCharLength?: (updatedValue: number) => void;
  onChange?: (content: string) => void;
  onBlur?: (content: string) => void;
  setIsChanging?: (val: boolean) => void;
  toolsToHide?: Array<tiptapToolsType>;
  hideAllTools?: boolean;
  animated?: boolean;
  desccharLength?: number;
  labelledEditor?: boolean;
  label?: string;
  mention?: {
    userName: string;
    key: string;
  }
  autoFocus?: boolean;
  charLimit?: number;
}

const CustomMention = Mention.extend({
  parseHTML() {
    return [
      {
        tag: `a[data-type="${this.name}"]`,
      },
    ];
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'a',
      mergeAttributes({ 'data-type': this.name, 'href': `/${HTMLAttributes['data-id']}` }, this.options.HTMLAttributes, HTMLAttributes),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
      ' ' // Add a space after the anchor element
    ]
  },
});

const TiptapEditor = (props: propTypes) => {
  const {
    onLoad,
    text,
    placeholder,
    editorFor,
    edit,
    imageDescriptionIndex,
    setCharLength,
    onChange,
    onBlur,
    setIsChanging,
    toolsToHide,
    hideAllTools,
    animated,
    desccharLength,
    labelledEditor,
    label,
    mention,
    autoFocus,
    charLimit,
  } = props;

  const [editorFocused, setEditorFocused] = useState(false);
  const [showTools, setShowTools] = useState(
    animated || hideAllTools ? false : true
  );
  const tiptap = useRef();
  const editor = useEditor({
    editable: edit || false,
    extensions: [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (StarterKit as unknown) as Extension<StarterKitOptions, any>,
      Underline,
      Link,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      Highlight.configure({ multicolor: true }),
      CharacterCount.configure({
        limit: charLimit,
        mode: 'textSize',
      }),
      CustomMention.configure({
        HTMLAttributes: {
          class: 'mention',
        },

        // suggestion: {
        //   items: ({ query }) => {
        //     return [
        //       'Lea Thompson',
        //       'Cyndi Lauper',
        //       'Tom Cruise',
        //       'Madonna',
        //       'Jerry Hall',
        //       'Joan Collins',
        //       'Winona Ryder',
        //       'Christina Applegate',
        //       'Alyssa Milano',
        //       'Molly Ringwald',
        //       'Ally Sheedy',
        //       'Debbie Harry',
        //       'Olivia Newton-John',
        //       'Elton John',
        //       'Michael J. Fox',
        //       'Axl Rose',
        //       'Emilio Estevez',
        //       'Ralph Macchio',
        //       'Rob Lowe',
        //       'Jennifer Grey',
        //       'Mickey Rourke',
        //       'John Cusack',
        //       'Matthew Broderick',
        //       'Justine Bateman',
        //       'Lisa Bonet',
        //     ]
        //       .filter((item) =>
        //         item.toLowerCase().startsWith(query.toLowerCase())
        //       )
        //       .slice(0, 5);
        //   },

        //   render: () => {
        //     let reactRenderer;
        //     let popup;

        //     return {
        //       onStart: (props) => {
        //         if (!props.clientRect) {
        //           return;
        //         }

        //         reactRenderer = new ReactRenderer(MentionList, {
        //           props,
        //           editor: props.editor,
        //         });

        //         popup = tippy('body', {
        //           getReferenceClientRect: props.clientRect,
        //           appendTo: () => document.body,
        //           content: reactRenderer.element,
        //           showOnCreate: true,
        //           interactive: true,
        //           trigger: 'manual',
        //           placement: 'bottom-start',
        //         });
        //       },

        //       onUpdate(props) {
        //         reactRenderer.updateProps(props);

        //         if (!props.clientRect) {
        //           return;
        //         }

        //         popup[0].setProps({
        //           getReferenceClientRect: props.clientRect,
        //         });
        //       },

        //       onKeyDown(props) {
        //         if (props.event.key === 'Escape') {
        //           popup[0].hide();

        //           return true;
        //         }

        //         return reactRenderer.ref?.onKeyDown(props);
        //       },

        //       onExit() {
        //         popup[0].destroy();
        //         reactRenderer.destroy();
        //       },
        //     };
        //   },
        // },
      }),
      Paragraph.extend({
        parseHTML() {
          return [{ tag: 'div' }];
        },
        renderHTML({ HTMLAttributes }) {
          return [
            'div',
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            0,
          ];
        },
      }),
    ],
    editorProps: {
      transformPastedHTML: (html) => {
        return html
          .replace(/<h[1-6]/g, '<strong')
          .replace(/<\/h[1-6]/g, '</strong');
      },
    },
    content: text,
    onUpdate({ editor }) {
      // The content has changed.
      const json = editor.getHTML();
      setCharLength && setCharLength(editor.getCharacterCount());
      onChange && onChange(json);
    },
    onFocus({ editor, event }) {
      setEditorFocused(true);
      if (animated && edit) {
        setShowTools(true);
        editor.setOptions({
          editorProps: {
            attributes: {
              class: 'tiptap--highlighted',
            },
          },
        });
      }
    },
    onCreate({ editor }) {
      onLoad && onLoad();
      if (animated) editor.commands.blur();
    },
    onBlur({ editor, event }) {
      setEditorFocused(false);
      setShowTools(false);
      if (onBlur) {
        const json = editor.getHTML();
        setCharLength && setCharLength(editor.getCharacterCount());
        onBlur(json);
      }
    },
  });

  useEffect(() => {
    if (editor != null) {
      editor.setEditable(edit || false);
      if (editor.isEditable) {
        editor.chain().run();
      } else {
        editor.commands.setContent(text);
      }
      setCharLength && setCharLength(editor.getCharacterCount());
    }
  }, [editor, edit]);
  useEffect(() => {
    if (!editor || !mention) return;
    // Commented below line for future which appends the mentioned user name in current content  
    // in editor instead of replacing all the content from editor with mentioned user
    // editor.chain().focus().insertContent(`<a data-type="mention" data-id="${mention?.userName}"></a>`).run();
    
    setTimeout(() => {
      editor.chain().focus().setContent(`<a data-type="mention" data-id="${mention?.userName}"></a>`).run();
      const content = editor.getHTML()
      onChange(content);
      // editor.chain().focus().run()  
    },0)
    
  }, [mention, editor]);

  useEffect(() => {
    if (editor != null && imageDescriptionIndex != null && setIsChanging) {
      if (editor.isEditable) {
        setIsChanging(true);
        editor.commands.setContent(text);
        setIsChanging(false);
        setCharLength && setCharLength(editor.getCharacterCount());
      }
    }
  }, [imageDescriptionIndex]);
  useEffect(() => {
    if (editor) {
      setShowTools(false);
      editor.setOptions({
        editorProps: {
          attributes: {
            class: '',
          },
        },
      });
      editor.chain().blur();
      if(autoFocus) {
        editor.chain().focus()
      }
    }
  }, [editor]);
  const showStickyLabel = editor && !editor.isEmpty
  const isLabelledEditor = () => {
    return labelledEditor && label;
  };
  return (
    <div
      className={`tiptap${editorFocused ? ' tiptap--focused ' : ''}${
        edit ? ' tiptap--editable' : ''
      }${isLabelledEditor() ? ' tiptap--labelled' : ''}${
        edit && showTools ? ' tiptap--tools-visible' : ''
      }${showStickyLabel ? ' tiptap--label-sticky' : ''}`}
    >
      <span
        className={`tiptap--label-text-input__label ${
          editorFocused ? 'label-text-input__label-active' : ''
        }`}
      >
        {label ? label : 'Notes'}
      </span>
      {!hideAllTools && !isLabelledEditor() && (
        <TiptapMenuBar
          editor={editor}
          toolsToHide={toolsToHide}
          visible={edit && showTools}
        />
      )}
      {isLabelledEditor() ? (
        <LabelTextInput
          placeholder="Add a title"
          label={label}
          isFocused={showTools}
          className="labelled-tiptap"
        >
          <EditorContent ref={tiptap} editor={editor} />
        </LabelTextInput>
      ) : (
        <EditorContent ref={tiptap} editor={editor} />
      )}
    </div>
  );
};

export default TiptapEditor;
