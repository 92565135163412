import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import GET_TIMEZONES from '../../../containers/data/getTimezones';
import GET_COUNTRIES from '../../../containers/data/getCountries';

import { useSelector } from 'react-redux';
import { userTypes } from '../../../types/user';
import { charLimits } from '../../../helpers/config';
import Tick from '../../Global/icons/Tick';
import {
  InstagramIcon,
  LinkedInIcon,
  FacebookSocial,
  MinusIcon,
  PinterestIcon,
  TwitterIcon,
  YoutubeIcon,
  DownArrowIcon,
} from '../../Global/icons';
import LabelTextInput from '../../Global/LabelTextInput';
import { onlyNumbers, scrollDivTopBottom } from '../../../helpers';
import { parseTryCatchError } from '../../../helpers/parseTryCatchError';
import { isMobileDevice } from '../../../helpers/handleShare';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../../Global/InvisibleRecaptcha';
import ValidateHuman from '../../../helpers/validators/isHuman';
import { ScrapImagesCropper } from '../../ScrapImagesPopup';
import AddImage from '../../Global/AddImage';
import { AddIcon } from '../../Global/icons';
import { UPLOAD_FILE } from '../../../containers/data/uploadFile';
import { returnImagesCDN } from '../../../helpers/config';
import useOutsideAlerter from '../../../helpers/customHooks/useOutsideAlerter';
import { useHistory } from 'react-router-dom';
import validSocialURL from '../../../helpers/publicProfile';

interface Proptypes {
  onSubmit: (data: {
    first_name?: string;
    last_name?: string;
    email?: string;
    headline?: string;
    phone?: string;
    country?: string;
    timezone?: string;
    bio?: string;
  }) => Promise<string>;
}

const EditPublicProfileForm = (props: Proptypes) => {
  const { onSubmit } = props;

  const userData = useSelector(
    (state: { user: userTypes }) => state.user as userTypes
  );

  const dropDownRef = useRef();
  const dropDownContent = useRef();
  const [firstName, setFirstName] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [lastName, setLastName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [country, setCountry] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const [bio, setBio] = useState(null);
  const [headline, setHeadline] = useState(null);
  const [gotEdited, setGotEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [success, setSuccess] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sendMailStatus, setSendMailStatus] = useState<string>(null);
  const [coverImage, setCoverImage] = useState(userData.cover_image);
  const [tempImage, setTempImage] = useState(userData.cover_image);
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const [file, setFile] = useState<File>(null);
  const [showUploader, setShowUploader] = useState(
    userData.cover_image !== null
  );
  const [showURL, setShowURL] = useState(userData.url?.length > 0);
  const [userURL, setUserURL] = useState('');
  const [showSocial, setShowSocial] = useState(false);
  const [userLocation, setUserLocation] = useState(userData?.location);
  const [cropUrl, setCropUrl] = useState<string>(null);
  const [mimeType, setMimeType] = useState('')
  const [isInvalidURL, setIsInvalidURL] = useState([]);
  const { data: countriesData, loading: countriesLoading } = useQuery(
    GET_COUNTRIES
  );
  const [
    getTimezones,
    { loading: timezonesLoading, data: timezonesData },
  ] = useLazyQuery(GET_TIMEZONES);
  const countriesList = countriesData?.countries?.data || [];
  const timezonesList = timezonesData?.timezones?.data?.timezones || [];

  const [socialMedia, setSocialMedia] = useState<{ [key: string]: string }>({});
  const [socialLinks, setSocialLinks] = useState<{ [key: string]: string }>({});
  const [uploadImage] = useMutation(UPLOAD_FILE);
  const history = useHistory();

  let showToBottom = false;
  let newInvalidURl;
  if (dropDownRef.current && dropDownContent.current) {
    if (
      //@ts-ignore
      dropDownContent?.current?.scrollHeight <
      //@ts-ignore
      dropDownRef?.current?.scrollHeight
    ) {
      showToBottom = true;
    }
  }

  const similarURL = /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}(?:\/[\w\-./?#%&=]*)?$/; // eslint-disable-line

  const validateSocialProfile = (label?: string, val?: string) => {
    if (validSocialURL(label, val)) {
      newInvalidURl = isInvalidURL.filter((item) => item !== label);
      setIsInvalidURL(newInvalidURl);
      setGotEdited(true);
    } else {
      if (!isInvalidURL.includes(label)) {
        setIsInvalidURL([...isInvalidURL, label]);
      }
      setGotEdited(false);
    }
  };
  const setSocialValues = (val, label) => {
    setSocialLinks((prev) => {
      if (val.trim().length) {
        return { ...prev, [label]: val };
      }
      let newObj = { ...prev };
      delete newObj[label];
      return newObj;
    });

    //check if it is similar to url or not
    if (similarURL.test(val)) {
      //check if it is valid social profile url or not
      validateSocialProfile(label, val);
    } else if (val.startsWith('http')) {
      validateSocialProfile(label, val);
    } else {
      newInvalidURl = [...isInvalidURL].filter((item) => item !== label);
      setIsInvalidURL(newInvalidURl);
      setGotEdited(true);
    }
  };
  let social = {
    Instagram: {
      icon: <InstagramIcon />,
      element: (label) =>
        renderInput(
          label,
          null,
          null,
          (val) => setSocialValues(val, label),
          socialLinks[label],
          <InstagramIcon className="InstagramIcon" />
        ),
      label: 'Instagram',
    },
    Pinterest: {
      icon: <PinterestIcon />,
      element: (label) =>
        renderInput(
          label,
          null,
          null,
          (val) => setSocialValues(val, label),
          socialLinks[label],
          <PinterestIcon className="PinterestIcon" />
        ),
      label: 'Pinterest',
    },
    LinkedIn: {
      icon: <LinkedInIcon />,
      element: (label) =>
        renderInput(
          label,
          null,
          null,
          (val) => setSocialValues(val, label),
          socialLinks[label],
          <LinkedInIcon className="LinkedInIcon" />
        ),
      label: 'Linkedin',
    },
    Twitter: {
      icon: <TwitterIcon />,
      element: (label) =>
        renderInput(
          label,
          null,
          null,
          (val) => setSocialValues(val, label),
          socialLinks[label],
          <TwitterIcon className="TwitterIcon" />
        ),
      label: 'Twitter',
    },
    Youtube: {
      icon: <YoutubeIcon />,
      element: (label) =>
        renderInput(
          label,
          null,
          null,
          (val) => setSocialValues(val, label),
          socialLinks[label],
          <YoutubeIcon className="YoutubeIcon" />
        ),
      label: 'Youtube',
    },
    Facebook: {
      icon: <FacebookSocial />,
      element: (label) =>
        renderInput(
          label,
          null,
          null,
          (val) => setSocialValues(val, label),
          socialLinks[label],
          <FacebookSocial className="FacebookIcon" />
        ),
      label: 'Facebook',
    },
  };

  useOutsideAlerter(dropDownRef, () => setShowSocial(false), !showSocial, true);

  function renderInput(
    label:
      | 'Phone Number'
      | 'Email'
      | 'Headline'
      | 'First Name'
      | 'Last Name'
      | 'Username'
      | 'URL'
      | 'Location'
      | 'Display Name'
      | 'Twitter',
    meta: React.ReactElement,
    disabled?: boolean,
    setText?: React.Dispatch<string>,
    defaultVal?: string,
    icon?: React.ReactElement,
    customType?: string
  ) {
    let maxLength = 100;
    let setVal = setText;
    let htmlFor;
    let val = null;
    let type = customType ? customType : 'text';
    if (label === 'Phone Number') {
      maxLength = 20;
      setVal = setPhone;
      val = phone;
      type = 'tel';
      htmlFor = 'phone';
    } else if (label === 'Email') {
      maxLength = null;
      val = email;
      setVal = setEmail;
      htmlFor = 'email';
      type = 'email';
    } else if (label === 'Headline') {
      maxLength = 100;
      val = headline;
      setVal = setHeadline;
      htmlFor = 'headline';
      type = 'text';
    }
    else if (label === 'First Name') {
      val = firstName;
      setVal = setFirstName;
      htmlFor = 'firstName';
    } else if (label === 'Last Name') {
      val = lastName;
      setVal = setLastName;
      htmlFor = 'lastName';
    } else if (label === 'Username') {
      val = '@' + userData.userName || '';
      setVal = () => {};
      htmlFor = 'userName';
    } else if (label === 'URL') {
      val = userURL;
      setVal = setUserURL;
      htmlFor = 'URL';
    } else if (label === 'Location') {
      val = userLocation;
      setVal = setUserLocation;
      htmlFor = 'Location';
    } else if (label === 'Display Name') {
      val = displayName;
      setVal = setDisplayName;
      htmlFor = 'Display Name';
    }

    const getPlaceholder = (label: string) => {
      if (Object.keys(social).includes(label)) {
        return label + ' username/url';
      } else if (label === 'URL') {
        return 'Add ' + label;
      } else return label;
    };
    return (
      <div className="form-group">
        <div className="form-group__input">
          <LabelTextInput
            label={label === 'Twitter' ? 'X' : label}
            disabled={disabled}
            additionalClassName={`profile-input ${
              isInvalidURL?.includes(label) ? 'invalid' : ''
            }`}
            placeholder={getPlaceholder(label)}
            value={val ? val : defaultVal ?? ''}
            showLabelOnFocus={true}
            onChange={(e) => {
              if (label === 'Phone Number')
                setVal(
                  onlyNumbers({
                    value: e,
                    oldValue: val,
                    max: maxLength,
                    compareLength: true,
                  })
                );
              else setVal(e);
            }}
            required={
              [
                'Phone Number',
                'Last Name',
                'Instagram',
                'LinkedIn',
                'Youtube',
                'URL',
                'Twitter',
                'Facebook',
                'Pinterest',
                'Location',
                'Display Name',
              ].includes(label)
                ? false
                : true
            }
            onKeyDown={(e) => {
              if (label === 'Phone Number' && e.key.trim() === '') {
                e.preventDefault();
              }
              //@ts-ignore
              if (e.code === 'Enter') {
                e.preventDefault();
              }
            }}
            inputName={htmlFor}
            inputType={type}
            icon={icon}
            className={icon ? 'social-icon' : ''}
          />
        </div>
      </div>
    );
  }

  function handleChange(fn: Function) {
    fn();
    setSuccess(null);
  }
  const onFileChange = async (imageFile: File) => {
    setCropUrl(URL.createObjectURL(imageFile));
    setMimeType(imageFile.type);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function sendMailHandler() {
    const isHuman = await ValidateHuman(reCaptchaRef.current);
    if (!isHuman) return;
    setSendMailStatus('pending');
    try {
      const message = await onSubmit({ email });
      setSendMailStatus(message);
    } catch (error) {
      if (parseTryCatchError(error) === 'Email Address is already used.') {
        setSendMailStatus(parseTryCatchError(error));
      } else {
        setSendMailStatus('error');
      }
    }
  }

  const handleCancel = () => {
    if (isMobileDevice()) {
      history.push('/profile?my-account=true');
      scrollDivTopBottom('my-account', 'top');
    } else {
      if (history.length > 2) {
        history.goBack();
      } else {
        history.push('/');
      }
    }
  };
  const removeSpace = () => {
    for (let [key, value] of Object.entries(socialLinks)) {
      socialLinks[key] = value.trim();
    }
  };

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);

    const isHuman = await ValidateHuman(reCaptchaRef.current);

    if (timezonesLoading || countriesLoading || !isHuman) return;
    setErrorMessage(null);
    setSuccess(null);

    let cover_image = coverImage;
    if (file) {
      const res = await uploadImage({
        variables: {
          file,
        },
      });
      if (res?.data?.upload) cover_image = res.data.upload;
    }

    removeSpace();

    const data = {
      email,
      phone,
      country,
      timezone,
      bio,
      headline,
    };
    data['cover_image'] = cover_image;
    if (cover_image?.includes(returnImagesCDN())) {
      data['cover_image'] = cover_image?.split(returnImagesCDN())[1];
    }
    data['display_name'] = displayName.trim();
    data['social_media_accounts'] = JSON.stringify(socialLinks);
    data['location'] = userLocation;
    data['url'] = userURL;
    if (data.email === userData.userEmail) delete data.email;
    if ((+data.phone || 0) === (+userData.phone || 0)) delete data.phone;
    if (data.country === userData.country) delete data.country;
    if (data.timezone === userData.timezone) delete data.timezone;
    if (data.bio === userData.bio) delete data.bio;
    if (data.headline === userData.headline) delete data.headline;
    if (
      !data.timezone &&
      Array.isArray(timezonesList) &&
      timezonesList.length > 0
    )
      data.timezone = timezonesList[0];
    try {
      const message = await onSubmit(data);
      setSuccess(message);
      setGotEdited(false);
    } catch (error) {
      setErrorMessage(
        (error as { message: string }).message || 'Something went wrong'
      );
    }
    setLoading(false);
    setTimeout(() => {
      setSuccess(null);
    }, 3000);
  }
  function fetchTimezones(country: string, changeTimezone = false) {
    getTimezones({ variables: { country } });
    if (changeTimezone) setTimezone(null);
  }
  useEffect(() => {
    if (countriesList.length > 0) {
      fetchTimezones(userData.country || countriesList[0].name);
    }
  }, [countriesList, userData.country]);
  useEffect(() => {
    setFirstName(userData.first_name);
    setLastName(userData.last_name);
    setDisplayName(userData.display_name);
    setPhone(userData.phone);
    setEmail(userData.userEmail);
    setCountry(userData.country);
    setTimezone(userData.timezone);
    setBio(userData.bio);
    setHeadline(userData.headline);
    setUserURL(userData.url);
    setShowURL(userData.url?.length > 0);

    setUserLocation(userData?.location);
    if (userData.social_media_accounts) {
      setSocialMedia(JSON.parse(JSON.parse(userData.social_media_accounts)));
      setSocialLinks(JSON.parse(JSON.parse(userData.social_media_accounts)));
    }
    if(!coverImage) setCoverImage(userData.cover_image);
    setUserURL(userData.url);
  }, [userData]);
  useEffect(() => {
    if (
      userData.first_name === firstName &&
      userData.last_name === lastName &&
      +phone === +userData.phone &&
      userData.userEmail === email &&
      userData.country === country &&
      userData.timezone === timezone &&
      userData.bio === bio &&
      userData.headline === headline &&
      userData.url === userURL &&
      userData.display_name === displayName &&
      userData.location === userLocation
    )
      setGotEdited(false);
    else setGotEdited(true);
  }, [
    firstName,
    lastName,
    phone,
    email,
    country,
    timezone,
    bio,
    headline,
    userURL,
    userLocation,
    displayName,
  ]);

  const disableSubmit =
    timezonesLoading ||
    countriesLoading ||
    loading ||
    (bio?.length || 0) > charLimits.userBio ||
    !gotEdited;

  const undoImageHandler = () => {
    setCoverImage(tempImage);
  };
  const cropHandler = (blob: Blob) => {
    const tempFile = new File([blob], 'Cover-image', { type: blob.type });
    setErrorMessage(null);
    setFile(tempFile);
    setCoverImage(URL.createObjectURL(tempFile));
    setTempImage(URL.createObjectURL(tempFile));
    setCropUrl(null);
    if (typeof window !== 'undefined') {
      document.body.style.overflow = '';
    }
  };

  if (cropUrl) {
    return (
      <ScrapImagesCropper
        imageType={mimeType}
        onCancel={setCropUrl.bind(null, null)}
        imageUrl={cropUrl}
        onCrop={cropHandler}
        aspectRatio={478 / 185}
        showOverflow={true}
        movable={isMobileDevice() ? false : true}
        zoomOnTouch={isMobileDevice() ? false : true}
      />
    );
  }

  return (
    <form
      onSubmit={submitHandler}
      encType="multipart/form-data"
      method="post"
      className="profile-form"
    >
      <div className="box-body">
        <InvisibleRecaptcha inputRef={reCaptchaRef} />
        {/* remove comment for showing first and last name*/}
        {/* <div className="form-group-double">
          {renderInput('First Name', null)}
          {renderInput('Last Name', null)}
        </div> */}
        <div className="user-detail__display-name">
          {renderInput('Display Name', null)}
        </div>
        <div className="form-group">{renderInput('Username', null, true)}</div>
        <div className="form-group">{renderInput('Headline', null)}</div>

        {
          //uncomment below code for phone number
        }
        {/* <div className="form-group">
          {renderInput('Phone Number', null)}
          <div className="form-group form-group__span">
            <span className="">
              We'll only use your phone number to help you verify your account.
              Message and data rates may apply.
            </span>
          </div>
        </div> */}

        {
          //uncomment below code for email
        }
        {/* <div className="form-group">
          {+userData?.login_via === 2
            ? renderInput('Email', null, true)
            : renderInput('Email', null)}

          <div className="form-group form-group__span">
            <span className="">
              Your email is not visible to any other Scrapper.
            </span>
            {!userData.email_verified_at ? (
              <>
                { uncomment if need this email link expired condition }
                {{userData.email_link_expired ? ( }
                <div className="form-group-email-status">
                  {sendMailStatus ? (
                    sendMailStatus === 'pending' ? (
                      <p className="pending">Sending email...</p>
                    ) : sendMailStatus === 'error' ? (
                      <>
                        <p>Something went wrong</p>
                        <button
                          onClick={sendMailHandler}
                          className="button button__outline"
                        >
                          try again
                        </button>
                      </>
                    ) : (
                      <>
                        <div className="email-unverified">
                          <div>
                            <ExclamationSquare />
                          </div>

                        <p>Email Unverified</p>
                      </div>
                      { eslint-disable-next-line jsx-a11y/anchor-is-valid }
                      <a onClick={sendMailHandler}>Resend code</a>{' '}
                    </>
                  )}
                </div>
                {) : (
                  <p className="form-group-message-email">
                    Email yet to be verified.
                  </p>
                )} }
              </>
            ) : (
              <span className="form-group__verified">
                <TickSquare />
                <p>Email Verified</p>
              </span>
            )}
          </div>
        </div> */}

        <div className="form-group-double">
          <div className="form-group">
            <div className="form-group__input">
              <LabelTextInput
                label="Country"
                value={country}
                icon={<DownArrowIcon className="dropdownicon" />}
              >
                <select
                  id="country"
                  name="country"
                  className="country input-field"
                  onChange={(e) =>
                    handleChange(() => {
                      setCountry(e.target.value);
                      fetchTimezones(e.target.value, true);
                    })
                  }
                  disabled={countriesLoading}
                  value={country ?? ''}
                >
                  {countriesList.map((country) => (
                    <option key={country.name} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </LabelTextInput>
            </div>
          </div>
          {
            //Uncomment below code for timezone
          }
          {/* <div className="form-group">
            <div className="form-group__input">
              <LabelTextInput label="Timezone">
                <select
                  id="timezone"
                  disabled={
                    timezonesList.length === 0 ||
                    countriesLoading ||
                    timezonesLoading
                  }
                  name="timezone"
                  className="input-field"
                  onChange={(e) =>
                    handleChange(() => setTimezone(e.target.value))
                  }
                  value={timezone}
                >
                  {timezonesList.map((tz) => (
                    <option key={tz} value={tz}>
                      {tz}
                    </option>
                  ))}
                </select>
              </LabelTextInput>
            </div>
          </div> */}
          <div className="profile-form__URL">
            {renderInput('Location', null)}
          </div>
        </div>
        <div className="form-group">
          <div className="form-group__input">
            <LabelTextInput
              label={'Bio'}
              value={bio}
              onChange={(e) => handleChange(() => setBio(e))}
              placeholder="Let others know who you are"
              inputName="bio"
              type="textarea"
              showLabelOnFocus={true}
            />
            <span className="form-group__char-limit">
              {bio?.length || 0}/{charLimits.userBio}
            </span>
          </div>
        </div>

        {showUploader && (
          <>
            <span className="user-detail__toggle">Cover Image</span>
            <AddImage
              hideCoverImageLabel
              removeButtonLabel="Replace"
              key={`image-${true}`}
              showUseExisting={true}
              image={coverImage}
              showUploader={showUploader}
              undoImageHandler={() => undoImageHandler()}
              setShowUploader={setShowUploader}
              convertCdnToBase64
              onChange={(image, type, mime_type) => {
                setGotEdited(true);
                if (type === 'remove') {
                  setCoverImage(null);
                  setFile(null);
                } else if (type === 'file') {
                  onFileChange(image as File);
                } else if (type === 'cdn-url') setCropUrl(image as string);
                else if (type === 'b64Url') setCropUrl(image as string);
                setMimeType(mime_type);
              }}
            />
          </>
        )}

        <div className="user-detail__toggle-container">
          {!showUploader && (
            <div className="user-detail__toggle-wrapper">
              <span className="user-detail__toggle">Add Cover Image</span>
              <AddIcon
                className="user-detail__toggle-icon"
                onClick={() => setShowUploader(true)}
              />
            </div>
          )}
          {!showURL && (
            <div className="user-detail__toggle-wrapper">
              <span className="user-detail__toggle">Add URL</span>
              <AddIcon
                onClick={() => setShowURL(true)}
                className="user-detail__toggle-icon"
              />
            </div>
          )}
          {showURL && (
            <div className="user-detail__URL">
              {renderInput('URL', null, null, null, null, null, 'url')}
              <MinusIcon
                onClick={() => {
                  setShowURL(false);
                  setUserURL('');
                }}
                className="user-detail__remove-icon"
              />
            </div>
          )}

          <div className="user-detail__toggle-wrapper user-detail__social">
            <div className="user-detail__social__toggle">
              <span className="user-detail__toggle ">
                {Object.keys(socialMedia).length > 0
                  ? 'Social Media Accounts'
                  : 'Add Social Media'}{' '}
              </span>
              {Object.keys(socialMedia).length !==
                Object.keys(social).length && (
                <span className="user-detail__toggle-icon-position">
                  <AddIcon
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onClick={() => setShowSocial(!showSocial)}
                    className="user-detail__toggle-icon user-detail__social__icon"
                  />

                  {showSocial &&
                    Object.keys(socialMedia).length !==
                      Object.keys(social).length && (
                    <div
                      className={`user-detail__social-id-move-up`}
                      ref={dropDownRef}
                    >
                      {Object.keys(social).map((item, index) => {
                        if (Object.keys(socialMedia).includes(item))
                          return null;
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              setSocialMedia((prev) =>
                                Object.keys(prev)?.includes(item)
                                  ? prev
                                  : { ...prev, [item]: '' }
                              );
                              setShowSocial(false);
                            }}
                            className="user-detail__social-options"
                          >
                            <span className="user-detail__social-options__icon">
                              {social[item].icon}
                            </span>
                            <span className="user-detail__social-options__label">
                              {social[item].label === 'Twitter' ? 'X' : social[item].label}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </span>
              )}
            </div>
          </div>
          <div
            className="user-detail__social-link-wrapper"
            ref={dropDownContent}
          >
            {Object.keys(socialMedia)?.map((item, index) => {
              return (
                <div className="user-detail__social-link" key={item}>
                  {social[item]?.element(item)}
                  <MinusIcon
                    className="user-detail__remove-icon"
                    onClick={() => {
                      setSocialMedia((prevItem) => {
                        let newObj = { ...prevItem };
                        delete newObj[item];
                        return { ...newObj };
                      });
                      setSocialLinks((prev) => {
                        let newObj = { ...prev };
                        delete newObj[item];
                        return newObj;
                      });
                      newInvalidURl = isInvalidURL.filter((i) => i !== item);
                      setIsInvalidURL(newInvalidURl);
                      setGotEdited(true);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="box-footer">
        {(errorMessage || success) && (
          <p className={errorMessage ? 'error' : 'success'}>
            {errorMessage ? errorMessage : success}
          </p>
        )}
        <button
          className={`box-footer__cancel-button`}
          onClick={() => handleCancel()}
        >
          Cancel
        </button>
        <button
          disabled={disableSubmit || isInvalidURL.length > 0}
          type="submit"
          className={`button  pull-right ${
            disableSubmit || isInvalidURL.length > 0
              ? 'button-primary'
              : 'button__primary'
          }`}
        >
          <Tick />
          Save Changes
        </button>
      </div>
    </form>
  );
};

export default EditPublicProfileForm;
