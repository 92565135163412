import React, { useEffect, useState, useContext, Fragment } from 'react';

import CollectionFiltersContext from '../helpers/contexts/collectionFiltersContext';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleSidebarAction,
  setReloadSideNavCollections,
  setReloadSideNavFilters,
  setBackUrl,
  setIsMainPageLoading,
  setReloadSideNavShares,
} from '../redux/action/utils';
import SideNavItem from '../components/SideNav/SideNavItem';
import SideNavWrapper from '../components/SideNav/SideNavWrapper';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import SCRAPS_FILTERS_OPTIONS from './data/scrapsFilterOptions';
import GROUP_SHARE_FILTER_OPTIONS from './data/groupShareFilterOptions';
// import { FILTER_TYPE } from './data/filterType';
import { COLLECTIONS_FOR_SIDE_NAV } from './data/collections';
import { UPSERT_COLLECTION } from './data/collection';
import { filterItem, filterTypes } from '../types/filter';
import qs from 'qs';
import SideNavDropdown from '../components/SideNav/SideNavDropdown';
import SideNavDropdownListItem from '../components/SideNav/SideNavDropdown/SideNavDropdownlistItem';
import { getResponseMessages, parseTagFromSlug, parseUrlParams, returnAllGroupMembersExceptMe, slugifyString } from '../helpers';
import { collectionType } from '../types/collections';
import CreateCollectionModal from '../components/Collections/components/CreateCollectionModal';
import {
  CollectionIcon,
  NewGlobeIcon,
  Collaborator,
  BookmarkIcon,
  MyScrapsIcon,
  ContactsIcon,
  ShareIcon,
  NewTagIcon,
} from '../components/Global/icons';
import { ReduxStateType } from '../redux/store';
import {
  addCollectionToStore,
  loadCollections,
} from '../redux/action/collectionAction';
import { parseTryCatchError } from '../helpers/parseTryCatchError';
import { userTypes } from '../types/user';
import { GET_CONTACTS_INVITATIONS } from './data/contactsInvitationList';
import GET_GROUP_SHARE_LIST from './data/getGroupShareList';
import { GroupType } from './GroupShareContainer/types';
import { setGroupFilters } from '../redux/action/filterAction';
/*
 * COMPONENT DESCRIPTION
 * This component is used as container which performs all the business logic for side navigation bar shown in dashboard 
   It is used to show all the tabs and filters which can be applied on the screen where user is currently in
   This can also be used to navigate between my scraps, collections, collaboration and uncategorized screens
 */

// DEFINE THE HELPER TYPE TO BE USED IN THE COMPONENT RATHER THAN INDIVIDUALLY HANDLING
// IT IS USED TO DEFINE THE TYPE OF OBJECT WHICH IS PARSED FROM SEARCH URL
interface queryParamsType {
  types?: string; // STRING WHICH CONSISTS MULTIPLE TYPES OF SCRAP JOINED BY COMMA
  tags?: string; // STRING WHICH CONSISTS MULTIPLE TAGS JOINED BY COMMA.
  collections?: string; // STRING WHICH CONSISTS MULTIPLE COLLECTIONS JOINED BY COMMA
  untagged?: string | boolean; // CAN EITHER BE STRING OR BOOLEAN WHICH IS EQUAL TO TRUE IF PRESENT
}

// THIE TYPE WILL BE USED FOR OBJECTS WHICH ARE USED TO HANDLE EACH TABS OF SIDE NAVIGATION COMPONENT
export interface filterItemType {
  icon?: string;
  reactElementIcon?: React.ReactElement
  label:
    | 'Scraps'
    | 'Collections'
    | 'Tags'
    | 'Scrap Types'
    | 'Uncategorized'
    | 'Collaborations'
    | 'Contacts'
    | 'Shares'
    | 'Saved'
    | 'Saved-Scraps'
    | 'Saved-Collections';
  isToggle?: boolean;
}
// SOME HELPER MAPPERS
const mappers = {
  Collections: 'collections',
  Tags: 'tags',
  'Scrap Types': 'types',
  Shares: 'shares',
};
const placeholderMappers = {
  Collections: 'Search or create',
  Tags: 'Search Tags',
  Shares: 'Search Shares',
  'Scrap Types': 'Search Type',
};

// FUNCTION TO CHECK IF CERTAIN FILTER IS ACTIVE OR NOT
function isFilterActive(
  search: string, // SEARCH QUERY FROM URL
  value: string, // VALUE TO LOOK FOR
  key: string, //
  itemLabel: string
) {
  const tempParams = qs.parse(search.substring(1));
  let isActive = false;
  let parsedData = tempParams[key];
  // For one scrap, the tags will be saved in url along with their id. example: /?tag=example-tag::1
  // Where label and id of tag will be joined via '::'
  //  FOR UNTAGGED PARAMETER
  if (
    itemLabel.toLowerCase() === 'untagged' &&
    value === 'true' &&
    tempParams.untagged
  ) {
    isActive = true;
  } else if (parsedData && itemLabel.toLowerCase() !== 'untagged') {
    if (typeof parsedData === 'string' && parsedData === value) {
      isActive = true;
    } else if (
      Array.isArray(parsedData) &&
      parsedData.findIndex((item) => item === value) >= 0
    ) {
      isActive = true;
    }
  }
  return isActive;
}

function isCollectionActive(pathnameValue: string, id: string) {
  const splittedList = pathnameValue.split('/');
  return splittedList[splittedList.length - 1] == id;
  // return false;
}

let filterItems: filterItemType[] = [
  {
    label: 'Scraps',
    isToggle: true,
    reactElementIcon: <MyScrapsIcon />
  },
  {
    label: 'Uncategorized',
    icon: '/dashboard/svg-icons/uncategorised-side-nav.svg',
  },
  {
    label: 'Collections',
    isToggle: true,
    reactElementIcon: <CollectionIcon />
  },
  {
    label: 'Saved',
    reactElementIcon: <BookmarkIcon isFilled />
  },
  {
    label: 'Tags',
    isToggle: true,
    reactElementIcon: <NewTagIcon />
  },
  {
    label: 'Contacts',
    reactElementIcon: <ContactsIcon />
  },
  {
    label: 'Shares',
    reactElementIcon: <ShareIcon type='connected-dots' />,
    isToggle: true,
  },
];

const AreTwoStringArraysEqual = (array1: string[], array2: string[]) => {
  if (!Array.isArray(array1) || !Array.isArray(array2)) return true;
  if (array1.length !== array2.length) return false;
  for (let i = 0; i < array1.length; i++) {
    if (!array2.includes(array1[i])) return false;
  }
  return true;
};

interface Proptypes {
  hideSideNavFilters?: boolean | string;
}

const SideNavigation = ({ hideSideNavFilters }: Proptypes) => {
  const {
    filters: collectionContextFilters,
    setFilters: setCollectionContextFilters,
    collectionId,
    setCollectionId,
  } = useContext(CollectionFiltersContext);

  const dispatch = useDispatch();
  const groupFilters = useSelector((state: ReduxStateType) => state.filters?.groupFilters)
  const collections = useSelector((state: ReduxStateType) => state.collections);
  const userData = useSelector(
    (state: ReduxStateType) => state.user as userTypes
  );
  const backUrl = useSelector((state: ReduxStateType) => state.utils.backUrl);
  const reloadSideNavFilters = useSelector(
    (state: ReduxStateType) => state.utils.reloadSideNavFilters
  );
  const reloadSideNavCollections = useSelector(
    (state: ReduxStateType) => state.utils.reloadSideNavCollections
  );


  const reloadSideNavShares = useSelector(
    (state: ReduxStateType) => state.utils.reloadSideNavShares
  );


  const isMainPageLoading = useSelector(
    (state: ReduxStateType) => state.utils?.isMainPageLoading
  );
  const { message, invitationsCountSetter } = useSelector((state: ReduxStateType) => state.supabase)
  // const expanded = useSelector((state: ReduxStateType) => state.utils.expanded);
  const location = useLocation();
  const { pathname, search } = location;

  let currentScreen:
    | ''
    | 'group-page'
    | 'home-page'
    | 'uncategorized-page'
    | 'home-page-scrap-popup'
    | 'my-collection-page-scrap-popup'
    | 'collection-page-scrap-popup'
    | 'my-collection-page'
    | 'collection-page'
    | 'all-shares-page'
    | 'global-search-page'
    | 'account-page'
    | 'not-found-page'
    | 'collaborations-page'
    | 'my-contacts-page'
    | 'my-bookmarks-page'
    | 'saved-scraps'
    | 'saved-collections'
    | 'public-scrap-page' = '';
  if (pathname.startsWith('/shares')) {
    if (pathname.startsWith('/shares/') && pathname.replace('/shares/', '')) currentScreen = 'group-page';
    else currentScreen = 'all-shares-page';
  } else if (pathname === '/') currentScreen = 'home-page';
  else if (pathname.startsWith('/scrap/'))
    currentScreen = 'home-page-scrap-popup';
  else if (pathname.startsWith('/c/')) {
    if (pathname.includes('/scrap/'))
      currentScreen = 'my-collection-page-scrap-popup';
    else currentScreen = 'my-collection-page';
  } else if (pathname.includes('/c/')) {
    if (pathname.includes('/scrap/'))
      currentScreen = 'collection-page-scrap-popup';
    else currentScreen = 'collection-page';
  } else if (pathname.startsWith('/uncategorized'))
    currentScreen = 'uncategorized-page';
  else if (pathname === '/search') currentScreen = 'global-search-page';
  else if (pathname.startsWith('/profile')) currentScreen = 'account-page';
  else if (pathname === '/not-found') currentScreen = 'not-found-page';
  else if (pathname === '/collaborations')
    currentScreen = 'collaborations-page';
  else if (pathname === '/contacts') currentScreen = 'my-contacts-page';
  else if (pathname === '/saved') {
    if(search === '?type=scraps') currentScreen = 'saved-scraps';
    else if(search === '?type=collections') currentScreen = 'saved-collections'
    else currentScreen = 'my-bookmarks-page'
  } else if (pathname.includes('/scrap/')) currentScreen = 'public-scrap-page'
  
  const { push, replace } = useHistory();
  const { width } = useSelector(
    (state: ReduxStateType) => state.utils.dimensions
  );
  const user = useSelector((state: ReduxStateType) => state.user);

  const client = useApolloClient();
  const [upsertCollection] = useMutation(UPSERT_COLLECTION());

  const [searchState, setSearchState] = useState(search);

  let searchToUse = searchState;

  const [creatingCollection, setCreatingCollection] = useState(false);
  const [showCreateCollection, setShowCreateCollection] = useState(false);
  const [invitationsCount, setInvitationsCount] = useState(0);
  

  const [status, setStatus] = useState<'pending' | 'loaded' | 'error'>(
    'pending'
  );
  const [shareGroupStatus, setShareGroupStatus] = useState<'pending' | 'loaded' | 'error'>(
    'pending'
  );
  const [, setCollectionsStatus] = useState<'pending' | 'loaded' | 'error'>(
    'pending'
  );

  const [loader, setLoader] = useState(false);

  // activeItem State to highlight the filter category tab in side navigation
  const [activeItem, setActiveItem] = useState<filterItemType['label']>(
    'Scraps'
  );
  // state to store the filters data which are being fetched
  const [filterData, setFilterData] = useState<filterTypes[]>();

  // State to store the value of current opened tab in side nav
  const [currentOpenTab, setCurrentOpenTab] = useState<filterItemType['label']>(
    null
  );

  const [page,setPage] = useState(1);
  
  let numOfRecords = 10;

  let variables = {
    page: 1,
    first: 500,
  };


  const { data: shareData, loading, refetch } = useQuery(
    GET_GROUP_SHARE_LIST,
    {
      variables,
      client,
      fetchPolicy: 'cache-and-network',
    }
  );


  let data: GroupType []= [{ name:"All Shares" },...shareData?.getGroupShareList?.data||[]];

  const isCollectionPage = currentScreen.includes('collection-page');
  let searchQueryString = search;
  if (isCollectionPage)
    searchQueryString =
      '?' +
      qs.stringify(collectionContextFilters, {
        arrayFormat: 'repeat',
      });
  else if(currentScreen === 'group-page') {
    searchQueryString =
      '?' +
      qs.stringify(groupFilters, {
        arrayFormat: 'repeat',
      });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let parsedSearchFilter: any = qs.parse(search.substring(1))
  if(isCollectionPage) parsedSearchFilter =  collectionContextFilters
  else if(currentScreen === 'group-page') parsedSearchFilter = groupFilters;

  const shouldToggleSideNav = width > 768 ? false : true;

  let areSearchesEqual = false;
  if (width < 450) {
    if (
      (!searchQueryString && !searchState) ||
      searchQueryString === searchState
    ) {
      areSearchesEqual = true;
    } else {
      const splittedSearch = searchQueryString.replace('?', '').split('&');
      const splittedSearchState = searchState.replace('?', '').split('&');
      areSearchesEqual = AreTwoStringArraysEqual(
        splittedSearch,
        splittedSearchState
      );
    }
  }
  // This variable will be used to hide filter tabs from side nav
  // and also used to prevent fetching filter queries
  let hideFilters = true;
  if(currentScreen === 'uncategorized-page') hideFilters = false;
  if(currentScreen.includes('home-page')) hideFilters = false;
  if(currentScreen.includes('my-collection-page')) hideFilters = false;
  if(currentScreen === 'global-search-page') hideFilters = false;
  if(currentScreen === 'group-page') hideFilters = false;
  
  const fetchInvitationsCountHandler = async () => {
    try {
      const response = await client.query({
        query: GET_CONTACTS_INVITATIONS,
        variables: {
          action: 'invitations',
          page: 1,
          first: 1,
        },
      });
      const count =
        response.data.getMyContactsOrInvitations.data.knot_count.received_notification;
      setInvitationsCount(count);
    } catch (error) {
      setInvitationsCount(0);
    }
  };

  function collectionClickHandler(collection: collectionType) {
    const newPathname = `/c/${slugifyString(collection.title, false)}/${
      collection.id
    }`;
    if (newPathname !== pathname || collectionContextFilters) {
      dispatch(setIsMainPageLoading(true));
      replace(`/c/${slugifyString(collection.title, false)}/${collection.id}`);
    }

    if (shouldToggleSideNav) {
      dispatch(toggleSidebarAction(false));
    }
    scrollToTop();
  }

  function groupShareClickHandler(groupShare: GroupType) {
    const newPathname = `/shares/${
      groupShare.id
    }`;

    if (newPathname !== pathname && groupShare?.id) {
      replace(`/shares/${groupShare.id}`);
    }
    else if(!groupShare.id){
      replace(`/shares`)
    }
  }

  // Function which will run when user clicks on any filter item from the filter list category
  function filterItemClickHandler(
    label: filterItemType['label'], // Label value of filter category tab
    item: filterItem // Value of item which is clicked by the user
  ) {
    const value = item.key ? parseTagFromSlug(item.key, item.doc_count) : item;
    // If user is inside collection page, then there will be no query params in the url,
    // So we have to use context for now to store all applied filters from side nav in context
    if (isCollectionPage || currentScreen === 'group-page') {
      const tempFilters = {
        ...(qs.parse((searchState || '').substring(1)) || {}),
      };
      const mappersLabel =
        value.label.toLowerCase() === 'untagged' &&
        value.value.toLowerCase() === 'true'
          ? 'untagged'
          : mappers[label];
      if (!tempFilters[mappersLabel]) {
        tempFilters[mappersLabel] = [value.value];
      } else {
        if (typeof tempFilters[mappersLabel] === 'string') {
          tempFilters[mappersLabel] = [tempFilters[mappersLabel]] as Array<
            string
          >;
        }
        const filtersArray = tempFilters[mappersLabel] as Array<string>;
        const existingIndex = filtersArray.findIndex(
          (item) => item === value.value
        );
        if (existingIndex >= 0) filtersArray.splice(existingIndex, 1);
        else filtersArray.push(value.value);
      }
      if (value.label.toLowerCase() === 'untagged') {
        if (
          Array.isArray(tempFilters.untagged) &&
          tempFilters.untagged.length === 0
        ) {
          delete tempFilters.untagged;
        } else if (tempFilters.untagged) {
          delete tempFilters.tags;
          tempFilters['untagged'] = ['true'];
        } else delete tempFilters.untagged;
      } else {
        if (tempFilters.untagged && label === 'Tags')
          delete tempFilters.untagged;
      }
      if (width < 450) {
        setSearchState(
          `?${qs.stringify(tempFilters, {
            arrayFormat: 'repeat',
          })}`
        );
      } else {
        if (isCollectionPage) setCollectionContextFilters(tempFilters);
        else {
          if (groupFilters?.tags?.length) {
            const tagsFiltersList =
              filterData.find((item) => item.type === 'tags')?.values ?? [];
            const newTagFilters = (tempFilters as ReduxStateType['filters']['groupFilters']).tags.filter(
              (item) =>
                tagsFiltersList.findIndex(
                  (tagData) => tagData.key.toLowerCase() === item.toLowerCase()
                ) > -1
            );
            tempFilters.tags = newTagFilters;
          }
          dispatch(setGroupFilters(tempFilters));
        }
      }
    } else {
      // Else handle for filter by tags or types category
      let searchParams: queryParamsType = qs.parse(searchToUse.substring(1));
      const mappersLabel =
        value.label.toLowerCase() === 'untagged' &&
        value.value.toLowerCase() === 'true'
          ? 'untagged'
          : mappers[label];

      const property = mappersLabel;
      // This variable stores the value which will be matched with url search query params
      // If the filters are for one scraps, then tags ids are stored in params along with their label
      const valueToSearch = value.value;
      // if there is not such query parameter in the url
      if (!searchParams[property]) searchParams[property] = value.value;
      else {
        if (typeof searchParams[property] === 'string') {
          // If property value type is string, it means that there is
          // only one query parameter value set in url for selected property
          if (searchParams[property] === valueToSearch) {
            // if query value already exists, then it means that user is unchecking that filter
            delete searchParams[property];
          } else {
            // Else user is applying the filter
            searchParams[property] = [searchParams[property], valueToSearch];
          }
        } else if (Array.isArray(searchParams[property])) {
          // There are multiple query parameter value sets in the url of same key
          // which is equal to property value defined above
          let result = searchParams[property] as Array<string>;
          const existingIndex = result.findIndex(
            (item) => item === valueToSearch
          );
          if (existingIndex >= 0) {
            // If value is already present in the url, then remove it from the url
            result.splice(existingIndex, 1);
          } else {
            // else add it to the url
            result.push(valueToSearch);
          }
        }
      }
      if (value.label.toLowerCase() === 'untagged') {
        if (searchParams.untagged) {
          delete searchParams.tags;
          searchParams['untagged'] = true;
        } else delete searchParams.untagged;
      } else {
        if (searchParams.untagged && label === 'Tags')
          delete searchParams.untagged;
      }

      let query = qs.stringify(searchParams, {
        arrayFormat: 'repeat',
      });

      if (width < 450) {
        setSearchState(`?${query}`);
      } else {
        dispatch(setIsMainPageLoading(true));
        replace(`?${query}`);
      }
    }
  }
  // Function to render each filter category dropdown
  function renderFilterTab({ label }: filterItemType) {
    if(label === 'Scraps') {
      return (
        <SideNavDropdown onClick={itemClickHandler} showSavedScraps showMyScraps showUncategorized notSearchable list={[]} labelField={null} valueField={null}>
          {() => null}
        </SideNavDropdown>
      );
    } else if (label !== 'Collections' && label !== 'Shares') {
      if (!filterData) return null;
      let selectedData = filterData.find(
        (item) => item.type === mappers[label]
      );
      if (!selectedData || !selectedData?.values?.length) return null;
      if (label === 'Scrap Types') {
        selectedData.values = selectedData.values.filter((data) => {
          const item = data.key
            ? parseTagFromSlug(data.key, data.doc_count)
            : data;
          return (
            item.count ||
            isFilterActive(
              backUrl || search,
              item.value,
              mappers[label],
              item.label
            )
          );
        });
      }
      return (
        <SideNavDropdown<filterItem>
          scrollable={label === 'Tags'}
          list={selectedData.values.map((data) =>
            data.key ? parseTagFromSlug(data.key, data.doc_count) : data
          )}
          placeholder={placeholderMappers[label]}
          labelField="label"
          valueField="value"
          notSearchable={label === 'Scrap Types'}
          onClick={itemClickHandler}
          showFull={label === 'Scrap Types'}
        >
          {(item) => {
            let active = false;
            active = isFilterActive(
              backUrl || searchToUse,
              item.value,
              mappers[label],
              item.label
            );
            let renderConfig;
            renderConfig = {
              type: 'text',
              data: item.count,
            };

            return (
              <SideNavDropdownListItem
                key={item.value + label}
                label={item.label}
                onClick={filterItemClickHandler.bind(null, label, item)}
                isMultiselect
                active={active}
                renderConfig={renderConfig}
              />
            );
          }}
        </SideNavDropdown>
      );
    } else if (label === 'Shares') {
      return (
        <SideNavDropdown<GroupType>
          list={data}
          isGroupList
          placeholder={placeholderMappers[label]}
          labelField="nickname"
          valueField="id"
          onClick={itemClickHandler}
          scrollable
          loader={loader}
          loadMore={loadMoreHandler}
          setLoader={setLoader}
          shareGroupStatus={shareGroupStatus}
          label="Shares"
          loading={loading}
        >
          {(item) => {
            const members = returnAllGroupMembersExceptMe(item, user.user_id);
            let active = false;
            const currentPath = pathname.split('/')
            if (+currentPath[2] === +item.id && currentPath[1] === 'shares') {
              active = true;
            } else if (
              !item.id &&
              // @ts-ignore
              item.name === 'All Shares' &&
              pathname === '/shares'
            ) {
              active = true;
            }
            let count = members.length > 1 ? members.length + 1 : null;
            // @ts-ignore
            let label: string = item.name;
            if (!label) {
              if (item.nickname && count) label = item.nickname;
              else {
                if (!count) {
                  if (item.owner_user_name !== user.userName)
                    label = item.owner_display_name ?? item.owner_user_name;
                  else
                    label =
                      item.members[0]?.display_name ??
                      item.members[0]?.user_name;
                } else
                  label = members
                    ?.map((obj) => obj['display_name'] ?? obj['user_name'])
                    .join(', ');
              }
                
            }
            
            return (
              <SideNavDropdownListItem
                key={item.id + label}                
                label={label}
                onClick={groupShareClickHandler.bind(null, item)}
                active={active}
                count={count}
              />
            );
          }}
        </SideNavDropdown>
      );
    } else {
      let selectedData = collections || [];
      // if (selectedData.length === 0 && collectionsStatus === 'pending')
      //   return null;
      return (
        <SideNavDropdown<collectionType>
          creatable
          list={selectedData}
          placeholder={placeholderMappers[label]}
          labelField="title"
          valueField="id"
          createConfig={{
            buttonLabel: 'New Collection',
            onClick: () => {
              setShowCreateCollection(true);
            },
          }}
          onClick={itemClickHandler}
          onCreate={createCollectionHandler}
          scrollable
          loader={loader}
          setLoader={setLoader}
          showCollections
          shoCollaborations
          showSavedCollections
        >
          {(item) => {
            let active = false;
            if (isCollectionPage) {
              active = isCollectionActive(pathname, item.id);
            }
            let renderConfig;
            if (userData.userName === item.user_name) {
              if (item?.members?.length > 0 && +item.state !== 3) {
                renderConfig = {
                  type: 'icon',
                  render: (elementProps) => <Collaborator {...elementProps} />,
                };
              }
            }

            if (+item.state === 3) {
              renderConfig = {
                type: 'icon',
                render: (elementProps) => <NewGlobeIcon {...elementProps} />,
              };
            }
            if (+item.state === 1 || !item.state || +item.state === 2) {
              if (item?.members?.length > 0) {
                renderConfig = {
                  type: 'icon',
                  render: (elementProps) => <Collaborator {...elementProps} />,
                };
              } else {
                renderConfig = {
                  type: 'icon',
                  render: (elementProps) => <CollectionIcon />,
                };
              }
            }

            return (
              <SideNavDropdownListItem
                key={item.id + label}
                label={item.title}
                onClick={collectionClickHandler.bind(null, item)}
                isMultiselect={label !== 'Collections'}
                active={active}
                renderConfig={renderConfig}
              />
            );
          }}
        </SideNavDropdown>
      );
    }
  }

  // Function handler for creating new collection
  const createCollectionHandler = async (variables: {
    title: string;
    desc?: string;
    cover_image?: string;
    view_type?: number;
  }) => {
    if (variables.title !== undefined) {
      variables['state'] = 2;
    }

    setCreatingCollection(true);
    try {
      var collectionData = await upsertCollection({
        variables,
      });
      setCreatingCollection(false);
      setShowCreateCollection(false);
      setLoader(false);
      dispatch(setIsMainPageLoading(true));
      push(
        `/c/${slugifyString(
          collectionData.data.upsertCollection.data.title,
          false
        )}/${collectionData.data.upsertCollection.data.id}`
      );
      dispatch(setReloadSideNavCollections(true));
      dispatch(addCollectionToStore(collectionData.data.upsertCollection.data));
      setCreatingCollection(false);
    } catch (error) {
      // alert('Something went wrong');
      setCreatingCollection(false);
      throw new Error(parseTryCatchError(error));
    }
  };

  async function fetchHandler(
    searchParams: queryParamsType,
    additionalParams: Object = {}
  ) {
    try {
      if(hideFilters) return;
      setStatus('pending');

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const variables: any = { ...searchParams, ...additionalParams };
      if (variables['untagged']) {
        variables['untagged'] = variables['untagged'] === 'true' ? true : false;
      }
      if(currentScreen === 'group-page') {
        if (variables.tags)
          variables.tags = variables.tags.split(',').map((slug) => ({
            slug,
          }));
        const response = await client.query({
          query: GROUP_SHARE_FILTER_OPTIONS(),
          variables,
        });
        const { error, isSuccess, success } = getResponseMessages(
          response.data.groupShareFilterOptions
        );
        const responseFilters: filterTypes[] = response?.data?.groupShareFilterOptions?.data ?? []
        if(!isSuccess) throw new Error(error[0]);
        setFilterData(responseFilters);
      } else {
        // If filters are to be loaded for one scraps page, then use SCRAPS_FILTER_OPTIONS
        // to fetch filters, else use old FILTER_TYPE query
        if (variables.tags)
          variables.tags = variables.tags.split(',').map((slug) => ({
            slug,
          }));
        const response = await client.query({
          query: SCRAPS_FILTERS_OPTIONS,
          variables,
        });

        const responseData = response.data.scrapsFilterOptions;
        if (!responseData.messages.success.length) {
          throw new Error('failed to fetch filters');
        }
        setFilterData(responseData.data);
      } 
      // else {
      //   const res = await client.query({ query: FILTER_TYPE, variables });

      //   if (!Array.isArray(res?.data?.filterType))
      //     throw new Error('Failed to fetch filters');
      //   setFilterData(res.data.filterType);
      // }
      setStatus('loaded');
    } catch (error) {
      //
      setStatus('error');
    }
  }






  // Function handler to fetch all collections
  async function fetchCollectionsHandler() {
    try {
      setCollectionsStatus('pending');
      const variables = {
        // action: 'list',
        roles:[0,1]
      };
      const res = await client.query({
        query: COLLECTIONS_FOR_SIDE_NAV,
        variables,
      });
      const list: collectionType[] = res?.data?.collections?.data || [];
      dispatch(loadCollections(list));
    } catch (error) {
      //
    }
  }





  function loadMoreHandler() {
    if ((data.length-1) % numOfRecords !== 0) return;
    setPage(Math.floor((data.length-1) / numOfRecords) + 1);
  }



  const scrollToTop = () => {
    try {
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    } catch (error) {
      //
    }
  };
  // Handler function which will run when user clicks on any filter category label
  function itemClickHandler(label: filterItemType['label'], isToggle?: boolean) {
    // Url mapper to check if user has clicked on My Scraps or uncategorized tab

    const urlMapper = {
      Scraps: '/',
      Uncategorized: '/uncategorized',
      Collaborations: '/collaborations',
      Contacts: '/contacts',
      Saved: '/saved',
      'Saved-Scraps': '/saved?type=scraps',
      'Saved-Collections': '/saved?type=collections',
      'Collections': '/collections',
    };

    if (urlMapper[label] && !isToggle) {
      if(label === 'Saved') return replace(urlMapper[label]);
      // Redirect to my scraps or uncategorized page
      if (currentScreen === 'home-page' ||  urlMapper[label] === "/") {
        scrollToTop();
      }

      if (pathname !== urlMapper[label] || search) {
        dispatch(setIsMainPageLoading(true));
        replace(urlMapper[label]);
      }

      //  Close all other opened tabs in side nav
      // setCurrentOpenTab(null);
      dispatch(setBackUrl(null));
      if (shouldToggleSideNav) dispatch(toggleSidebarAction(false));
    } else {
      // Else user has clicked on openable tab
      //  Open the tab if not opened other wise close it
      setCurrentOpenTab((old) => (old === label ? null : label));
    }
  }
  
  function renderSideNavItem(item: filterItemType) {
    if(item.label === 'Tags' && hideFilters) return null
    const tempParams = qs.parse(search.substring(1));
    // If page opened is global search bar,
    // then hide filter by type and category tabss
    if (item.label === 'Uncategorized' || item.label === 'Collaborations') {
      return;
    }
    if (
      ( currentScreen === 'account-page' ||
        currentScreen === 'global-search-page' ||
        currentScreen === 'not-found-page' ||
        currentScreen === 'collaborations-page' ||
        currentScreen === 'my-contacts-page') &&
      ['Tags', 'Scrap Types'].includes(item.label)
    ) {
      if (tempParams.mode !== 'personal' || tempParams.type !== 'scraps')
        return null;
    }
    return (
      <SideNavItem
        onClick={itemClickHandler.bind(null, item.label, item.isToggle)}
        item={item}
        isActive={
          item.label === activeItem ||
          (item.label === 'Collections' &&
            (activeItem === 'Collaborations' || activeItem === 'Saved-Collections')) ||
          (item.label === 'Scraps' &&
            (activeItem === 'Uncategorized' || activeItem === 'Saved-Scraps'))
        }
        isOpen={currentOpenTab === item.label}
        count={item.label === 'Contacts' ? invitationsCount : null}
      >
        {renderFilterTab(item)}
      </SideNavItem>
    );
  }

  function applyFiltersClickHandler(type: 'cancel' | 'submit') {
    const parsedFilters = qs.parse((searchState || '').substring(1));
    if (parsedFilters['untagged']) {
      parsedFilters['untagged'] = ['true'];
    }
    switch (type) {
      case 'cancel':
        if (
          !parsedSearchFilter['tags'] &&
          !parsedSearchFilter['types'] &&
          !parsedSearchFilter['untagged']
        ) {
          setSearchState(
            (isCollectionPage || currentScreen === 'group-page')
              ? '?' +
                  qs.stringify(isCollectionPage ? collectionContextFilters : groupFilters, {
                    arrayFormat: 'repeat',
                  })
              : search
          );
          break;
        }
        if (parsedFilters['untagged']) delete parsedFilters['untagged'];
        if (parsedFilters['types']) delete parsedFilters['types'];
        if (parsedFilters['tags']) delete parsedFilters['tags'];

        if (isCollectionPage) {
          setCollectionContextFilters(parsedFilters);
        } else if(currentScreen === 'group-page') {
          dispatch(setGroupFilters(parsedFilters));
        } else {
          dispatch(setIsMainPageLoading(true));
          replace(
            '?' +
              qs.stringify(parsedFilters, {
                arrayFormat: 'repeat',
              })
          );
        }
        break;
      case 'submit':
        dispatch(setIsMainPageLoading(true));
        if(isCollectionPage) setCollectionContextFilters(parsedFilters)
        else if(currentScreen === 'group-page') dispatch(setGroupFilters(parsedFilters))
        else replace(searchState || '');
        break;

      default:
        break;
    }
    if (shouldToggleSideNav) dispatch(toggleSidebarAction(false));
  }

  useEffect(()=>{
    if(reloadSideNavShares)
    {
      refetch({ page:1 })
      setPage(1);
      dispatch(setReloadSideNavShares(false));
    }
  },[reloadSideNavShares]);

  useEffect(() => {
    if (currentScreen === 'my-contacts-page')
      setInvitationsCount(invitationsCountSetter?.value);
  }, [invitationsCountSetter, currentScreen]);

  useEffect(() => {
    if (typeof backUrl !== 'string' && !isMainPageLoading) {
      const params = parseUrlParams(search);
      if (currentScreen === 'uncategorized-page') {
        fetchHandler(params, { uncategorized: true });
      } else if (isCollectionPage) {
        // collectionFilterLoadHandler(collections);
      } else if(currentScreen === 'group-page') {
        // 
      } else {
        fetchHandler(params);
      }

      
    }
    // Handle active filter category tab state
    let activeTab: filterItemType['label'] = null;
    // Focus collections category tab if user is viewing collection details
    if (currentScreen.startsWith('my-collection-page')) activeTab = 'Collections';
    else if (currentScreen === 'uncategorized-page') activeTab = 'Uncategorized';
    else if (currentScreen === 'collaborations-page') activeTab = 'Collaborations';
    else if (currentScreen === 'my-contacts-page') activeTab = 'Contacts';
    else if (currentScreen === 'home-page') activeTab = 'Scraps';
    else if (currentScreen === 'all-shares-page' || currentScreen === 'group-page') activeTab = 'Shares';
    else if (currentScreen === 'my-bookmarks-page') activeTab = 'Saved';
    else if(currentScreen === 'saved-scraps') activeTab = 'Saved-Scraps';
    else if(currentScreen === 'saved-collections') activeTab = 'Saved-Collections'
    setActiveItem(activeTab);
    if (activeTab !== 'Collections') setSearchState(search);
  }, [search, pathname, backUrl, isMainPageLoading]);

  

  useEffect(() => {
    if (width < 450) {
      setSearchState((old) => {
        if (isCollectionPage) {
          return (
            '?' +
            qs.stringify(collectionContextFilters, {
              arrayFormat: 'repeat',
            })
          );
        } else if (currentScreen === 'group-page') {
          return (
            '?' +
            qs.stringify(groupFilters, {
              arrayFormat: 'repeat',
            })
          );
        }
        return search;
      });
    }
  }, [currentOpenTab]);
  //use this useEffect to default open collection in home page
  // useEffect(() => {
  //   if (pathname === '/' || pathname === '/uncategorized') {
  //     setCurrentOpenTab('Collections');
  //   }
  // }, []);

  useEffect(() => {
    if (typeof backUrl !== 'string' && !isMainPageLoading)
    {
      fetchCollectionsHandler();
      
    }
  }, [pathname, backUrl, isMainPageLoading]);


  useEffect(() => {
    // Only set collection contexnt filters to null if it is not in collection page
    if (!(isCollectionPage || pathname.includes('/scrap'))) {
      setCollectionContextFilters(null);
      setCollectionId(null);
    }
    if (currentScreen !== 'my-contacts-page') {
      fetchInvitationsCountHandler();
    }
    if(groupFilters) dispatch(setGroupFilters(null));
    if(!loading) {
      refetch({ page:1 })
      setPage(1);
    }
  }, [pathname]);

  useEffect(() => {
    if (message && pathname !== '/contacts') {
      fetchInvitationsCountHandler();
    }
  }, [message]);

  useEffect(() => {
    if (collectionId && !isMainPageLoading) {
      let filterParams = {};
      const filters = collectionContextFilters || {};
      for (let key in filters) {
        if (key !== 'untagged') filterParams[key] = filters[key].toString();
        else if (Array.isArray(filters[key]) && filters[key][0] === 'true')
          filterParams[key] = 'true';
      }
      setSearchState(
        '?' +
          qs.stringify(filters, {
            arrayFormat: 'repeat',
          })
      );
      fetchHandler(filterParams, {
        collection_id: collectionId,
      });
    }
  }, [collectionContextFilters, collectionId, isMainPageLoading]);

  useEffect(() => {
    if(currentScreen === 'group-page' && !isMainPageLoading) {
      let filterParams = {};
      const filters = groupFilters || {};
      for (let key in filters) {
        if (key !== 'untagged') filterParams[key] = filters[key].toString();
        else if (Array.isArray(filters[key]) && filters[key][0] === 'true')
          filterParams[key] = 'true';
      }
      setSearchState(
        '?' +
          qs.stringify(filters, {
            arrayFormat: 'repeat',
          })
      );
      fetchHandler(filterParams, {
        group_id: pathname.replace('/shares/',''),
      });
    }
  }, [groupFilters, currentScreen, isMainPageLoading])

  useEffect(() => {
    if (reloadSideNavCollections) {
      fetchCollectionsHandler();
      dispatch(setReloadSideNavCollections(false));
    }
  }, [reloadSideNavCollections]);
  useEffect(() => {
    if (reloadSideNavFilters) {
      const params = parseUrlParams(search);
      if (currentScreen === 'uncategorized-page') {
        fetchHandler(params, { uncategorized: true });
      } else if (isCollectionPage || currentScreen === 'group-page') {
        let filterParams = {};
        let filters = {};
        if(isCollectionPage) filters = collectionContextFilters || {};
        else filters = groupFilters || {};
        for (let key in filters) {
          if (key !== 'untagged') filterParams[key] = filters[key].toString();
          else if (Array.isArray(filters[key]) && filters[key][0] === 'true')
            filterParams[key] = 'true';
        }
        setSearchState(
          '?' +
            qs.stringify(filters, {
              arrayFormat: 'repeat',
            })
        );
        fetchHandler(filterParams, isCollectionPage ? {
          collection_id: collectionId,
        } : {
          group_id: pathname.replace('/shares/',''),
        });
      } else fetchHandler(params);
      dispatch(setReloadSideNavFilters(false));
    }
  }, [reloadSideNavFilters]);
  // useEffect(() => {
  //   if (collectionId && !isMainPageLoading) {
  //     collectionFilterLoadHandler(collectionId);
  //   }
  // }, [collectionId, isMainPageLoading]);

  // const searchParams: queryParamsType = qs.parse(searchState.substring(1));
  // let showCancelFilters = false;
  // if(width < 450) {
  //   if(searchParams.tags || searchParams.types || searchParams.untagged ) showCancelFilters = true;
  // }
  return (
    <Fragment>
      <SideNavWrapper
        showCancelFilters={!areSearchesEqual && width < 450}
        showApplyFilters={!areSearchesEqual && width < 450}
        onApplyFiltersClick={applyFiltersClickHandler}
        list={filterItems}
      >
        {renderSideNavItem}
      </SideNavWrapper>
      <CreateCollectionModal
        isCreating={creatingCollection}
        onCreate={createCollectionHandler}
        controlled={{
          open: showCreateCollection,
          setOpen: setShowCreateCollection,
        }}
      />
    </Fragment>
  );
};

export default SideNavigation;
