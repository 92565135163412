import React, { useState, useEffect } from 'react';
import Icon from '../../../Global/Icon';
import { ShareIcon } from '../../../Global/icons';
import ReturnShareIcon from '../../../Global/ReturnShareIcon';
import './styles.css';
import { handleNativeShare } from '../../../../helpers';
import { handleShare } from '../../../../helpers';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../../redux/store';


interface PropTypes {
  handleEdit?: (
    val: { title?: string; state?: number },
    cb?: Function
  ) => Promise<string>;
  status: number;
  shareUrl?: string;
  noUrlMessage?: string; // Custom message to show when share url is not present
  loading?: boolean;
  type: 'Scrap' | 'Collection' | 'Story';
  uneditable?: boolean;
  settings?: boolean;
  onGenerateLink?: () => Promise<string>;
  setScrapShareUrl?: (url: string) => void;
  view?: string;
  cardOwner?: string;
  alwaysShowPublic?: boolean;
  setEditVisibility?:(val:number)=>void;
  setEnableShareStatus?:(val:boolean)=>void;
  enableShareStatus?:boolean;
  collectionHandleCopy?:()=>void;
  copyMessage?:boolean;
  showFullMessage?:boolean;
}

const ScrapShare = (props: PropTypes) => {
  const {
    status,
    handleEdit,
    loading,
    shareUrl,
    type,
    uneditable,
    noUrlMessage,
    settings,
    onGenerateLink,
    setScrapShareUrl,
    view,
    cardOwner,
    alwaysShowPublic,
    setEditVisibility,
    setEnableShareStatus,
    enableShareStatus,
    showFullMessage
  } = props;
  const [shareLink, setShareLink] = useState(shareUrl);
  // Comment
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [processing, setProcessing] = useState(false);
  const [copied,setCopied]=useState(false)
  const user = useSelector((state: ReduxStateType) => state.user);
  const [copy,setCopy]=useState(false);
  // const copied = useRef(false);
  useEffect(() => {
    setShareLink(shareUrl);

    return () => {};
  }, [shareUrl]);

  

  let possibleState = [
    {
      title: 'Private',
      value: 1,
      description: `Only you can see this ${type}.`,
      icon: 'eye-slash',
    },
    {
      title: 'Private with Link',
      value: 2,
      description: `This ${type} will be Private but anyone with the link can view it.`,
      icon: 'link',
    },
    {
      title: 'Public',
      value: 3,
      description: `This ${type} will be visible to all users in the Scrappiverse.`,
      icon: 'eye-fill',
    },
  ];
  if (uneditable)
    possibleState = possibleState.filter((st) => +st.value === +status);

  const performShare = (shareURL: string, generate: boolean = false) => {
    if (generate) navigator.clipboard.writeText(encodeURI(shareURL || ''));
    else {
      handleShare(shareURL, 'show', () => {
        navigator.clipboard.writeText(encodeURI(shareURL || ''));
        // setTimeout(()=>setCopied(true),1000);
        setCopied(true)
        setTimeout(() =>setCopied(false), 1500);
        setCopy(true);
        setTimeout(() =>setCopy(false), 1500);
        let element = document.getElementsByClassName('scrap-share__actions-toggle-description')[0];
        element.scrollIntoView();
      });
    }
  };


  const handleCopy = (url?: string, generate?: boolean) => {
    if (typeof window !== 'undefined') {
      const isShared = handleNativeShare(url || shareUrl, () =>
        performShare(url || shareLink, generate)
      );
      if (isShared) return;
    }
    performShare(url || shareLink, generate);
  };

  const collectionEnableStatus = () => {
    if (status === 1 || status === 2 || !status) {
      setEnableShareStatus(!enableShareStatus);
    }
  };

  const getActiveOption = (status) => {
    return possibleState.find((option) => option.value === status);
  };

  let toolTipTitle = `Share link for this ${type}.`;

  const handleShareScrap = async () => {
    if (shareLink) {
      let url: string;
      const activeStatus = getActiveOption(status);
      if (!activeStatus) return;
      if (activeStatus.value === 1) {
        url = await handleEdit({ state: +2 });
      }
      handleCopy(url);
    } else {
      alert(noUrlMessage || 'Sorry, link is unavailable at the moment.');
    }
  };

  const generateProceedHandler = async () => {
    try {
      setProcessing(true);
      // onGenerateLink should always return new url link
      // for the scrap/collection which can be used here if required
      const newUrl = await onGenerateLink();
      setShareLink(newUrl);
      setScrapShareUrl && setScrapShareUrl(newUrl);
      setProcessing(false);
      handleCopy(newUrl, true);
    } catch (error) {
      //
    }
  };

  const toggleMessage =()=>{
    if(type === "Collection"){
      if(enableShareStatus){
        if(status === 3) {
          return (
            <p className="scrap-share__actions-toggle-description">
             Collection can be shared and is found in public collections.
            </p>
          );
        }else{
          return (
            <p className="scrap-share__actions-toggle-description">
             Collection can be shared. Toggle off to make it private.
            </p>
          );
        }
      }else{
        return (
          <p className="scrap-share__actions-toggle-description">
          Collection cannot be shared. Toggle on to share.
          </p>
        );
      }
    }
  }

  const toggleStatus=(status)=>{
    if(status===2)
    {
      return 1
    }
    else if(status===3)
    {
      return 4
    }
    else if(status===4)
    {
      return 3
    }
    else if(status==1)
    {
      return 2
    }

  }
  const statusMessage = () => {

    if(type === "Collection"){
      if(status === 1 && !enableShareStatus){
        return (
          <p className="scrap-share__actions-toggle-description">
           Collection is marked Private. Toggle on to make it public.
          </p>
        );
      }else if(status ===3) {
        return (
          <p className="scrap-share__actions-toggle-description">
           Collection is marked Public. Toggle off to make it private.
          </p>
        );
      }else{
        return (
          <p className="scrap-share__actions-toggle-description">
           Collection is marked shareable. Toggle on to make it public.
          </p>
        );
      }
    }else{
      if (status === 2 && alwaysShowPublic && showFullMessage) {
        return (
          <p className="scrap-share__actions-toggle-description">
            Scrap can be shared and is found in public collections.
          </p>
        );
      } else if (status === 2 && !alwaysShowPublic) {
        return (
          <p className="scrap-share__actions-toggle-description">
            Scrap can be shared. Toggle off to make it private.
          </p>
        );
      } else if ((status === 1 ||status ===4) && alwaysShowPublic && showFullMessage) {
        return (
          <p className="scrap-share__actions-toggle-description">
            Scrap cannot be shared but is found in public collections.
          </p>
        );
      } else if (status !== 2 && !alwaysShowPublic) {
        return (
          <p className="scrap-share__actions-toggle-description">
            Scrap is private and cannot be shared.
          </p>
        );
      } 
      else if(status===3&&!showFullMessage)
      {
        return (
          <p className="scrap-share__actions-toggle-description">
            Scrap can be shared.
          </p>
        );
      }
      else if(status===4&&!showFullMessage)
      {
        return (
          <p className="scrap-share__actions-toggle-description">
            Scrap cannot be shared.
          </p>
        );
      }
      else {
        return (
          <p className="scrap-share__actions-toggle-description">
            Scrap can be shared and is found in public collections.
          </p>
        );
      }
    }

    
  };
  if (settings) {
    return (
      <div
        className={`scrap-share-wrapper${
          status === 2 || status === 3 ? ` scrap-share-wrapper__enabled` : ''
        }${loading ? ' scrap-share-wrapper__processing' : ''}`}
      >
        <div className={`scrap-share__actions-wrapper`}>
          <div className="scrap-share__actions">
            <div className="scrap-share__toggle-wrapper">
              {type==="Collection" &&  
              <div className="scrap-share__actions-toggle">
                <span className="scrap-share__actions-toggle-title">
                  {type === 'Collection' && 'Enable Re-Sharing'}
                </span>
                <label className="scrap-share__actions-toggle-switch">
                  <input
                    type="checkbox"
                    checked={enableShareStatus}
                    onChange={() => {
                      type === 'Collection' && collectionEnableStatus();
                    }}
                    disabled={loading}
                  />
                  <span className={`slider round ${status ===3 && 'slider-title__lock' }`}></span>
                  {enableShareStatus ? (
                    <span className={`slider-title ${status ===3 && 'slider-title__lock-title' }`}>on</span>
                  ) : (
                    <span className="slider-title slider-title-off">off</span>
                  )}
                </label>
              </div>
             
              }
              {toggleMessage()}
              <div className="scrap-share__actions-toggle">
                <span className="scrap-share__actions-toggle-title">
                  {/* {type ==='Collection' && status === 3 ? "Public" :  type ==='Collection' && status === 1  && 'Private' } */}
                  {type ==='Collection' &&  (status === 1 || status===2 || !status ||status === 3) ? "Public" : 'Enable Re-Sharing'}


                  {/* { status === 3
                    ? 'Public'
                    : 
                    (status === 1 || status === 2) &&
                      'Private'} */}
                </span>
                <label className="scrap-share__actions-toggle-switch">
                  <input
                    type="checkbox"
                    checked={type ==="Collection" ? status === 3 : (status === 3 || status===2)}
                    onChange={() => {
                     
                      
                      // type === "Collection" ?  handleEdit({ state: status === 1  ? 3 : 1 }) :  handleEdit({ state: status === 2 || status === 3 ? 1 : 2 })
                      if (type === 'Collection') {
                      
                        if (status === 1 || status === 2 || !status) {
                          setEditVisibility && setEditVisibility(3);
                          setEnableShareStatus(true);
                        } else {
                          setEditVisibility &&  setEditVisibility(1);
                          setEnableShareStatus(true);
                        }
                      }else{
                        handleEdit({ state: toggleStatus(status) });
                        if (status === 1 || status === 2 || status ===4) {
                          setEditVisibility && setEditVisibility(3);
                        } else {
                          setEditVisibility &&  setEditVisibility(4);
                         
                        }
                       
                      }
                    }}
                    disabled={loading}
                  />
                  <span className="slider round"></span>
                  { type ==="Collection" ?  status === 3 ? (
                    <span className="slider-title">on</span>
                  ) : <span className="slider-title slider-title-off">off</span>
                    :  status === 2 || status === 3 ? (
                      <span className="slider-title">on</span>
                    ) : (
                      <span className="slider-title slider-title-off">off</span>
                    )}
                  {/* {status === 3 && type === "Collection" ? (
                    <span className="slider-title">on</span>
                  ) : status === 2 ?  (
                    <span className="slider-title">on</span> 
                  ): <span className="slider-title slider-title-off">off</span>} */}
                </label>
              </div>
            </div>
            <ReturnShareIcon
              scrapState={
                type === 'Collection'
                  ? status
                  : alwaysShowPublic
                    ? 3
                    : !alwaysShowPublic && (status === 2 || status === 3)
                      ? 2
                      : 1
              }
              enableShareStatus={enableShareStatus}
              itemType={type.toLowerCase()}
              className="create-scrap__footer-info--status"
              type={type}
            />
          </div>

          {/* Remove below code to show the copy section */}

          {/* {(status === 2 || status === 3) && (type !=="Collection")  &&(
            <div className="scrap-share__copy-section">
              <button
                className={`scrap-share__actions-copy ${
                  processing ? 'scrap-share__disabled-button' : ''
                }`}
                onClick={(e) => handleCopy()}
                disabled={processing}
              >
                <CopyIcon /> Copy Link
                {copied && (
                  <div className="scrap-share__copied">
                    <Icon iconClass="check-square" size="big" />
                    Link copied to clipboard
                  </div>
                )}
              </button>
              {onGenerateLink &&
                (processing ? (
                  <span className="scrap-share__actions-generate">
                    Generating New Link{' '}
                    <FontAwesomeIcon icon={faSpinnerIcon} spin size="2x" />
                  </span>
                ) : (
                  <span
                    className="scrap-share__actions-generate"
                    onClick={generateProceedHandler}
                  >
                    Generate New Link
                  </span>
                ))}
            </div>
          )} */}

          {statusMessage()}
        </div>
      </div>
    );
  }
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`share-settings ${loading ? 'loading' : ''}`}
    >
      <span
        role="button"
        className={`${'action-item'} ${
          ['detailSlideOut', 'collectionEdit', 'collectionPreview'].includes(
            view
          ) &&
          status === 1 &&
          user?.userName !== cardOwner &&
          'disabled'
        }`}
        title={toolTipTitle}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          handleShareScrap();
          const el = e.target as HTMLElement;
          el.closest('.card-base')?.classList?.add('share-active');
          setTimeout(
            () => el.closest('.card-base')?.classList?.remove('share-active'),
            1500
          );
          e.stopPropagation();
        }}
      >
        <ShareIcon />
      </span>
      {copied && (
        <div className="scrap-share__copied">
          <Icon iconClass="check-square" size="big" />
          Link copied to clipboard
        </div>
      )}
    </div>
  );
};

export default ScrapShare;
