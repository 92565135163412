import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewPopup from '../../components/Global/NewPopup';
import {
  setDeleteScrapPopup,
  setNewScrapPopup,
  setScrapImagesPopup,
  setWarningPopup,
} from '../../redux/action/utils';
import { ReduxStateType } from '../../redux/store';

import { NewScrapPopupInputsActions, NewScrapPopupLinkInput } from '../../components/NewScrapPopup';
import { utilsTypes } from '../../types/utils';
import ScrapAddNotesGroup from '../../components/Global/InputsGroup/ScrapAddNotesGroup';
import AddTagsHelper from '../../components/Global/AddTags';
import { collectionType } from '../../types/collections';
import { tagType } from '../../types/tags';
import ScrapShare from '../../components/ScrapDetails/components/ScrapShare';
import { ScrapImagesUploader } from '../../components/ScrapImagesPopup';
import {
  ScrapSavedFiles,
  ScrapSavedGallery,
} from '../../components/ScrapSaved';
import { parseImagesMeta } from '../../helpers/parseImagesMeta';
import { RequestStatusObjectType } from '../../types/requestStatusType';
import {
  convertHEICImage,
  isEmpty,
  parseStringifiedJson,
  returnFileType,
  returnPrice,
  waitBeforeExecute,
} from '../../helpers';
import config, { charLimits } from '../../helpers/config';
import { ErrorMessage } from '../../components/Global/Error/Index';
import { useApolloClient, useMutation } from '@apollo/client';
import InvisibleRecaptcha from '../../components/Global/InvisibleRecaptcha';
import ReCAPTCHA from 'react-google-recaptcha';
import ReloadContext from '../../helpers/contexts/reloadContext';
import { FileDataType, FileItemStateType, existingScrapType } from './types';
import submitHandler from './helpers/scrapSaveSubmitHandler';
import WebContent from '../../components/Global/WebContent';
import ViewAllHighlights from '../../components/Global/Highlights/uiComponents/ViewAllHighlights';
import AddCollectionsHelper from '../../components/Global/AddCollections';
import parseCollectionsFromIds from '../../helpers/parseCollectionsFromIds';
import { returnRemovedAndNewCollections } from './helpers/returnRemovedAndNew';
import HighlightsItem from '../../components/Global/Highlights/uiComponents/HighlightsItem';
import { InputFieldType } from '../../types/inputFieldType';
import DELETE_SCRAP_ANNOTATION from '../data/deleteScrapAnnotation';
import UPSERT_SCRAP_ANNOTATION from '../data/upsertScrapAnnotation';
import { FilePinIcon, TrashOutlineIcon } from '../../components/Global/icons';
import { setOneScrapAction } from '../../redux/action/onScrapActions';
import { scrapType } from '../../types/scrapType';
import Button from '../../components/uiComponents/Button';
import { DeleteScrap } from "../data/deleteScrap";
import GetScrapImages from '../data/getScrapImages';
import createScrapHandler from './helpers/createScrapHandler';

interface InputsConfigType<T> {
  show: boolean;
  data?: T;
}



interface Proptypes {
  existingScrap?: existingScrapType;
}

const NewScrapPopupContainer = ({ existingScrap }: Proptypes) => {
  const [deleteAnnotation] = useMutation(DELETE_SCRAP_ANNOTATION());
  const [upsertScrapAnnotation] = useMutation(UPSERT_SCRAP_ANNOTATION())
  const { data: scrap, isLoading, imagesData, onSaved } = existingScrap || {};
  
  const scrapImages = (scrap?.images || []).filter(data => !!data.file_path)
  const client = useApolloClient();
  const dispatch = useDispatch();
  const storeCollections = useSelector((state: ReduxStateType) => state.collections);
  const newScrapPopup = useSelector(
    (state: ReduxStateType) => state.utils.newScrapPopup
  );
  const [showViewAll, setShowViewAll] = useState(true);
  const { setReload } = useContext(ReloadContext);
  const [deleteScrap] = useMutation(DeleteScrap);

  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const embedRef = useRef<HTMLDivElement>();

  const [status, setStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  const [currentType, setCurrentType] = useState<
    utilsTypes['newScrapPopup']['type']
  >(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [linkInputConfig, setLinkInputConfig] = useState<InputsConfigType<any>>({
    show: false,
    data: null,
  })
  const [notesInputConfig, setNotesInputConfig] = useState<InputsConfigType<InputFieldType<string>>>({
    show: false,
    data: {
      value: null,
      gotChanged: false,
    }
  })

  // makes sure that when capture new webpage, the image replace is disabled
  const [ newCapture, setNewCapture ] = useState(false);
  const [ processingWebCapture, setProcessingWebCapture ] = useState(false);

  const [notesKey, setNotesKey] = useState((Math.random() * 1000).toString());
  const [title, setTitle] = useState<InputFieldType<string>>({
    value: null,
    gotChanged: false,
  });
  const [state, setState] = useState<InputFieldType<number>>({
    value: 2,
    gotChanged: false,
  });
  const [collections, setCollections] = useState<
    InputFieldType<collectionType[]>
  >({
    value: [],
    gotChanged: false,
  });
  const [tags, setTags] = useState<InputFieldType<tagType[]>>({
    value: [],
    gotChanged: false,
  });
  const [filesInputConfig, setFilesInputConfig] = useState<InputsConfigType<InputFieldType<FileDataType[]>>>({
    show: false,
    data: {
      value: [],
      gotChanged: false,
    }
  })
  const [imagesInputConfig, setImagesInputConfig] = useState<InputsConfigType<InputFieldType<FileItemStateType[]>>>({
    show: false,
    data: {
      value: [],
      gotChanged: false,
    }
  })
  const [editPrice, setEditPrice] = useState<InputFieldType<number>>({
    value: scrap?.price,
    gotChanged: false,
  });
 
  const [editMode,setEditMode]=useState(true);

  let containsEmbed = false;
  let containsMetaData = false;
  if (scrap) {
    if (scrap.service) containsEmbed = true;
    if (scrap.meta_desc || scrap.meta_title || linkInputConfig?.data) containsMetaData = true;
  }
  const containsWebContent = containsEmbed || containsMetaData;

  const [show, closeHandler] = [
    existingScrap ? existingScrap?.show : !!newScrapPopup,
    () =>
      existingScrap
        ? existingScrap.onClose()
        : dispatch(setNewScrapPopup(null)),
  ];
  // variable to open the file system for images or files
  // as default when user creates new image or file scrap
  const defaultOpen: utilsTypes['newScrapPopup']['type'] = useMemo(() => {
    if(existingScrap || !show) return null;
    return newScrapPopup?.type
  },[show, existingScrap]);

  let disableSubmit = false;
  if (
    !filesInputConfig?.data?.value.length &&
    !imagesInputConfig?.data?.value.length &&
    !(title.value || '').trim() &&
    isEmpty(notesInputConfig?.data?.value, true) &&
    !linkInputConfig?.data
  ) {
    disableSubmit = true;
  }
  
  
  disableSubmit = (disableSubmit && !containsWebContent) || status.status === 'processing';

  // Handler function which gets called when user clicks on cancel button in popup
  const cancelHandler = async () => {
    closeHandler();
    await removeWebScrap();
  };

  // Handler function to reset form to initial state
  const resetFormHandler = (isResetAll = false, defaultType?: utilsTypes['newScrapPopup']['type']) => {
    if (isResetAll) {
      setTitle({
        gotChanged: false,
        value: '',
      });
      setLinkInputConfig({
        show: !existingScrap && defaultType === 'Web',
        data: null,
      })
      setNotesInputConfig({
        show: !existingScrap && defaultType === 'Note',
        data: {
          gotChanged: false,
          value: '',
        } 
      });
      setState({
        gotChanged: false,
        value: 2,
      });
      setCollections({
        value: [],
        gotChanged: false,
      });
      setImagesInputConfig({
        show: false,
        data: {
          gotChanged: false,
          value: [],
        }
      });
      setFilesInputConfig({
        show: false,
        data: {
          gotChanged: false,
          value: [],
        }
      })
      setTags({
        value: [],
        gotChanged: false,
      });
      setEditPrice({
        value: scrap?.price,
        gotChanged: false,
      });
    }
    setStatus({ status: 'not-started' });
  };

  const toggleErrorMessage = (message: string) => {
    setStatus({
      status: 'error',
      message
    })
    setTimeout(() => {
      setStatus(old => {
        if(old.status !== 'processing') {
          return {
            status: 'not-started'
          }
        }
        return old;
      })
    },3000)
  }

  // Handler function which gets called when file is selected from file system by user
  const uploadFileChangeHandler = async (
    from: 'images' | 'documents',
    files: FileList,
  ) => {
    setStatus({
      status: 'processing',
      processingLabel: 'Processing'
    })
    // Convert files in to array and store it in filesArr variable
    const filesArr: File[] = Array.from(files);
    if (from === 'images') {
      // validate for total number of images allowed in a scrap
      if(config.gallery_images_count_limit < filesArr.length) {
        toggleErrorMessage(`Images limit exceeded, only ${config.gallery_images_count_limit} images allowed.`)
        return
      }
      // Check image size constraints
      if ([...files].some((file) => file.size > config.image_size_limit)) {
        return setStatus({
          status: 'error',
          message: `Size of each file should be less than ${
            config.image_size_limit / 1000000
          } MB!`,
        });
      }
      const heicIndexes: number[] = [];
      for(let i=0; i<filesArr.length; i++) {
        const file = filesArr[i];

        // if the file name includes .heic extension
        const isHeicExtension = file.name.includes('.heic');

        if(file.type === 'image/heic' || isHeicExtension) {
          const newFile = await convertHEICImage(file);
          if(!newFile) return setStatus({
            status: 'error',
            message: 'Processing image failed, as it is an unsupported file type.',
          });
          filesArr[i] = newFile;
          heicIndexes.push(i);
        }

        // Windows is not giving the HEIC file type.
        // so if you have figured it out from the extension, skip it.
        if(!isHeicExtension && (!file.type || file.type?.trim() === '')) {
          setStatus({
            status: 'error',
            message: 'Unsupported file type.'
          });
          return;
        }
      }

      // Store the files in images list state
      setImagesInputConfig({
        show: true,
        data: {
          value: filesArr.map((file, i) => ({
            fileUrl: URL.createObjectURL(file), // generated file url from the file object
            key: 'image-' + Math.ceil(Math.random() * 10000),
            filename: file.name,
            description: '',
            fileType: null,
            mimeType: file.type,
            fileSize: file.size,
            order: (i + 1) * 100,
            is_heic: heicIndexes.length && heicIndexes.findIndex(item => item === i) >= 0 ? true : false,
          })),
          gotChanged: true,
        }
      });
    } else if (from === 'documents') {
      // Check document file size constraints
      if ([...files].some((file) => file.size > config.pdf_size_limit)) {
        return setStatus({
          status: 'error',
          message: `Size of file should be less than ${
            config.pdf_size_limit / 1000000
          } MB!`,
        });
      }

      // Store the file in fileData
      setFilesInputConfig(old => ({
        ...old,
        show: true,
        data: {
          value: [
            ...(old?.data?.value || []),
            ...filesArr.map((item) => ({
              key: 'file-' + Math.ceil(Math.random() * 10000),
              fileUrl: URL.createObjectURL(item), // generated file url from the file object
              fileType: returnFileType(item.type),
              filename: item.name,
            }))
          ],
          gotChanged: true
          
        }
      }))
    }

    setStatus({ status: 'not-started' });
  };

  //  Handler function which gets called when user click on edit button on image in popup
  const imagesEditClickHandler = () => {
    // Dispatch action in redux to show global scrap images popup
    dispatch(
      setScrapImagesPopup({
        dontCreate: true,
        defaultEdit: true,
        isGridMode: true,
        scrap: {
          images: imagesInputConfig?.data?.value.map((item, i) => ({
            desc: item.description,
            file_path: item.fileUrl,
            id: item.id,
            order: item.order,
            file_name: item.filename,
            mimeType: item.mimeType,
            file_config: JSON.stringify(JSON.stringify({
              file_meta: {
                is_heic: item.is_heic
              }
            }))
          }))
        },
        onDone: (images_meta) => {
          // Save the images of files list state when scrap images closes
          // after successful submit in global scrap images popup
          let parsedImages = parseImagesMeta(images_meta);
          setImagesInputConfig({
            show: true,
            data: {
              value: parsedImages.map((item, i) => ({
                description: item.description,
                filename: item.filename,
                fileUrl: item.image,
                key: item.key,
                id: item.id,
                fileSize: item.fileSize,
                order: +item.order,
                mimeType: item.mimeType,
                is_heic: item.is_heic,
              })),
              gotChanged: true,
            }
          })
        },
      })
    );
  };

  const renderDistinctInputs = (onlyRender?: 'images' | 'files') => {
    let fileRenderer, imagesRenderer;
    
    // Show the file uploader if current type is file
    // Or show document file preview if document file is already selected by user
    if (onlyRender !== 'images' && (filesInputConfig?.show || filesInputConfig?.data?.value.length)) {
      let filesData = (showViewAll && scrap)
        ? filesInputConfig?.data?.value.slice(0, 5)
        : filesInputConfig?.data?.value
      fileRenderer = filesData.length ? (
        <>
          {filesData.map((item, i) => (
            <ScrapSavedFiles
              key={item.key}
              link={item.fileUrl}
              type="document"
              fileName={item.filename}
              fileType={item.fileType}
              onReplace={() => {
                setFilesInputConfig(old => ({
                  ...old,
                  data: {
                    value: old?.data.value.filter((fileItem, j) =>
                      fileItem.id ? item.id !== fileItem.id : i !== j
                    ),
                    gotChanged: true,
                  },
                }))
              }}
            />
          ))}
          {(showViewAll && scrap && filesInputConfig?.data?.value?.length > 5 )&& (
            <ViewAllHighlights
              highlightsCount={filesInputConfig?.data?.value?.length}
              label="View all files"
              showMore="all-files"
              setShowViewAll={setShowViewAll}
            />
          )}
          {filesInputConfig.show && (
            <ScrapImagesUploader
              onChange={uploadFileChangeHandler.bind(null, 'documents')}
              fileType=".pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.txt"
              text="Up to 20 MB"
              upLoadType="File"
              multiple
              notValidHuman
            >
              {(onClick) => (
                <Button
                  buttonType="outlined-with-background"
                  className="new-scrap-popup__inputs-actions-button"
                  onClick={onClick}
                  // disabled={disableCancel || spinnerConfig?.show}
                >
                  <FilePinIcon />
                  Files +
                </Button>
              )}
            </ScrapImagesUploader>
          )}
        </>
      ) : null
    }
    // Show the file uploader if current type is images
    // Or show images thumbnail images are already selected by user
    if (onlyRender !== 'files' && (imagesInputConfig.show || imagesInputConfig?.data?.value?.length)) {
      if (imagesInputConfig?.data?.value?.length) {
        let url, meta_desc, favicon, meta_title;
        if(scrap?.meta_desc || scrap?.meta_title || linkInputConfig?.data) {
          if(linkInputConfig?.data) {
            url = linkInputConfig?.data?.url;
            meta_desc = linkInputConfig?.data?.meta_desc;
            favicon = linkInputConfig?.data?.original_favicon;
            meta_title = linkInputConfig?.data?.meta_title
          } else {
            url = scrap.url;
            meta_desc = scrap.meta_desc;
            favicon = scrap?.favicon || scrap?.original_favicon;
            meta_title = scrap.meta_title
          }
        }
        imagesRenderer = (
          <ScrapSavedGallery
            imageList={imagesInputConfig?.data?.value.map((item) => ({
              description: item.description,
              desc: item.description,
              image: item.fileUrl,
              is_heic: item.is_heic,
            }))}
            onReplaceClick={newCapture ? null : () => {
              setImagesInputConfig({
                show: false,
                data: {
                  gotChanged: true,
                  value: [],
                }
              });
            }}
            onImageEdit={imagesEditClickHandler}
            onImageClick={imagesEditClickHandler}
            onMoreImagesClick={imagesEditClickHandler}
          >
            <WebContent
              url={url}
              description={meta_desc}
              favicon={favicon}
              price={editPrice.value}
              title={meta_title}
              showFullContent
              editMode={editMode}
              setEditMode={setEditMode}
              setEditPrice={setEditPrice}
              onRemove={linkInputConfig.data ? () => removeWebScrap() : null}
            />
          </ScrapSavedGallery>
        );
      } else imagesRenderer = null;
    }
    return (
      <>
        {imagesRenderer && (
          <div
            className={`new-scrap-popup__distinct-inputs new-scrap-popup__distinct-inputs--images ${processingWebCapture ? ' loading' : ''}`}>
            {imagesRenderer}
          </div>
        )}
        {fileRenderer && (
          <div className="new-scrap-popup__distinct-inputs new-scrap-popup__distinct-inputs--files">
            {fileRenderer}
          </div>
        )}
      </>
    );
  };

  async function removeWebScrap() {
    setProcessingWebCapture(true);
    try {
      if(existingScrap) {
        if(!linkInputConfig.data) return setProcessingWebCapture(false);
        
        const newScrapData = {
          id: scrap.id,
          url: null,
          meta_desc: null,
          original_favicon: null,
          price: null,
          meta_title: null,
          meta: null,
          service: null
        }
        const data = await  createScrapHandler(newScrapData, client)
        setLinkInputConfig({
          show: false,
          data: null
        })
        onSaved({
          ...newScrapData
        }, true);
        dispatch(
          setOneScrapAction({
            type: 'edit',
            scrap: {
              ...newScrapData,
            },
          })
        );
      } else {
        const deleteAction = await deleteScrap({
          variables: {
            scrap_id: linkInputConfig.data?.id,
          },
        });
    
        // Only update state if the delete action is successful
        // if there is an error notify the user
        if (deleteAction.data?.deleteScrap?.http_code === 200) {
          setLinkInputConfig({
            show: true,
            data: null,
          });
    
          setImagesInputConfig({
            show: false,
            data: {
              value: [],
              gotChanged: false,
            },
          });
        } else {
          dispatch(
            setWarningPopup({
              warningMessage:
                'Something went wrong while remove the web capture! Please try again later.',
              header: 'Error',
              cancel: {
                label: 'Cancel',
                hide: true,
              },
              submit: {
                label: 'Ok',
              },
            })
          );
        }
      }
    } catch (error) {
      // Intentionally left blank
    }
    setProcessingWebCapture(false);
  }

  const saveAnnotationHandler = async (variables) => {
    upsertScrapAnnotation({ variables: { ...variables, scrap_id: scrap.id } });
  };
  const deleteAnnotationHandler = async (annotation_id: string) => {
    deleteAnnotation({
      variables: {
        scrap_id: scrap.id,
        annotation_id,
      },
    });
    const data = {
      id: scrap.id,
      annotations: scrap.annotations
        .filter((item) => item.id !== annotation_id)
    }
    onSaved(data);
  };

  const renderAllAnnotations = () => {
    if(!scrap?.annotations?.length) return null;
    return (
      <div className="new-scrap-popup__all-annotations">
        {scrap?.annotations?.map((item, key) => {
          return (
            <HighlightsItem
              annotation={item}
              key={item.id}
              showAll
              onEdit={async (comment) => {
                await saveAnnotationHandler({
                  id: item.id,
                  comment,
                });
                const allAnnotations = [...scrap.annotations];
                allAnnotations[key].comment = comment;
                onSaved({id: scrap.id,annotations: allAnnotations})
              }}
              onDelete={deleteAnnotationHandler.bind(null, item.id)}
            />
          );
        })}
      </div>
    );
  };
  const linkFetchHandler = async (data) => {
    setProcessingWebCapture(true);
    const scrap_id = data.id || scrap?.id
    setLinkInputConfig((old) => ({
      ...old,
      data,
    }));

    setNewCapture(!existingScrap);
    if(!isNaN(returnPrice(data.price))) {
      setEditPrice({
        gotChanged: false,
        value: returnPrice(data.price)
      })
    }
    if(existingScrap) {
      if(data.thumbnail) {
        const scrapData = {...data};
        const totalImages = imagesInputConfig.data.value.length + 1
        delete scrapData.thumbnail;
        if(!scrap_id) return;
        let times = 0
        let fetchedImages: scrapType['images'] = null
        while(times <= 4) {
          times += 1;
          const { data } = await client.query({
            query: GetScrapImages(),
            variables: {
              scrap_id,
              first: totalImages,
              page: 1,
              skip_first: 0,
            }
          })
          const images: scrapType['images'] = data?.getScrapImages?.data || [];
          const filteredimages = images.filter(image => !!image.file_path);
          if(filteredimages.length >= totalImages) {
            fetchedImages = filteredimages.sort((a, b) => a.order - b.order);
            break;
          }
          await waitBeforeExecute(() => {}, 2000);
          
        }
        onSaved({
          ...scrapData,
          id: scrap_id,
          images: fetchedImages,
          images_count: totalImages,
        }, true);
        let imageData = fetchedImages.map(function (item) {
          delete item.file_origin;
          delete item.title;
          // @ts-ignore
          delete item.scrap_id;
          return item;
        });
        dispatch(
          setOneScrapAction({
            type: 'edit',
            scrap: {
              ...scrapData,
              id: +scrap.id,
              images: imageData,
              images_count: totalImages,
            },
          })
        );
        setImagesInputConfig({
          show: true,
          data: {
            value: fetchedImages.map((image) => {
              
              let parsedConfig = null;
              if(image.file_config) {
                try {
                  parsedConfig = JSON.parse(image.file_config)
                } catch (error) {
                  // 
                }
              }
              const mimeType = parsedConfig?.original?.mime_type || '';            
              return {
                id: image.id,
                fileUrl: image.file_path,
                key: 'image-' + Math.ceil(Math.random() * 10000),
                filename: image.file_name,
                description: image.desc,
                fileType: +image.file_type,
                fileSize: +image.file_size,
                order: image.order,
                mimeType,
                is_heic: false,
              }
            }),
            gotChanged: false,
          }
        })
      } else {
        onSaved({
          ...data,
          id: scrap_id,
        }, true);
        dispatch(
          setOneScrapAction({
            type: 'edit',
            scrap: {
              ...data,
              id: scrap_id,
            },
          })
        ); 
      }
    } else if(data.thumbnail) {
      setImagesInputConfig({
        show: true,
        data: {
          // @ts-ignore
          value: [{
            fileUrl: data.thumbnail,
          }],
          gotChanged: false,
        }
      })
    }
    setProcessingWebCapture(false);
  }


  const render = () => {
    if (existingScrap && !existingScrap.data) {
      return null;
    }
    let url, meta_desc, favicon, meta_title;
    if(scrap?.meta_desc || scrap?.meta_title || linkInputConfig?.data) {
      if(linkInputConfig?.data) {
        url = linkInputConfig?.data?.url;
        meta_desc = linkInputConfig?.data?.meta_desc;
        favicon = linkInputConfig?.data?.original_favicon;
        meta_title = linkInputConfig?.data?.meta_title
      } else {
        url = scrap.url;
        meta_desc = scrap.meta_desc;
        favicon = scrap?.favicon || scrap?.original_favicon;
        meta_title = scrap.meta_title
        
      }
    }
    
    return (
      <Fragment>
        {linkInputConfig.show && !linkInputConfig.data && (
          <NewScrapPopupLinkInput
            scrapId={scrap?.id}
            onDataFetched={linkFetchHandler}
          />
        )}
        {renderDistinctInputs('images')}

        
        {!imagesInputConfig?.data.value.length &&
          (scrap?.meta_desc || scrap?.meta_title || linkInputConfig?.data) && (
          <WebContent
            url={url}
            description={meta_desc}
            favicon={favicon}
            price={editPrice.value}
            title={meta_title}
            showFullContent
            editMode={editMode}
            setEditMode={setEditMode}
            setEditPrice={setEditPrice}
            onRemove={linkInputConfig.data ? () => removeWebScrap() : null}
          />
        )}
        
        {renderAllAnnotations()}
        <ScrapAddNotesGroup
          charLimit={charLimits.note}
          onDescriptionClose={() => {
            dispatch(setWarningPopup({
              warningMessage: 'Are you sure you want to delete the notes?',
              header: 'Delete',
              cancel: {
                label: 'Cancel'
              },
              submit: {
                label: 'Delete',
                cb: () => {
                  setNotesInputConfig({
                    show: false,
                    data: {
                      gotChanged: false,
                      value: null,
                    }
                  })
                }
              }
            }))
            
          }}
          key={notesKey}
          isDefaultOpened
          onDescriptionChange={
            notesInputConfig?.show
              ? (desc) => {
                let gotChanged = true;
                if (
                  scrap &&
                  (scrap.desc === desc ||
                    (isEmpty(scrap.desc, true) && isEmpty(desc, true)))
                ) {
                  gotChanged = false;
                }
                if (!scrap && isEmpty(desc, true)) {
                  gotChanged = false;
                }
                setNotesInputConfig((old) => ({
                  ...old,
                  data: { value: desc, gotChanged },
                }));
              }
              : null
          }
          description={notesInputConfig?.data?.value}
          title={title.value}
          onTitleChange={(value) => {
            let gotChanged = true;
            if (scrap && (scrap?.title || '').trim() === (value || '').trim()) {
              gotChanged = false;
            } else if (!scrap && !value.trim()) gotChanged = false;
            setTitle({ value, gotChanged });
          }}
        />
        <NewScrapPopupInputsActions
          onError={toggleErrorMessage}
          defaultOpen={defaultOpen}
          onLinkClick={
            !linkInputConfig.show &&
            (!existingScrap || (existingScrap && !(scrap.meta_desc || scrap.meta_title))) &&
            setLinkInputConfig.bind(null, {
              show: true,
            })
          }
          onFilesChange={(!filesInputConfig?.show || !filesInputConfig.data.value.length) ? uploadFileChangeHandler.bind(null, 'documents') : null}
          onImagesChange={
            !imagesInputConfig.show || !imagesInputConfig.data.value.length
              ? uploadFileChangeHandler.bind(null, 'images')
              : null
          }
          onNotesClick={
            !notesInputConfig.show
              ? () => {
                setNotesInputConfig((old) => ({
                  ...old,
                  show: true,
                }));
              }
              : null
          }
        />
        {renderDistinctInputs('files')}
        <AddCollectionsHelper
          selectedCollections={collections.value}
          onSave={({ collections }) => {
            if (!scrap) {
              setCollections({
                value: collections,
                gotChanged: true,
              });
            } else {
              const {
                newCollections,
                removedCollections,
              } = returnRemovedAndNewCollections(
                scrap?.collections ?? [],
                collections
              );
              if (newCollections.length || removedCollections.length) {
                const oldCollections = parseCollectionsFromIds(
                  storeCollections,
                  scrap.collections ?? []
                );
                const updatedCollections = oldCollections.filter(
                  (collection) =>
                    removedCollections.findIndex(
                      (item) => item.id === collection.id
                    ) < 0
                );
                if (newCollections.length)
                  updatedCollections.push(
                    ...(newCollections as collectionType[])
                  );
                setCollections({
                  value: updatedCollections,
                  gotChanged: true,
                });
              }
            }
          }}
        />
        <AddTagsHelper
          onSave={(scrap) => {
            setTags({
              value: scrap.tags || [],
              gotChanged: true,
            });
          }}
          selectedTags={tags.value}
          disableItemClick
        />
        <ScrapShare
          settings
          status={state.value}
          type="Scrap"
          handleEdit={async (data) => {
            setState({ value: data.state || 1, gotChanged: true });
            return null;
          }}
          enableShareStatus={state.value && state.value > 1}
        />
        {status?.status === 'error' && (
          <ErrorMessage>{status.message}</ErrorMessage>
        )}
        <InvisibleRecaptcha inputRef={reCaptchaRef} />
      </Fragment>
    );
  };

  useEffect(()=>{
    if(currentType){
      setNotesKey((Math.random() * 1000).toString());
    }
  },[currentType]);
  useEffect(() => {
    resetFormHandler(true, newScrapPopup?.type);
    setCurrentType(newScrapPopup ? newScrapPopup.type : 'Note');
  }, [show]);
  useEffect(() => {
    const element = embedRef?.current;
    if (element && scrap?.service === 'youtube') {
      element.style.height = (element.clientWidth * 360) / 640 + 'px';
    }
  }, [embedRef, scrap]);

  useEffect(() => {
    if(imagesData) {
      setImagesInputConfig({
        show: true,
        data: {
          value: scrapImages.map((image) => {
            let mimeType = '';
            if(image.file_config) {
              try {
                const parsedConfig = JSON.parse(image.file_config)
                mimeType = parsedConfig?.original?.mime_type
              } catch (error) {
                // 
              }
            }
            return {
              id: image.id,
              fileUrl: image.file_path,
              key: 'image-' + Math.ceil(Math.random() * 10000),
              filename: image.file_name,
              description: image.desc,
              fileType: +image.file_type,
              fileSize: +image.file_size,
              order: image.order,
              mimeType: mimeType,
              is_heic: false,
            }
          }),
          gotChanged: false,
        }
      })
    }
  }, [imagesData])
  useEffect(() => {
    if (scrap) {
      // Fill the scrap popup with existing scrap data
      if (scrap.title) setTitle({ value: scrap.title, gotChanged: false });
      
      if (scrap.desc) setNotesInputConfig({
        show: true,
        data: { value: scrap.desc, gotChanged: false }
      });
      
      setNotesKey((Math.random() * 1000).toString());
      
      if (scrap.tags){
        setTags({
          value: scrap.tags.map((item) => ({
            ...item
          })),
          gotChanged: false,
        });
      }
      
      if (scrapImages?.length) {
        setImagesInputConfig({
          show: true,
          data: {
            value: scrapImages.map((image) => {
              const parsedConfig = parseStringifiedJson(image.file_config);
              return {
                id: image.id,
                fileUrl: image.file_path,
                key: 'image-' + Math.ceil(Math.random() * 10000),
                filename: image.file_name,
                description: image.desc,
                fileType: +image.file_type,
                fileSize: +image.file_size,
                order: image.order,
                mimeType: parsedConfig?.file_meta?.original?.mime_type,
                is_heic: parsedConfig?.file_meta?.is_heic,
              }
            }),
            gotChanged: false,

          }
        })
      }
      
      if (scrap.documents?.length) {
        setFilesInputConfig({
          show: true,
          data: {
            value: scrap.documents.map((item) => ({
              id: item.id,
              key: 'file-' + Math.ceil(Math.random() * 10000),
              fileUrl: item.file_path,
              fileType: +item.file_type,
              filename: item.file_name,
            })),
            gotChanged: false,
          }
        })
      }
      setState({
        value: scrap.state || 1,
        gotChanged: false,
      });
      const price = returnPrice(scrap.price);
      if(!isNaN(price)) {
        setEditPrice({
          value: price,
          gotChanged: false,
        })
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (scrap?.collections && !collections.value.length) 
      setCollections({
        value: parseCollectionsFromIds(storeCollections, scrap.collections),
        gotChanged: false,
      })  
  }, [storeCollections, isLoading])

  return (
    <NewPopup
      size='Small'
      className={`new-scrap-popup${
        status.status === 'processing' ? ' new-scrap-popup--saving' : ''
      }${
        containsWebContent
          ? ` new-scrap-popup--${containsEmbed ? 'embed' : 'web'}`
          : ''
      }`}
      controlled={{ show, setShow: closeHandler }}
      action={async () => {
        await removeWebScrap();
      }}
      header={{
        heading: existingScrap ? 'Edit Scrap' : 'New Scrap'
      }}
      footer={{
        submitLabel: existingScrap ? 'Save' : 'Create',
        onSubmit: submitHandler.bind(null, {
          meta: linkInputConfig?.data,
          setStatus,
          captchaRef: reCaptchaRef?.current,
          scrap,
          title,
          notes: notesInputConfig?.data,
          state,
          client,
          filesList: filesInputConfig?.data,
          imagesList: imagesInputConfig?.data,
          tags,
          collections,
          setReload,
          closeHandler,
          onSaved,
          editPrice,
          dispatch,
        }),
        leftRender: scrap?.id ? () => (
          <button
            onClick={() => {
              dispatch(
                setDeleteScrapPopup({
                  scrap: scrap,
                  onComplete: (scrap) => {
                    dispatch(setDeleteScrapPopup(null));
                    dispatch(
                      setOneScrapAction({
                        scrap: scrap as Partial<scrapType>,
                        type: 'delete',
                      })
                    );
                    closeHandler();
                  },
                })
              );
            }}
            className="new-scrap-popup__footer-delete"
          >
            <TrashOutlineIcon />
          </button>
        ) : null,
        disableSubmit,
        disableCancel: status.status === 'processing',
        onCancelClick: cancelHandler,
        spinnerConfig: {
          label: status?.processingLabel || 'Saving',
          show: status.status === 'processing',
        },
      }}
    >
      {render}
    </NewPopup>
  );
};

export default NewScrapPopupContainer;
