import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const LongArrowRight = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.89673 8.77553C10.187 9.07105 10.6618 9.07533 10.9573 8.78509L14.7755 5.03509C14.9191 4.89407 15 4.70125 15 4.5C15 4.29875 14.9191 4.10594 14.7755 3.96491L10.9573 0.214914C10.6618 -0.0753288 10.187 -0.0710506 9.89673 0.224469C9.60649 0.519989 9.61077 0.994844 9.90629 1.28509L12.416 3.75L0.75 3.75C0.335786 3.75 0 4.08579 0 4.5C0 4.91421 0.335786 5.25 0.75 5.25L12.416 5.25L9.90629 7.71491C9.61077 8.00516 9.60649 8.48001 9.89673 8.77553Z"
        fill="#EB6F19"
      />
    </svg>
  );
};

export default LongArrowRight;
