import React, { useEffect, useRef } from 'react';
import WebContentFavicon from './uiComponents/WebContentFavicon';
import WebContentUrl from './uiComponents/WebContentUrl';
import WebContentTitle from './uiComponents/WebContentTitle';
import WebContentDescription from './uiComponents/WebContentDescription';
import WebContentPrice from './uiComponents/WebContentPrice';
import './styles.css';
import { onClickType } from '../../ScrapCard';
import { isMobileDevice } from '../../../helpers';
import { PlusIcon } from '../icons';
interface Proptypes {
  favicon?: string;
  url?: string;
  price?: number;
  description?: string;
  title?: string;
  secondary?: boolean;
  onMoreClick?: (
    onClickType: onClickType,
    currentlyOpened?: boolean
  ) => boolean;
  showFullContent?: boolean;
  moreShow?: onClickType;
  setEditPrice?: (val) => void;
  editMode?: boolean;
  setEditMode?: (val) => void;
  clickedOn?: string;
  onRemove?: () => void;
}

const WebContent = (props: Proptypes) => {
  const {
    favicon,
    description,
    price,
    title,
    url,
    secondary = false,
    onMoreClick,
    showFullContent,
    moreShow,
    editMode,
    setEditMode,
    setEditPrice,
    clickedOn,
    onRemove,
  } = props;
  const contentScroll= useRef(null);
  useEffect(() => {

    if (clickedOn === 'ugc-description' && isMobileDevice()) {
      if(typeof(window)!=="undefined")
      {
        window.scroll({ top:window.pageYOffset+contentScroll?.current?.getBoundingClientRect().top-60 })
      }
    }
  }, [clickedOn]);
  if (!favicon && !description && !price && !title && !url) return null;
  if (secondary && !description && !price && !title) return null;
  const showLink = favicon || url;


  return (
    <div
      ref={contentScroll}
      className={`web-content ${secondary ? 'web-content__secondary' : ''}${
        showFullContent ? ' web-content--full' : ''
      }`}
    >
      {showLink && !secondary && (
        <div className="web-content__link">
          <div className='web-content__link-wrapper'>
            <WebContentFavicon favicon={favicon} />
            <WebContentUrl url={url} />
          </div>
          {onRemove && <button onClick={onRemove} className='web-content__link-remove'><PlusIcon /></button>}
          
        </div>
      )}
      <WebContentTitle title={title} url={url} secondary={secondary} fullTitle={showFullContent} />
      <WebContentDescription
        showFull={showFullContent}
        description={description}
        onMoreClick={onMoreClick}
        moreShow={moreShow}
      />
      <WebContentPrice
        price={price}
        withDivider
        editMode={editMode}
        setEditMode={setEditMode}
        setEditPrice={setEditPrice}
      />
    </div>
  );
};

export {
  WebContent as default,
  WebContentFavicon,
  WebContentUrl,
  WebContentTitle,
  WebContentDescription,
  WebContentPrice,
};
