import React from 'react';
import { useHistory } from 'react-router-dom';
import { slugifyString } from '../../../helpers';
import Collection from '../icons/Collection';
import NewTag from '../icons/NewTag';
import "./styles.css";
interface Type {
 id: string;
 title?: string;
 value?: string;
}
interface Proptypes {
  trim?: {
    after: number;
    onMoreClick: () => void
  }
  mode: 'tags' | 'collections';
  tags:Array<Type>;
  shareDetails?: boolean;
  clickable?: boolean;
}

const CardTypes = ({ mode, tags, shareDetails, clickable, trim }: Proptypes) => {
  const { push } = useHistory();
  const { after, onMoreClick } = trim || {};
  const showMore = !!trim && tags.length > after;
  const itemClickHandler=(item: Type)=>{
    if(!clickable) return;
    if(shareDetails) return null;
    if(mode === 'tags'){
      push(`/?tags=${item.value}`);
    }else if(mode === 'collections'){
      push(`/c/${slugifyString(item.title, false)}/${item.id}`);
    }  
  }

  const showTags = () => {
    let items = tags;
    if(trim) items = tags.slice(0, trim.after)
    return items.map((item, i) => {
      return (
        <span
          className={`card-tags__item${
            clickable ? ' card-tags__item--clickable' : ''
          }${shareDetails ? ' card-tags__share-details' : ''}`}
          key={i}
          
        > 
          <span onClick={() => itemClickHandler(item)} className='card-tags__item-text'>
            {mode === 'tags' && '#'}
            {item.title}
          </span>
          {showMore && items.length - 1 === i && <button className='card-tags__ellipsis' onClick={onMoreClick}>...</button>}
        </span>
      );
    });
  };
  if(!tags || tags.length ===0) return null;
  
  return (
    <div className="card-tags">
      <div className={`card-tags__icon`}>
        {mode ==='collections' ? <Collection /> : < NewTag/>}
      </div>
      <div className={`card-tags__${mode} card-tags__list`}>
        {showTags()}
        {/* {showMore && } */}
      </div>
    </div>
  );
};

export default CardTypes;
