import { getDomainWithoutSubdomain } from '../helpers';
import * as config from '../settings.json';

export const setToken = (access_token: string) => {
  try {
    if (config.sharedToken) {
      if (typeof window !== 'undefined' && access_token.length) {
        const domainName = getDomainWithoutSubdomain(window.location.href);
        // For localhost environment
        const prefix = domainName.split('.').length > 1 ? '.' : '';
        document.cookie =
          's_token=' + access_token + `; domain=${prefix}${domainName}; path=/`;
      }
    }
    if (access_token.length) {
      document.cookie = '_token_=' + access_token + `; path=/`;
    }
    document.cookie = '_token_=' + access_token + `; path=/`;
  } catch (e) {
    //
  }
};
