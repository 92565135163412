import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../redux/store';
import './styles.css';

const tabsArray = ['All', 'Scraps', 'Collections'] as const;
export type tabsUnionType = typeof tabsArray[number];
interface Proptypes {
  renderFeeds: () => React.ReactNode;
  renderProfileSideBar: () => React.ReactNode;
  activeTab: tabsUnionType;
  filterCounts: {
    collections_count: number;
    scraps_count: number;
    stories_count: number;
  };
  isViewingOwnProfile: boolean;
  userFullName: string;
  isPublic?: boolean;
}

const ProfilePreview = (props: Proptypes) => {
  const {
    renderProfileSideBar,
    renderFeeds,
    isViewingOwnProfile,
    userFullName,
    isPublic,
  } = props;
  const { width } = useSelector(
    (state: ReduxStateType) => state.utils.dimensions
  );
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef<HTMLDivElement>();

  
  // USEEFFECT TO CHECK FOR THE EVENT IF FILTER HAS STICKED ON THE SCREEN OR NOT
  useEffect(() => {
    const cachedRef = ref.current;
    if (cachedRef) {
      const observer = new IntersectionObserver(
        ([e]) => {
          setIsSticky(e.intersectionRatio < 1);
        },
        {
          rootMargin: `${isViewingOwnProfile ? '-33px' : '-42px'} 0px 0px 0px`, // ROOT MARGIN TO SET ACCORDINGLY BASED ON THE VALUE OF TOP APPLIED ON THE FILTER ELEMENT USING CSS, OTHERWISE, THIS OBSERVER WILL NOT WORK
          threshold: [1],
        }
      );

      observer.observe(cachedRef);

      return function () {
        observer.unobserve(cachedRef);
      };
    }
  }, [isViewingOwnProfile]);

  // USEEFFECT FOR CHANGING THE POSITION CSS OF MAIN NAVIGATION ON SCREEN RESIZE
  useEffect(() => {
    if (!isPublic) {
      const navEl = document.querySelector('.navigation') as HTMLElement;
      navEl.style.position = width <= 700 ? 'absolute' : 'fixed';
      return () => {
        // SET BACK THE STYLE POSITION TO FIXED ON PAGE UNMOUNT
        navEl.style.position = 'fixed';
        navEl.style.top = '0';
      };
    }
  }, [width, isViewingOwnProfile]);

  const userProfileReferenceName = userFullName
    ? `${userFullName}'s Profile`
    : '';
  return (
    <section
      className={`profile-preview${
        isViewingOwnProfile ? ' profile-preview__own-profile' : ''
      }`}
    >
      {!isViewingOwnProfile && isSticky && (
        <div className="profile-preview__user-reference-tab">
          <h3>{userProfileReferenceName}</h3>
        </div>
      )}
      <div className="profile-preview-container">
        {/* <div className="profile-sidebar-container">
          <aside className="">
            {renderProfileSideBar()}
            {countsMappers['All'] > 0 && (
          <div className="profile-preview__filters" ref={ref}>
            <div className="profile-sidebar__filters">
              {tabsArray.map((val) => (
                <p
                  key={val}
                  className={`profile-sidebar__filters__filter${
                    activeTab === val ? ' active' : ''
                  }`}
                  onClick={() => onTabSwitch(val)}
                >
                  <span>{val}</span>
                  <span className="profile-sidebar__filters__filter__count">
                    ({countsMappers[val]})
                  </span>
                </p>
              ))}
            </div>
          </div>
        )}
          </aside>
        </div> */}
        <div className="profile-preview-feeds-container">
          <div className="profile-preview__feeds">{renderFeeds()}</div>
        </div>
      </div>
    </section>
  );
};

export default ProfilePreview;
