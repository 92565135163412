import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { parseTryCatchError } from '../../../helpers/parseTryCatchError';
import { ExclamationIcon, UploadIcon } from '../../Global/icons';
import './styles.css';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../../Global/InvisibleRecaptcha';
import ValidateHuman from '../../../helpers/validators/isHuman';
import Dropzone from 'react-dropzone';
import config, { errorMessages } from '../../../helpers/config';

interface Proptypes {
  onChange?: (file: FileList) => Promise<void>;
  fileType?: string;
  text?: string;
  upLoadType?: string;
  multiple?: boolean;
  status?: string;
  errorMessage?: string;
  notValidHuman?: boolean;
  children?: (onClick: () => void) => React.ReactNode;
  openFileSytemByDefault?: boolean;
  onError?: (errorMessage: string) => void;
}

function isFileAllowed(filename: string, allowedExtensions: string[]) {
  const extension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(extension);
}

const ScrapImagesUploader = ({
  onChange,
  fileType,
  text,
  upLoadType,
  multiple = false,
  status,
  errorMessage,
  notValidHuman = false,
  children,
  openFileSytemByDefault,
  onError
}: Proptypes) => {
  const ref = useRef<HTMLDivElement>();
  const faSpinnerIcon = faSpinner as IconProp;
  const inputRef = useRef<HTMLInputElement>(null);
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const acceptedExtensions = (fileType ?? config.supported_images_accept_list)
    .split(',')
    .map((item) => item.replace('.', '').trim());

  async function fileChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    try {
      const isHuman =
        !notValidHuman && (await ValidateHuman(reCaptchaRef.current));
      let allFiles = (e.target as HTMLInputElement).files;

      if (allFiles.length && (isHuman || notValidHuman)) {
        const containsUnsupportedFile = Array.from(allFiles).some(
          (file) => !isFileAllowed(file.name, acceptedExtensions)
        );
        const dataTransfer = new DataTransfer();
        if (containsUnsupportedFile)
          throw new Error(errorMessages.unsupportedFiles);
        // Looping over filesList to check if there is any image 
        // of type heic and then convert it to jpg/png type
        for (let i = 0; i < allFiles.length; i++) {
          const file = allFiles[i];
          dataTransfer.items.add(file);
        }
        await onChange(dataTransfer.files);
      } else {
        throw new Error(errorMessages.unsupportedFiles);
      }
    } catch (error) {
      const errorMessage = parseTryCatchError(error);
      onError ? onError(errorMessage) : alert(parseTryCatchError(error));
    }
    e.target.value = null;
  }

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }, []);

  useEffect(() => {
    if (openFileSytemByDefault && inputRef.current) {
      inputRef.current.click();
    }
  }, [openFileSytemByDefault]);

  return (
    <>
      {status === 'processing' && errorMessage === null && (
        <div className="scrap-file-uploader__loading-overlay">
          <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
        </div>
      )}

      <Dropzone
        accept={fileType ?? config.supported_images_accept_list}
        onDrop={(acceptedFiles) =>
          fileChangeHandler(({
            target: { files: acceptedFiles },
          } as unknown) as React.ChangeEvent<HTMLInputElement>)
        }
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          if (children) {
            return (
              <>
                <input
                  {...getInputProps()}
                  multiple={multiple}
                  accept={
                    fileType ? fileType : config.supported_images_accept_list
                  }
                  onChange={fileChangeHandler}
                  ref={inputRef}
                  type="file"
                />
                {children(() => {
                  inputRef.current.click();
                })}
              </>
            );
          }
          return (
            <div className="scrap-images-uploader" ref={ref}>
              <div
                className={`scrap-images-uploader__inner-container ${
                  isDragActive
                    ? 'scrap-images-uploader__inner-container--active'
                    : ''
                }`}
                {...getRootProps({ isDragActive })}
              >
                <InvisibleRecaptcha inputRef={reCaptchaRef} />
                <input
                  {...getInputProps()}
                  multiple={multiple}
                  accept={
                    fileType ? fileType : config.supported_images_accept_list
                  }
                  onChange={fileChangeHandler}
                  ref={inputRef}
                  type="file"
                />
                <button
                  onClick={() => {
                    inputRef.current.click();
                  }}
                  className={`scrap-images-uploader__button ${
                    isDragActive ? 'scrap-images-uploader__button--active' : ''
                  }`}
                >
                  <UploadIcon className="scrap-images-uploader__icon" />
                  <span className="scrap-images-uploader__button-text">
                    Upload {upLoadType ? `${upLoadType}(s)` : 'Image(s)'}
                  </span>
                </button>
                <p className="scrap-images-uploader__support-text">
                  {text ? (
                    <p>
                      Up to <strong>20 MB</strong>. Formats allowed: pdf, xls,
                      doc, txt and ppt
                    </p>
                  ) : (
                    'Supported file types: jpg, jpeg, png, gif, bmp, webp and svg'
                  )}
                </p>
                {errorMessage && errorMessage.length > 0 && (
                  <p className="scrap-images-uploader__error">
                    <span className="scrap-images-uploader__error-icon">
                      <ExclamationIcon />
                    </span>
                    {errorMessage ||
                      "Sorry... we can't save this page right now. An error case has been logged for review."}
                  </p>
                )}
              </div>
            </div>
          );
        }}
      </Dropzone>
    </>
  );
};

export default ScrapImagesUploader;
