import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
interface Proptypes {
  title: string;
  url: string;
  secondary?: boolean;
  fullTitle?: boolean;
}
export default function WebContentTitle({
  title,
  url,
  secondary,
  fullTitle,
}: Proptypes) {
  if (!title) return null;
  return (
    <h3 className="web-content__title">
      {!secondary ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {fullTitle ? (
            title
          ) : (
            <LinesEllipsis
              text={title}
              maxLine="3"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          )}
        </a>
      ) : (
        title
      )}
    </h3>
  );
}
