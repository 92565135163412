import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { EntryActionContextProvider } from '../helpers/contexts/entryActionContext';
import {
  clearBatchEditScraps,
  changeBatchEditMode,
} from '../redux/action/batchEditAction';
import { Switch, Route, useLocation } from 'react-router-dom';
import './Home.css';
import Page404 from './page404';
import { utilsTypes } from '../types/utils';
import LoginSuccess from './Messages';
import { ScrapRouter, CollectionRouter } from './AppRoutes';
import ProfileWrapper from '../containers/Profile';
import ProfilePreviewWrapper from '../containers/ProfilePreview';
import BaseLayout from './BaseLayout';
import MyScrapsContainer from '../containers/MyScrapsContainer';
import OnboardSlider from './Global/OnboardSlider';
import { ReduxStateType } from '../redux/store';
import DeleteScrapPopupContainer from '../containers/DeleteScrapPopupContainer';
import WarningPopupContainer from '../containers/WarningPopupContainer';
import SearchResultsContainer from '../containers/SearchResultsContainer';
import CollectionSettingsPopupContainer from '../containers/CollectionSettingsPopupContainer';
import CollaboratedCollectionsContainer from '../containers/CollaboratedCollectionsContainer';
import NewHelpPage from './NewHelpPage/index';
import MyContactsContainer from '../containers/MyContactsContainer';
import ProfileSetup from './Global/ProfileSetup';
import { PROFILE_SETUP_PATH } from '../helpers/config';
import OneScrapPopupContainer from '../containers/OneScrapPopupContainer';
import CollectionCollaborationWrapper from '../containers/CollectionCollaborationWrapper';
import AllSharesContainer from '../containers/AllSharesContainer';
import GroupPageContainer from '../containers/GroupPageContainer';
import BookmarksContainer from '../containers/BookmarksContainer';
import NotificationList from './NotificationList';
import StatusPage from "./StatusPage";
import AI from "./AI";
interface propTypes {
  expanded: boolean;
  batchEditMode: boolean;
  scrapPopup: utilsTypes['scrapPopup'];
}

const Home = ({ batchEditMode, expanded, scrapPopup }: propTypes) => {


  const dispatch = useDispatch();
  
  const { pathname } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [withEntry, setWithEntry] = useState(false);
  const [showPublicWrapper, setShowPublicWrapper] = useState(false);
  let containerClass =
    'sl-container' +
    (batchEditMode ? ' batch-visible' : '') +
    (expanded ? '' : ' collapsed');

  useEffect(() => {
    // Disable batch mode whenever url changes
    dispatch(changeBatchEditMode(false));
    dispatch(clearBatchEditScraps());
  }, [pathname]);
  return (
    <BaseLayout showPublicWrapper={showPublicWrapper} withEntry={withEntry}>
      <EntryActionContextProvider>
        <Switch>
          <Route
            exact
            path="/login/success"
            render={() => {
              return <LoginSuccess />;
            }}
          />
          <Route
            exact={true}
            path="/search"
            // component={SearchResultsContainer}
            render={(props) => <SearchResultsContainer />}
            // render={(props) => (
            //   <GlobalSearchWrapper {...props} className={containerClass} />
            // )}
          />
          <Route
            path={['/collections', '/c']}
            render={(props) => (
              <CollectionRouter containerClass={containerClass} {...props} />
            )}
          />
          <Route
            path={['/shares']}
            render={(props) => (
              <AllSharesContainer/>
            )}
            exact
          />
          <Route
            path={['/shares/:gid']}
            render={(props) => (
              <GroupPageContainer/>
            )}
          />
        
          <Route
            path="/collaborations"
            render={(props) => <CollaboratedCollectionsContainer />}
          />
          
          <Route path="/help" render={(props) => <NewHelpPage />} />

          <Route path="/ai-tagging" render={(props) => <AI />} />

          <Route
            path="/profile"
            render={(props) => <ProfileWrapper {...props} />}
          />
          <Route
            path="/uncategorized"
            render={() => <MyScrapsContainer uncategorized />}
          />
          <Route
            path="/saved"
            render={() => <BookmarksContainer />}
          />
          <Route
            path="/contacts"
            render={()=><MyContactsContainer/>}/>
          <Route
            path="/status"
            render={()=><StatusPage />}/>
          <Route
            path="/not-found"
            exact={true}
            render={() => {
              setWithEntry(false);
              return <Page404 />;
            }}
          />
          <Route
            exact
            path={PROFILE_SETUP_PATH}
            render={(props) => {
              setShowPublicWrapper(true);
              return <ProfileSetup {...props} />;
            }}
          />
          <Route
            exact
            path="/onboard"
            render={(props) => <OnboardSlider {...props} />}
          />
          <Route
            exact
            path="/notifications"
            render={(props) => <NotificationList />}
          />
          <Route
            exact
            path="/:username"
            render={(props) => <ProfilePreviewWrapper {...props} />}
          />
          <Route
            path="/"
            render={(props) => (
              <ScrapRouter containerClass={containerClass} {...props} />
            )}
          />
        </Switch>
        <DeleteScrapPopupContainer />
        <CollectionSettingsPopupContainer />
        <CollectionCollaborationWrapper />
        {!scrapPopup?.fromUrl && <OneScrapPopupContainer isReduxPopup />}
        <WarningPopupContainer/>
      </EntryActionContextProvider>
    </BaseLayout>
  );
};

const mapStateToProps = (state: ReduxStateType) => {
  return {
    expanded: state.utils.expanded,
    batchEditMode: state.batchEdit.mode,
    scrapPopup: state.utils.scrapPopup,
  };
};


export default connect(mapStateToProps)(Home);