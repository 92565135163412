import React from 'react';
import { PlusIcon } from '../../../Global/icons';
import './styles.css';
import NotificationCount from '../../../Global/NotificationCount';



/*
  * COMPONENT DESCRIPTION
  * This component is getting used as header for providing controls ,that will be used for  switching between contacts and invitation).
*/
interface Proptypes {
  label: string;
  onLabelClick: (val: 'contacts' | 'invitations') => void;
  count: number;
  onInviteClick: () => void;
}

const ContactsControl = ({ label, onLabelClick , count, onInviteClick } : Proptypes) => {
  return (
    <div className="contacts-control">
      <ul className="contacts-control__list">
        <li
          className={`contacts-control__list__nav ${
            label === 'contacts'
              ? 'contacts-control__list__nav--highlight'
              : ''
          }`}
          onClick={() => onLabelClick('contacts')}
        >
          My Contacts
        </li>
        <li
          className={`contacts-control__list__nav ${
            label === 'invitations'
              ? 'contacts-control__list__nav--highlight'
              : ''
          }`}
          onClick={() => onLabelClick('invitations')}
        >
          Invitations {count > 0 ? <NotificationCount count={count}/> : null}
        </li>
      </ul>
      <div className="contacts-control__add-new">
        <span onClick={onInviteClick}>
          <PlusIcon />
          New
        </span>
      </div>
    </div>
  );
};

export default ContactsControl;
