import React, { useState, useEffect, useCallback } from 'react';
import { generalResultType } from '../../../../types/globalSearchDataType';

import { Masonry, useInfiniteLoader } from 'masonic';

import './styles.css';
import LoadingContent from '../../../Loaders/LoadingContent';
import { tabsUnionType } from '../..';
import { returnShareLink } from '../../../../helpers/returnShareLinks';
import { storyType } from '../../../../types/stories';
import { collectionType } from '../../../../types/collections';
import { entryTypes } from '../../../../types/feed';
import { returnScrapSlug } from '../../../../helpers';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userTypes } from '../../../../types/user';
import { ReduxStateType } from '../../../../redux/store';
import { PublicProfileDataType } from '../../../../containers/ProfilePreview';
import { collectionCardFooterOptionsType } from '../../../NewCollectionCard/UiComponents/NewCollectionFooter';
import {
  setCollaborationPopup,
  setCollectionSettingsPopup,
} from '../../../../redux/action/utils';
import NewCollectionCard from '../../../NewCollectionCard';
import EmptyPageMessage from '../../../Global/EmptyPageMessage';
import { ReactionType } from '../../../Comments';
interface Proptypes {
  bookmarkedIds: Array<collectionType['id']>;
  onBookmarkToggled: (index: number, action: "save" | "unsave") => void
  isViewingOwnProfile?: boolean;
  userName: string;
  feeds: generalResultType[];
  loadMore: Function;
  isLoading: boolean;
  activeTab: tabsUnionType;
  userData?: PublicProfileDataType | null;
  count?: number;
  scrapsCount: {id:number,scraps_count:number}[];
  onLikeClicked?: (collectionId: collectionType['id'], collectionIndex: number, isLiked: boolean) => Promise<void>;
}

// FUNCTION HANDLER WHICH WILL RETURN NUMBER OF COLUMNS AND LOADING CARDS BASED ON THE BROWSER WIDTH
function returnNumberOfCardsAndColumns(width: number) {
  if (width >= 1528) return { numberOfCards: 12, columns: 4 };
  else if (width > 1200) return { numberOfCards: 9, columns: 3 };
  else if (width > 879) return { numberOfCards: 4, columns: 2 };
  else if (width > 699) return { numberOfCards: 2, columns: 1 };
  else if (width > 653) return { numberOfCards: 4, columns: 2 };
  else return { numberOfCards: 2, columns: 1 };
}

const ProfileFeeds = (props: Proptypes) => {
  const {
    bookmarkedIds,
    onBookmarkToggled,
    isViewingOwnProfile,
    feeds,
    loadMore,
    isLoading,
    activeTab,
    userName,
    userData: profileData,
    count,
    scrapsCount,
    onLikeClicked
  } = props;

  const [numberOfLoadingCards, setNumberOfLoadingCards] = useState(
    returnNumberOfCardsAndColumns(window.innerWidth)
  );
  const expanded = useSelector((state: ReduxStateType) => state.utils.expanded);
  const { pathname, search: comingFrom } = useLocation();
  const userData = useSelector(
    (state: { user: userTypes }) => state.user as userTypes
  );
  const tabTypeMapper = {
    All: 'content',
    Stories: 'stories',
    Collections: 'collections',
    Scraps: 'scraps',
  };
  const dispatch = useDispatch();
  const user = useSelector((state: ReduxStateType) => state.user);
  const { push } = useHistory();
  function footerOptionClickHandler(
    type: collectionCardFooterOptionsType['list'][number],
    collection
  ) {
    switch (type) {
      case 'edit':
        dispatch(setCollectionSettingsPopup({ collection }, false));
        break;
      case 'manageCollaborators':
        dispatch(
          setCollaborationPopup({
            collectionId: collection.id,
            onSaved: () => {},
          })
        );
        break;
      default:
        break;
    }
  }

  const footerDropdownOptions = (collection) => {
    const collectionIsMine = collection?.user_name === user.userName;
    const footerDropdownOptions: collectionCardFooterOptionsType = collectionIsMine
      ? {
        list: ['edit', 'share'],
        onOptionClick: (type) => footerOptionClickHandler(type, collection),
      }
      : null;
    return footerDropdownOptions;
  };

  const renderItems = useCallback(
    ({
      index,
      data,
      width,
    }: {
      index: number;
      data: generalResultType;
      width: number;
    }) => {
      let collectionData = data?.data as collectionType;
      let amIOwner = collectionData?.user_name === userData?.userName;
      let shareLink;
      let itemType = data.type;
      let scrapData: entryTypes;
      
      switch (data.type) {
        case 'story':
          shareLink = returnShareLink(
            'story',
            (data.data as storyType).slug,
            userName,
            true
          );

          break;
        case 'collection':
          shareLink =
            returnShareLink(
              'collection',
              (data.data as collectionType).slug,
              userName,
              true
            ) +
            '?from=' +
            encodeURIComponent(pathname + comingFrom);
          if (amIOwner)
            shareLink =
              '/c/' +
              shareLink.split('?')[0]?.split('/c/')?.[1] +
              '?from=' +
              encodeURIComponent(pathname + comingFrom);
          break;
        default:
          scrapData = data.data as entryTypes;
          itemType = null;
          shareLink = returnShareLink(
            'scrap',
            returnScrapSlug(scrapData),
            userName,
            true,
            scrapData.state,
            scrapData.private_key
          );

          break;
      }
    
      return (
        <NewCollectionCard
          footerDropdownOptions={{
            list: ['share'],
            onOptionClick: () => {
              
            }
          }}
          isBookmarked={!!bookmarkedIds[index]}
          onBookmarkToggled={onBookmarkToggled.bind(null, index)}
          onLikeClicked={onLikeClicked && onLikeClicked.bind(null, (data.data as collectionType).id, index)}
          data={data.data as collectionType}
          cardLayout="compact"
          scrapCount={
            scrapsCount.filter(
              (item) => item?.id === +(data?.data as collectionType)?.id
            )[0]?.scraps_count
          }
          onCardClick={() => {
            push(shareLink);
          }}
        />
      );
    },
    [scrapsCount, bookmarkedIds]
  );
  const maybeLoadMore = useInfiniteLoader(
    async (startIndex, stopIndex, currentItems) => {
      // get the data
      loadMore();
    },
    {
      isItemLoaded: (index, items) => !!items[index],
      minimumBatchSize: 15,
      threshold: 3,
    }
  );

  const windowResizeHandler = useCallback(() => {
    var w = window.innerWidth;
    setNumberOfLoadingCards(returnNumberOfCardsAndColumns(w));
  }, []);
  useEffect(() => {
    window.addEventListener('resize', windowResizeHandler);
    return () => {
      window.removeEventListener('resize', windowResizeHandler);
    };
  }, []);

  // const noFeedMessage = isViewingOwnProfile
  //   ? 'You do not currently have any Publicly visible'
  //   : 'This Author does not have any Publicly visible';

  if (isLoading && feeds.length === 0) {
    return (
      <div className="profile-feeds profile-feeds--loading">
        <LoadingContent numberOfLine={numberOfLoadingCards.numberOfCards} />
      </div>
    );
  }
  if (!isLoading && (!feeds || feeds.length === 0))
    return (
      <div className="profile-feeds">
        <EmptyPageMessage header={{}}>
          <>
            @{userName} currently has no public collections.
          </>
          
        </EmptyPageMessage>
      </div>
    );
  else
    return (
      <div className={`profile-feeds`}>
        <Masonry
          items={feeds}
          columnGutter={16}
          onRender={maybeLoadMore}
          overscanBy={2}
          render={renderItems}
          columnWidth={350}
          key={`profile-feeds-${activeTab}${expanded}-${count}`}
        />
      </div>
    );
};

export default ProfileFeeds;
