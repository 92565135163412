import { gql } from '@apollo/client';

const GET_SUBSCRIPTION_LIST_ITEMS = gql`query ListSubscriptionItems {
    listSubscriptionItems {
    data{
      id
      name
      price
      buttonDetail
      isAnnual
      customerActive
      included
      customerItemUnitPrice
      modifiedPriceId
      modifiedCustomerActivePlanPriceId
      modifiedCustomerActivePlanPriceFrequency
    }
    messages {
      error
      success
      __typename
    }
    status
    http_code
    __typename
  }
}`;

export default GET_SUBSCRIPTION_LIST_ITEMS;